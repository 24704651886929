import React from "react";
import { Container, Button, Row, Col, Card, CardHeader } from "shards-react";
import PageTitle from "../components/common/PageTitle";
const Finances = () => (
  <Container className="main-content-container px-5 pb-3">
    <Col>
      <Row>
        <PageTitle
          sm="4"
          title="Finances"
          className="text-sm-left"
        />
      </Row>
      <Col>
        <Card small className="px-3 py-3">
          <CardHeader className="border-bottom">
            <h3>
              Corporate Financial Data
            </h3>
          </CardHeader>
          <h6>
            The websites listed here can provide you reports on public and private companies.
            Financial reports for public companies are required to be filed with the Securities and Exchange Commision (SEC) on a quarterly basis.
            These reports, known as 10-Qs can be found on SEC On-Line.  Condensed versions, without accompanying notes can be found on Yahoo Finance.
            Other sites provide data on private companies, usually for a fee.
            Their listing here is neither a recommendation nor endorsement of such firms.
            You  are encouraged to visit the web sites of such firms or attorney to learn more about their services.
          </h6>
          <CardHeader className="border-bottom">
            <h3>Corporate Financial Links</h3>
          </CardHeader>
          <div class="d-grid gap-2">
            <a href="http://www.carlsononline.com/">Carlson (Canadian Financials)</a>
            <a href="http://www.freeedgar.com/">Free Edgar</a>
            <a href="http://www.edgar-online.com/">Edgar On-Line</a>
            <a href="http://www.hoovers.com/">Hoovers</a>
            <a href="http://www.marketguide.com/">Market Guide</a>
            <a href="http://www.sec.gov/">SEC On-Line</a>
            <a href="http://www.sedar.com/homepage.html">SEDAR (Canadian Financials)</a>
            <a href="http://finance.yahoo.com/?u">Yahoo Finance</a>
          </div>
        </Card>
      </Col>
    </Col>
  </Container>
)
export default Finances
