import React,{ useState, useEffect} from "react";
import { Container, CardHeader, Row } from "shards-react";
import SummaryService from "../../../flux/services/SummaryService";
//import { useViewContext } from "../ViewTradeReport";

 
export default function Summary (props)
{
 // const [view, setView] = useContext(useViewContext());
//    alert(JSON.stringify(view));
  const [data, setData] = useState(null);
  useEffect(() => {
    async function getSummary(acctId)
    {
      try {
        // set loading to true before calling API
        const data = await SummaryService.getSummary(acctId);
        setData(data);
        //alert(JSON.stringify(data));
      } catch (error) {
        // add error handling here
        console.log(error);
      }
    }
    getSummary(props.accountId)

    }, []);

    const PctRows = (props) => {

      const {pct_current,pct_one_day_past_due, pct_thirty_day_past_due, pct_sixty_day_past_due, pct_ninety_day_past_due} = props
  
      return ( <tr>
        <td align="right">--------</td>
        <td align="right">--------</td>
        <td align="right">{pct_current=="0"?"":pct_current + "%"}</td>
        <td></td>
        <td align="left">{pct_one_day_past_due=="0"?"":pct_one_day_past_due + "%"}</td>
        <td align="left">{pct_thirty_day_past_due=="0"?"":pct_thirty_day_past_due + "%"}</td>
        <td align="left">{pct_sixty_day_past_due=="0"?"":pct_sixty_day_past_due + "%"}</td>
        <td align="left">{pct_ninety_day_past_due=="0"?"":pct_ninety_day_past_due + "%"}</td>
      </tr>
      )
    }
  const Rows = (props) => {

    const {report_date_id,recent_high_credit,account_balance,current_balance,one_day_past_due_amt, thirty_day_past_due_amt, sixty_day_past_due_amt, ninety_day_past_due_amt} = props
    let USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0, 
      minimumFractionDigits: 0,
    });

    return ( <tr>
      <td align="right">{USDollar.format(recent_high_credit)}</td>
      <td align="right">{USDollar.format(account_balance)}</td>
      <td align="right">{current_balance=="0"?"":USDollar.format(current_balance)}</td>
      <td></td>
      <td align="left">{one_day_past_due_amt=="0"?"":USDollar.format(one_day_past_due_amt)}</td>
      <td align="left">{thirty_day_past_due_amt=="0"?"":USDollar.format(thirty_day_past_due_amt)}</td>
      <td align="left"> {sixty_day_past_due_amt=="0"?"":USDollar.format(sixty_day_past_due_amt)}</td>
      <td align="left">{ninety_day_past_due_amt=="0"?"":USDollar.format(ninety_day_past_due_amt)}</td>
    </tr>
    )
  }

  const Table = (props) => {
    
    //const {data} = props
    //alert(JSON.stringify(data))
    if (!data) return (
      <span>Data not available</span>
    );

    return (
            <Row className="px-3">
              <table className="table table-sm">
                <thead className="table-light">
                  <tr>
                  
                  <th scope="col"><div class="w-100 text-end">High Credit</div></th>
                  <th scope="col"><div class="w-100 text-end">Balance</div></th>
                  <th scope="col"><div class="w-100 text-end">Current</div></th>
                  <th scope="col"><div class="w-100 text-end"></div></th>
                  <th scope="col"><div class="w-100">1-30</div></th>
                  <th scope="col">31-60</th>
                  <th scope="col">61-90</th>
                  <th scope="col">90+</th>
                  </tr>
                </thead>
              <tbody> 
                


              {data.map((data) => (
                <Rows key={data.credit_summary_id} report_date_id={data.report_date_id} recent_high_credit={data.recent_high_credit} account_balance={data.account_balance} current_balance={data.current_balance} one_day_past_due_amt={data.one_day_past_due_amt} 
                      thirty_day_past_due_amt={data.thirty_day_past_due_amt} sixty_day_past_due_amt={data.sixty_day_past_due_amt}
                      ninety_day_past_due_amt={data.ninety_day_past_due_amt} />
              ))}

              {data.map((data) => (
                <PctRows key={data.credit_summary_id} pct_current={data.pct_current}
                      pct_one_day_past_due={data.pct_one_day_past_due} pct_thirty_day_past_due={data.pct_thirty_day_past_due} pct_sixty_day_past_due={data.pct_sixty_day_past_due}
                      pct_ninety_day_past_due={data.pct_ninety_day_past_due} />
              ))}

              </tbody>
              </table>
            </Row>)
  }


 
  //alert(JSON.stringify(props));
  return (
    
    
    <Container>
      
      <CardHeader className="border-bottom">
        <h5>
          Summary
        </h5>
      </CardHeader>
      <Table data={props}/>
      </Container>
  )
}

