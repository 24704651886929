import supabase from "../stores/supabase";

class PlacedForCollectionsService {
  
  async getPFC(accountId ) {
  
    //alert(accountId);
    let { data: pfc, error } = await supabase
        .from('placed_for_collections')
        .select('placed_for_collections_id, date_entered,account_id,member_id, amount')
        .eq('account_id', accountId)
        .order('date_entered', { ascending: false });
 
    //alert(JSON.stringify(pfc));
    if (error !== null)
      return null;
    if (pfc === null)
      return null;

      return pfc;


  }

  async getPFCMonths(numOfMonths ) {
  

    let { data: pfc, error } = await supabase
        .from('vw_placed_for_collections_months')
        .select('placed_for_collections_id, date_entered,account_id,member_id, amount, account_name, city, state')
        .lte('number_months', numOfMonths)
        .order('date_entered', { ascending: false });
 
    if (error !== null)
      return null;
    if (pfc === null)
      return null;

      return pfc;


  }

  async addPFC(accountId, Amount, memberId ) {
  
    let { data, error } = await supabase
    .from('placed_for_collections')
    .insert([
        { account_id: accountId, amount: Amount, member_id: memberId },
    ])
 
    if (error !== null)
      return null;

    return data;

  }


  async deletePFC(pfcId) {
    
    let { data, error } = await supabase
    .from('placed_for_collections')
    .delete()
    .eq('placed_for_collections_id', pfcId)
 
    if (error !== null)
    {
      alert("Error Deleting PFC Record: " + JSON.stringify(error));
      return null;
    }
    return data;

  }

  async getTodayPFC(memberId) {
    
    let { data: pfc, error } = await supabase
        .from('vw_place_for_collections_today')
        .select('member_id, placed_for_collections_id, cci_account_id, account_id, amount, date_entered , account_name, phone, city, state, zip')
        .eq('member_id', memberId);
 
    if (error !== null)
    {
      alert(JSON.stringify(error))
      return null;
    }
    if (pfc === null)
      return null;

      return pfc;

  }

}

export default new PlacedForCollectionsService()
