import React from "react";
import { Container, Button, Row, Col, Card, CardHeader } from "shards-react";
import PageTitle from "../components/common/PageTitle";
const Collection = () => (
  <Container className="main-content-container px-5 pb-3">
    <Col>
      <Row>
        <PageTitle
          sm="4"
          title="Collections"
          className="text-sm-left"
        />
      </Row>
      <Col>
        <Card small className="px-3 py-3">
          <CardHeader className="border-bottom">
            <h3>
              Collections
            </h3>
          </CardHeader>
          <h6>
            The financial institutions listed here have represented floorcovering manufacturers in the recent past.
            Their listing here is neither a recommendation nor endorsement of such firms.
            You  are encouraged to visit the web sites of such firms to learn more about their services
          </h6>
          <CardHeader className="border-bottom">
            <h3>
              Why use a collection agency?
            </h3>
          </CardHeader>
          <h6>
            At some point it becomes cost effective to outsource your collections work and hire an agency:

            When your department makes no progress with delinquent accounts.
            When your department is overwhelmed with other work
            When maintaining good relations with a customer is important
          </h6>
          <CardHeader className="border-bottom">
            <h3>Collection Agencies</h3>
          </CardHeader>
          <div class="d-grid gap-2">
            <a href="http://www.agaltd.com/">A.G. Adjustments</a>
            <a href="http://www.abccompanies.com/">ABC Companies</a>
            <a href="http://www.amalgamated.com/">Amalgamated Collections</a>
            <a href="https://www.capitalrecovery.net/">Capital Recovery Corp</a>
            <a href="https://centralcollect.com/">Central Collection Corporation</a>
            <a href="http://www.leibco.com/">Lieb & Company</a>
            <a href="https://nrf.com/">National Association of Retail</a>
          </div>
          <CardHeader className="border-bottom">
            <h3>Collection Costs</h3>
          </CardHeader>
          <h6> The cost to recover delinquent accounts varies from agency to agency and depends on a range of variables,
            from the staleness of the account to the volume of business that your firm does with the agency.
            Typical costs range from 25% to 33% of the debt. </h6>
        </Card>
      </Col>
    </Col>
  </Container>
)
export default Collection
