import React from "react";
//import addons from "react-addons/index";
import { Row, Col, Container, Card, CardHeader, CardBody } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { ProtectedRoute } from "../../ProtectedRoute";
import { Link } from "react-router-dom";

//var classSet = React.classSet;


export default function ViewAdminReports(props) {
    
    
      
      return (
      
      <ProtectedRoute>
      <Container className="main-content-container px-5 pb-3">
      <Row noGutters  className="page-header py-4">
            <PageTitle
                sm="4"
                title="Standard Reports"
                className="text-sm-left"
            />
        </Row>
        <Row className="px-0" > 
            <Col lg="3" className="flex-column align-items-stretch">
                <Card className="h-50 mb-4">
                  <Link to="/MeetingNamesReport">
                    <CardHeader className="border-bottom">
                    <h6 className="m-0">Monthly Meeting Names</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="blog-comments__content">
                        <p className="m-2 my-1 mb-2 text-muted">
                            Pull of the Meeting and Clearing Names entered through out the month.
                        </p>
                        </div>
                  </CardBody>
                  </Link>
                </Card> 
                <Card className="h-50 mb-4">
                  <Link to="/MeetingCommentsReport">
                    <CardHeader className="border-bottom">
                    <h6 className="m-0">Meeting Comments</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="blog-comments__content">
                        <p className="h-25 m-2 my-1 mb-2 text-muted">
                            View all of the meeting comments that have been entered.
                        </p>
                        </div>
                    </CardBody>
                    </Link>
                </Card>   
              </Col>
              <Col lg="3" className="flex-column align-items-stretch">
                <Card className="h-50 mb-4">
                  <Link to="/ViewCustomerUsage">
                    <CardHeader className="border-bottom">
                    <h6 className="m-0">Customer Usage</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="blog-comments__content">
                        <p className=" m-2 my-1 mb-2 text-muted">
                            View the report requests of all the users by type and member.
                        </p>
                        </div>
                  
                    </CardBody>
                    </Link>
                </Card> 
                <Card className="h-50 mb-4">
                  <Link to="/ScoreDecileDistribution">
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Invoices</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="blog-comments__content">
                        <p className="h-25 m-2 my-1 mb-2 text-muted">
                            Show monthly invoices for a-la-carte members.
                        </p>
                        </div>

                  </CardBody>
                    </Link>
                </Card> 
            </Col>
            <Col lg="3" className="flex-column align-items-stretch">
                <Card className="h-50 mb-4">
                  <Link to="/AccountsUnder75">
                    <CardHeader className="border-bottom">
                    <h6 className="m-0">Change Requests</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="blog-comments__content">
                        <p className="h-25 m-2 my-1 mb-2 text-muted">
                            Show all change requests that have been entered by members.
                        </p>
                        </div>

                    </CardBody>
                  </Link>
                </Card> 
            </Col>
        </Row>
      </Container>
      </ProtectedRoute>
    );
    
    }
/*
export function useViewContext()
{
  return viewContext;
}*/
