let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');
    
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };
  
    return null
  };

let formatDate = (str) => {

    let formattedDate = '';
    if (str != undefined)
        formattedDate = str.toString().substring(5,7) + '/' + str.toString().substring(8,10) + '/' + str.toString().substring(0,4);

    return formattedDate;
}



  export default {formatPhoneNumber, formatDate};