import supabase from "../stores/supabase";

class NSFCheckService {
  
  async getNSFChecks(accountId ) {

    let today = new Date();
    today.setMonth(today.getMonth() - 12); // dates are zero indexed so it its starts with 0 not 1 for the month
    
    let year = today.getFullYear();
    let month = (1 + today.getMonth()).toString().padStart(2, '0');
    let day = '01';
  
    let dateformat =  month + '/' + day + '/' + year;
    
    let { data: checks, error } = await supabase
        .from('nsf_check')
        .select('nsf_check_id, account_id,times_returned, amount, member_id, date_entered')
        .eq('account_id', accountId)  
        .gte('date_entered', dateformat)  
        .order('date_entered',{ascending: false})                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
        ;
 

    if (error !== null)
      return null;
    if (checks === null)
      return null;

      return checks;


  }

  async getNSFChecksMonths(numOfMonths ) {
    
    let { data: checks, error } = await supabase
        .from('vw_nsf_check_months')
        .select('nsf_check_id, account_id,times_returned, amount, member_id, date_entered, account_name, city, state')
        .lte('number_months', numOfMonths)
        .order('date_entered', { ascending: false });                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
        ;
 
    if (error !== null)
      return null;
    if (checks === null)
      return null;

      return checks;


  }

  async deleteNSFChecks(nsfId) {
    
    let { data, error } = await supabase
    .from('nsf_check')
    .delete()
    .eq('nsf_check_id', nsfId)
 
    if (error !== null)
    {
      alert("Error Deleting NSF Check Record: " + JSON.stringify(error));
      return null;
    }
    return data;

  }

  async addNSFChecks(accountId, checkAmount, timesReturned, memberId ) {
  
    let { data, error } = await supabase
    .from('nsf_check')
    .insert([
        { account_id: accountId, amount: checkAmount, times_returned: timesReturned, member_id: memberId },
    ])
 
    if (error !== null)
      return null;
    if (data === null)
      return null;



  }

}

export default new NSFCheckService()
