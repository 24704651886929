import React from "react";
import { Container, Button, Row, Col, Card, CardHeader } from "shards-react";
import PageTitle from "../components/common/PageTitle";
const Banks = () => (
  <Container className="main-content-container px-5 pb-3">
    <Col>
      <Row>
        <PageTitle
          sm="4"
          title="Banks"
          className="text-sm-left"
        />
      </Row>
      <Col>
        <Card small className="px-3 py-3">
          <CardHeader className="border-bottom">
            <h3>
              Banks & Factors
            </h3>
          </CardHeader>
          <h6>
            The financial institutions listed here have represented floorcovering manufacturers in the recent past.
            Their listing here is neither a recommendation nor endorsement of such firms.
            You  are encouraged to visit the web sites of such firms to learn more about their services
          </h6>
          <CardHeader>
            <h3>Commercial Banks</h3>
          </CardHeader>
          <div class="d-grid gap-2">
            <a href="http://www.bankofamerica.com/">Bank Of America</a>
            <a href="http://www.chase.com/">Chase Manhatten</a>
            <a href="http://www.citicorp.com/">Citicorp</a>
            <a href="https://www.truist.com/">Suntrust</a>
            <a href="https://www.wellsfargo.com/">Wells Fargo</a>
          </div>
          <CardHeader className="border-bottom">
            <h3>Factors</h3>
          </CardHeader>
          <div class="d-grid gap-2">
            <a href="http://www.capfac.com/">Capital Factors</a>
            <a href="http://www.cit.com/">CIT Group</a>
            <a href="http://www.gmacfs.com/">GMAC Financial</a>
            <a href="http://www.hellerfin.com/">Heller Financial</a>
            <a href="https://www.truist.com/">SunTrust</a>
            <a href="http://www.solocredit.com/">Solo Credit International</a>
          </div>
        </Card>
      </Col>
    </Col>
  </Container>
)
export default Banks
