import React from "react";
import {
  Container,
  Row,
  Col,
  Button
} from "shards-react";

import CustomFileUpload from "../components/components-overview/CustomFileUpload";

const Links = () => (
  <Container fluid className="px-8 pb-4">
    <Row>
      <Col>
      <card small>
        <strong>
            Upload Files
        </strong>
        <CustomFileUpload />
      </card>
      </Col>
      <Col>
        <card>
          <strong className="text-muted d-block my-2">
            Links
          </strong>
          <Button>View Files</Button>
        </card>
      </Col>
    </Row>
  </Container>
)

export default Links;
