import React, { useState, createContext } from "react";
//import addons from "react-addons/index";
import { Row, Col, Container, Card } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import BlogOverview from "../BlogOverview";
import { ProtectedRoute } from "../../ProtectedRoute";
import DashboardSummary from "./Components/DashboardSummary";
import loginStore from "../../flux/stores/loginStore";


//var classSet = React.classSet;


export default function ViewDashboard() {
    
      let memberId = loginStore.getMemberId();
      
      return (
      
      <ProtectedRoute>

      <Row noGutters className="px-0">
            <Col>
            <DashboardSummary memberId={memberId}></DashboardSummary>
          
          </Col>
      </Row>
      
        
    
      </ProtectedRoute>
    );
    
    }
/*
export function useViewContext()
{
  return viewContext;
}*/
