import React from "react";
import loginStore from "./flux/stores/loginStore";
import { Redirect } from "react-router-dom";
import AuthService from "./flux/services/AuthService";

export const ProtectedRoute = ({children}) => {
  if (!loginStore.isLoggedIn()) {
    // user is not authenticated
    AuthService.logout();
    return <Redirect to="/login" />;
  }
  return children;
};