export default function() {
  return [
    {
      title: "Dashboard",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/ViewDashboard",
    },
    {
      title: "Trade Reports",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/ViewTradeReport",
    },
    {
      title: "Customer NSF",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/ViewCustomerNSF",
    },
    {
      title: "Customer PFC",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/ViewCustomerPFC",
    },
    {
      title: "Standard Reports",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/ViewStandardReports",
    },
    {
      title: "Meeting Comments",
      htmlBefore: '<i class="material-icons">edit</i>',
      to: "/ViewMeetingComments",
    },
    {
      title: "Clearing Names",
      htmlBefore: '<i class="material-icons">edit</i>',
      to: "/ViewClearingNames",
    },
    {
      title: "Change Requests",
      htmlBefore: '<i class="material-icons">edit</i>',
      to: "/ViewChangeRequest",
    }
    
    
  ];
}
