import React, { useState, useRef, useEffect} from "react";
import {Container, Form, FormInput, FormGroup, Button, Row,  Card, CardHeader,CardBody } from "shards-react";
import NSFCheckService from "../../../flux/services/NSFCheckService";
import loginStore from "../../../flux/stores/loginStore";
import AdminService from "../../../flux/services/AdminService";
import { useReactToPrint } from "react-to-print";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../../../utils/tableCustomStyles";
import formatDollar from "../../../utils/formatDollar";
import SearchService from "../../../flux/services/SearchService";
import { Redirect } from "react-router-dom";


export default function MonthlyNSF(props) {

  //const [view, setView] = useContext(getViewContext());
  const [state, setState]= useState({
    months: !props.location.months?"":props.location.months,
    view: "none"
  });
  const [data, setData] = useState(null);
  const profile = loginStore.getProfile();

  useEffect(() => {

    if (props.location.months != undefined)
    {
      let isMounted = true; 
      NSFCheckService.getNSFChecksMonths(props.location.months).then( data => { if (isMounted) {setData(data)  
        } })
      return () => { isMounted = false };
    }  
  }, []);

   
  function HandleForm(event) {
    let newValue = event.target.value;
    switch(event.target.id)
    {
    case "#numOfMonths":
      setState( prevState =>
        (
          {
            ...prevState,
            months: newValue
          }
        )
      );
      break;
    default:
      break;
    }
    
  }
  async function HandleSubmit(e) {
      
      try {
        // set loading to true before calling API
        const data = await NSFCheckService.getNSFChecksMonths(state.months);
        setData(data);
  
        if (data !== null)
        {
          let memberId = loginStore.getMemberId();
          let userName = loginStore.getUserName();
          let result = AdminService.setReportAccess(memberId, 0, 65, userName);
        }

      } catch (error) {
        // add error handling here
        console.log(error);
      }

  }

  async function onRowClickHandler(event) {
   //alert(JSON.stringify(event))
    let _response = await SearchService.getCustomerRecord(event.account_id);
  
    //alert(_response)
    if (_response == false)
      alert("Error returning Customer Record for CCI ID: " + event.account_id)
    else
    {
      setState( prevState =>
        (
          {
            ...prevState,
            view: "TradeReport",
          }
        )
      );
      
    }
     // setView({view: "Report"});
      
  }

  const columns = [
    {
      name: 'Member ID',
      selector: data => profile=="alacarte"?"":data.member_id.toString().substring(2,5),
      sortable: true,
      width: '10%'
    },
    {
      name: (<div class="w-100 text-end">CCI ID</div>),
      selector: data => data.account_id,
      cell: data => (<div class="w-100 text-end text-nowrap">{data.account_id}</div>),
      sortable: true,
      width: '8%'
    },
    {
      name: 'Company Name',
      sortable: true,
      selector: data => data.account_name,
      width: '28%'
    },
    {
      name: 'City',
      selector: data => data.city,
      width: '16%'
    },
    {
      name: 'State',
      selector: data => data.state,
      cell: data => data.state,
      sortable: true,
      width: '8%'
    },
    {
      id: data => data.date_entered,
      name: (<div class="w-100">Date Entered</div>),
      selector: data => data.date_entered,
      cell: data => (<div class="w-100 text-end text-nowrap">{data.date_entered.toString().substring(5,7) + '/' + data.date_entered.toString().substring(8,10) + '/' + data.date_entered.toString().substring(0,4)}</div>),
      sortable: true,
      width: '10%'
    },
    {
      name: (<div class="w-100 text-end">NSF Amount</div>),
      selector: data => data.amount,
      cell: data => (<div class="w-100 text-end text-nowrap">{formatDollar(data.amount)}</div>),
      sortable: true,
      width: '10%'
    },
    {
      name: (<div class="w-100 text-end">Times Returned</div>),
      selector: data => data.times_returned,
      cell: data => (<div class="w-100 text-end text-nowrap">{data.times_returned}</div>),
      sortable: true,
      width: '10%'
    }
  ]

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
     content: () => componentRef.current,
     documentTitle: 'Monthly Meeting Report',
     
    });
  const Rows = (props) => {
    const {account_id,amount,times_returned,member_id,date_entered, name, city, state} = props
    let dateEntered = date_entered.toString().substring(5,7) + '/' + date_entered.toString().substring(8,10) + '/' + date_entered.toString().substring(0,4)
    let memberIdDisplay = member_id
    
    if (profile == 'alacarte')
      memberIdDisplay = ''

    return ( <tr>
      <td>{memberIdDisplay}</td>
      <td>{account_id}</td>
      <td>{name}</td>
      <td>{city}</td>
      <td>{state}</td>
      <td>{dateEntered}</td>
      <td>{amount}</td>
      <td>{times_returned}</td>
    </tr>
    )
  }

  const Table = (props) => {
    if (!data) return (
      null
    );
    return (
      <Container>
      <Row className="py-2 px-4">
        <div className="d-flex flex-row-reverse bd-highlight"> 
                <Button size="sm" theme="info" className="mb-2 mr-1" onClick={handlePrint}>Print</Button>
        </div>
        <div
                ref={componentRef}
                className="print-component"
              >
        <Card className="px-6">
          <CardHeader className="text-sm-center" id="PFC">
              <div class="text-center fs-5">Round Table Floor Covering Credit Group</div>
              <div class="text-center fs-6">NSF Monthly Report</div>
              <div class="text-center fs-6">All Customer's NSF Activity For The Past {state.months} Month(s)</div>
          </CardHeader>
          <CardBody>
            
                <table className="table table-sm">
                  <thead className="table-light">
                    <tr>
                    
                    <th scope="col" >Member</th>
                    <th scope="col">CCI ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">City</th>
                    <th scope="col">State</th>
                    <th scope="col">Date Entered</th>
                    <th scope="col">Amt Returned</th>
                    <th scope="col"># Times Returned</th>
                    </tr>
                  </thead>
                <tbody> 
                  
                  {data.map((data) => (
                    <Rows key={data.nsf_check_id} account_id={data.account_id} amount={data.amount} times_returned={data.times_returned}
                          member_id={data.member_id} date_entered={data.date_entered} name={data.account_name} city={data.city} state={data.state}/>
                  ))}

                </tbody>
                </table>
            
          </CardBody>
        </Card>
        </div>
        </Row>
        </Container>
        )
  }

  const NSFDataTable = (props) =>
  {
    if (!data) return (
      null
    );

    return (
      <Row className="py-2 px-4">
          <div className="d-flex flex-row-reverse bd-highlight"> 
                <Button size="sm" theme="info" className="mb-2 mr-1" onClick={handlePrint}>Print</Button>
        </div>
        <div
                ref={componentRef}
                className="print-component"
              >
      <Card className="px-6">
          <CardHeader className="text-sm-center" id="PFC">
              <div class="text-center fs-5">Round Table Floor Covering Credit Group</div>
              <div class="text-center fs-6">NSF Monthly Report</div>
              <div class="text-center fs-6">All Customer's NSF Activity For The Past {state.months} Month(s)</div>
          </CardHeader>
          <CardBody>
      
      <DataTable customStyles={tableCustomStyles} columns={columns}
        onRowClicked={onRowClickHandler}
        highlightOnHover={true}
        data={data}
        ></DataTable>
        </CardBody>
        </Card>
        </div>
        </Row>
    )
  }
  if (state.view == "TradeReport")
  return (
    <div>
      <Redirect to={{
            pathname: '/ViewTradeReport',
            returnValue: 'MonthlyNSF',
            months: state.months 
            }} />
    </div>
  )

  //alert(JSON.stringify(props));
  return (
    

    <Container >
    <Row className="py-2 px-5">
      <Card className="px-6">
        <CardHeader className="border-bottom">
          <h6>
            View Monthly NSF
          </h6>
        </CardHeader>
        <CardBody>
        
          <Form className="d-flex justify-content-left">
          <FormGroup>
            <label for="#numOfMonths" className="px-2 pb-2">Number Of Months:</label>
            <FormInput type="text" id="#numOfMonths" onChange={HandleForm.bind(this)} value={state.months} />
            
          </FormGroup>
          </Form>
          <div className="d-flex justify-content-left">
          <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={HandleSubmit} >Submit</Button>
          </div>
        </CardBody>
      </Card>
    </Row>
    
      
          <NSFDataTable />
          
    </Container>
  )
}

