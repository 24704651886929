import React, { useState } from "react";
//import addons from "react-addons/index";
import { Row, Col, Container, Card, CardHeader } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import CustomerSearch from "./Components/CustomerSearch";
import { ProtectedRoute } from "../../ProtectedRoute";
import CustomerInfoCard from "./Components/CustomerInfoCard";
import searchStore from "../../flux/stores/searchStore";
import ResetSearch from "./Components/ResetSearch";
import getViewContext from "../Context/ViewContext";
import ClearingNames from "./Components/ClearingNames";
import loginStore from "../../flux/stores/loginStore";
import ClearingReport from "./Components/ClearingReport";

//var classSet = React.classSet;

const viewContext = getViewContext();

export default function ViewClearingNames() {
    
    const [view, setView] = useState({view: "Search"});
   
    

    function CustomerSearchVisible(){
      //  const { view } = useContext(viewState);

        if(view.view == "Report" || searchStore.getCustomerRecord() != undefined)
        {
          //alert(view.view);
          const customerRecord = searchStore.getCustomerRecord();
          const profile = loginStore.getProfile();

           return ( 
            <Container>
            
                <Row className="px-3">
                <div className="d-flex gap-3 flex-row-reverse bd-highlight"> 
                <ResetSearch></ResetSearch>
                </div>
                </Row>
                <Row>
                <CustomerInfoCard></CustomerInfoCard>
                </Row>
                <Row noGutters>
                    <Card small className="mb-4">
                       <ClearingNames cciAccountId={customerRecord[0].cci_account_id} accountId={customerRecord[0].account_id} memberId={loginStore.getMemberId()} profile={loginStore.getProfile()}></ClearingNames> 
                    </Card>

                </Row>
            </Container>
            )
        }
        if(view.view == "Search")
        {
            return ( 

              <Col>
                <Row className="px-3">
                <div className="d-flex gap-3 flex-row-reverse bd-highlight"> 
                <ResetSearch></ResetSearch>
                </div>
                </Row>
                <CustomerSearch></CustomerSearch>
                <Card small className="mb-1">
                <CardHeader className="border-bottom">
                <h5>
                  Clearing Names
                </h5>
              </CardHeader>
                  <ClearingReport refresh={true} memberId={loginStore.getMemberId()} profile={loginStore.getProfile()} ></ClearingReport>
                </Card>
              </Col>

            )
        }

        return (<Row></Row>);
    }

    return (
    
    <ProtectedRoute>
    <Container className="main-content-container px-4 pb-3">
    <Row noGutters className="page-header py-4">
    <PageTitle
    sm="4"
    title="Clearing Names"
    className="text-sm-left"
    />
    </Row>
    <Row noGutters className="px-0">
        <Col>
    
        <viewContext.Provider value={[view, setView]}>
        <CustomerSearchVisible/> 
        
        </viewContext.Provider>
        
        </Col>
    </Row>
    
    
    
    </Container>
    </ProtectedRoute>
);

}

