import React,{ useState, useEffect} from "react";
import { Container, Button, Card, CardHeader, Row, CardBody } from "shards-react";
import JobProcessService from "../../../flux/services/JobProcessService";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../../../utils/tableCustomStyles";
import JobProcess from "./JobProcess";

 
export default function RefreshReports (props)
{
  //const [view, setView] = useContext(useViewContext());
  const [data, setData] = useState(null);
  useEffect(() => {
    async function GetReportRefresh()
    {

      try {
        // set loading to true before calling API
        const data = await JobProcessService.GetReportRefresh();
        setData(data);
      } catch (error) {
        // add error handling here
        console.log(error);
      }
    }
    GetReportRefresh();
  
    }, []);
  
  const columns = [
      {
        id: data => data.job_process_id,
        name: 'Process Id',
        selector: data => data.job_process_id,
        width: '8%'
      },
      {
        name: 'Report Name',
        selector: data => data.job_name
      },
      {
        name: 'Status',
        selector: data => data.status
      },
      {
        name: 'Error',
        selector: data => data.error_message
      },
      {
        name: 'Start Time',
        selector: data => data.start_time,
        cell: data =>(<div class="w-90 text-end text-nowrap">{(new Date(data.start_time)).toLocaleString()}</div>) 
      },
      {
        id: data => data.end_time,
        name: 'End Time',
        selector: data => data.end_time,
        cell: data => (<div class="w-90 text-end text-nowrap">{(new Date(data.end_time)).toLocaleString()}</div>)
      },
      {
        id: data => data.job_process_id,
        name: '',
        cell: data => (<button type="button" id={data.job_name} class="btn btn-link" onClick={RefreshReport.bind(this)}>Refresh</button>),
      }
  ]

  async function RefreshReport(e)
  {

    switch(e.target.id){
        case 'Payment Score':
            await JobProcessService.RefreshReport(1);
            break;
        case 'Aging Score':
            await JobProcessService.RefreshReport(2);
            break;
        case 'CCI Score':
            await JobProcessService.RefreshReport(3);
            break;
        case 'Credit Summary':
            await JobProcessService.RefreshCreditSummary();
            break;
        default:
            break;
    }

    try {
        // set loading to true before calling API
        const data = await JobProcessService.GetReportRefresh();
        setData(data);
       // alert(JSON.stringify(data));
      } catch (error) {
        // add error handling here
        console.log(error);
      }
  }
 
  if (!data) return (
    <span>Data not available</span>
  );
  //alert(JSON.stringify(props));
  return (
    

      <Container>
        <Card>
      <CardHeader className="border-bottom">
        <h5>
          Job Processes
        </h5>
      </CardHeader>
      <Row className="px-2">
        <CardBody>
        <DataTable customStyles={tableCustomStyles} columns={columns}
            data={data}
            ></DataTable>
        </CardBody>
        </Row>
        </Card>
      </Container>

  )
}

