import React from "react";
import { Container, Button, Row, Col, Card, CardHeader } from "shards-react";
import PageTitle from "../components/common/PageTitle";
const attorneys = () => (
  <Container className="main-content-container px-5 pb-3">
    <Col>
      <Row>
        <PageTitle
          sm="4"
          title="Attorneys"
          className="text-sm-left"
        />
      </Row>
      <Col>
        <Card small className="px-3 py-3">
          <CardHeader className="border-bottom">
            <h3>
              Attorneys
            </h3>
          </CardHeader>
          <h6>
            The attorneys or firms listed here have represented floorcovering manufacturers in the recent past.
            Their listing here is neither a recommendation nor endorsement of such firms.
            You are encouraged to visit the web sites of such firms or attorney to learn more about their services.
          </h6>
          <CardHeader className="border-bottom">
            <h3>Corporate Law Firms</h3>
          </CardHeader>
          <div class="d-grid gap-2">
            <a href="https://www.alston.com/en/">Alston & Bird </a>
            <a href="https://www.agg.com/">Arnall, Golden & Gregory</a>
            <a href="http://www.kilstock.com/">Kilpatrick & Stockton</a>
            <a href="http://www.kslaw.com/">King & Spalding</a>
            <a href="http://www.pgfm.com/">Powell Goldstein Frazer & Murphy</a>
            <a href="http://www.troutmansanders.com/">Troutman Sanders, LLP</a>
          </div>
          <CardHeader className="border-bottom">
            <h3>Bankrupcy Counsel</h3>
          </CardHeader>
          <div class="d-grid gap-2">
            <a href="https://www.agg.com/">Arnall, Golden & Gregory</a>
          </div>
        </Card>
      </Col>
      </Col>
  </Container>
)
export default attorneys
