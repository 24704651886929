import React,{ useState, useEffect} from "react";
import { Container, CardHeader, Row, Button, Modal, ModalBody, ModalHeader, Card, CardBody, Col, ListGroup, ListGroupItem, Form, FormGroup, FormInput,FormSelect, FormTextarea} from "shards-react";
import MeetingCommentsService from "../../../flux/services/MeetingCommentsService";
import MeetingCommentsReport from "./MeetingCommentsReport";
 
export default function MeetingComments (props)
{
  let acctId = props.accountId;
  const [state, setState]= useState({
    insertComplete: false,
    open: false,
    meetingCode: null,
    otherComment: null,
    additionalComment: null,
    memberId: props.memberId,
    profile: props.profile,
    accountId: acctId,
    otherCommentVisibility: "invisible",
    meeting_code_id: null
  });
  const [data, setData] = useState(null);
  useEffect(() => {
    async function getMeetingComments(memberId){
      try {
        // set loading to true before calling API
        let data = await MeetingCommentsService.getMeetingComments(memberId);
        setData(data);

      } catch (error) {
        console.log(error);
      }
    }
    getMeetingComments(state.memberId);

  }, []);

  const [meetingCodes, setMeetingCodes] = useState(null);
  useEffect(() => {
    async function getCommentsCodes()
    {
      try {
        // set loading to true before calling API
        const data = await getMeetingCommentsCodes();
        setMeetingCodes(data);

      } catch (error) {
        console.log(error);
      }
    }
    getCommentsCodes();

  }, []);


  async function getMeetingCommentsCodes()
  {
      //alert("meeting Codes");
      return MeetingCommentsService.getMeetingCommentsCodes();
  }

  async function addMessage(e) {
      // alert(JSON.stringify(state));
      try {

        await MeetingCommentsService.addMeetingComments(  state.accountId, state.memberId, state.meetingCode, state.otherComment, state.additionalComment);

      } catch (error) {
        // add error handling here
        console.log(error);
      }

      window.location.reload(false);
    toggle();

  }


  function toggle(e) {
   
    setState( prevState =>
      ({
      ...prevState,
      open: !state.open
      }
      ) 
    );
    
  }

  function handleChange(event) {
    let newValue = event.target.value;

    // handle the form values and set the state
    switch(event.target.id)
    {
    case "#MeetingCode":
      setState( prevState =>
        (
          {
            ...prevState,
            meetingCode: newValue
          }
        )
      );

      // Make the other comment visible
      if (newValue == 36){
        setState( prevState =>
          (
            {
              ...prevState,
              otherCommentVisibility: "visible"
            }
          )
        );
      }
      else {
        setState( prevState =>
          (
            {
              ...prevState,
              otherCommentVisibility: "invisible",
              otherComment: ""
            }
          )
        );
      }
          
      break;
    case '#OtherComment':
      setState(prevState =>
        (
          {
            ...prevState,
            otherComment: newValue
          }
        )

      );
      break;
      case '#AdditionalComment':
        setState(prevState =>
          (
            {
              ...prevState,
              additionalComment: newValue
            }
          )
  
        );
        break;
    default:
      break;
    }
  }

  

  const Option = (props) => {
    const {attribute_id, attribute_value, attribute_description} = props;
    if (attribute_id == state.meetingCode)
    {
      return ( <option selected value={attribute_id}>
        {attribute_value + ' - ' + attribute_description}
      </option>
      )
    }
    return ( <option  value={attribute_id}>
        {attribute_value + ' - ' + attribute_description}
    </option>
    )
  }

  const Options = (props) => {
    
    if (!meetingCodes) return (
      <span></span>
    );
    //alert(JSON.stringify(meetingCodes));
    return (
            
             <FormSelect id="#MeetingCode" onChange={handleChange.bind(this)} >
              <option>--Select--</option>
           {meetingCodes.map((meetingCodes) => (
                <Option attribute_id={meetingCodes.attribute_id}
                      attribute_value={meetingCodes.attribute_value} 
                      attribute_description={meetingCodes.attribute_description} />
            ))}
            </FormSelect> 
            )

  }

  return (
    
      <Row>
      <MeetingCommentsReport profile={state.profile} memberId={props.memberId}></MeetingCommentsReport>

      
    
        <div className="d-flex p-3 justify-content-center bd-highlight">
        <Button size="sm" className="mb-2 mr-1" onClick={toggle.bind(this)}>Add Comment</Button>
        </div>
          <Modal open={state.open}  centered={true}>
          <ModalHeader>Enter Meeting Comment</ModalHeader>
          <ModalBody>
            <Card small className="mb-4">                     
              <CardBody className="p-1">
                  <Row noGutters className="page-header px-0 py-4">
                      <Row>
                          
                        <Col>
                            <ListGroup flush>
                            <ListGroupItem>
                            <Row>
                              <Col>
                                  <Form role="form">
                                      <FormGroup>
                                      <label htmlFor="#Codes">Meeting Comment</label>
                                        <Options id="#Codes" />
                                        
                                        <div className={state.otherCommentVisibility}>
                                          <label htmlFor="#MeetingCode">Other Comment</label> 
                                          <FormInput id="#OtherComment" placeholder="Other Comment" value={state.otherComment} onChange={handleChange.bind(this)}/>
                                        </div>
                                        <label htmlFor="#AdditionalComment">Additional Comments</label>
                                        <FormTextarea id="#AdditionalComment" placeholder="Additional Comment" required value={state.additionalComment} onChange={handleChange.bind(this)}/>  
                                      </FormGroup>
                                      
                                  
                                  <div class="d-flex justify-content-center">
                                        
                                          <Button type="button" size="sm" theme="primary" className="mb-2 mr-1" onClick={addMessage.bind(this)}  >Save</Button>
                                          <label>&nbsp;&nbsp;</label>
                                          <Button size="sm" theme="secondary" className="mb-2 mr-1" onClick={toggle}>Cancel</Button>
                                        
                                  </div>
                                  </Form>
                              </Col>
                            </Row>
                            </ListGroupItem>
                            </ListGroup>
                        </Col>
                      </Row>
                  </Row>
                </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        
      </Row>
      
  )
}

