import React,{useRef} from "react";
import { Container, Button, Row, Col, Card, CardBody} from "shards-react";
import { ProtectedRoute } from "../../../ProtectedRoute";
import MeetingCommentsReport from "../../Client Views/Components/MeetingCommentsReport";
import ClearingReport from "../../Client Views/Components/ClearingReport";
import loginStore from "../../../flux/stores/loginStore"
import PageTitle from "../../../components/common/PageTitle";
import { useReactToPrint } from "react-to-print";
 
export default function MeetingNamesReport (props)
{
 
    let memberId = loginStore.getMemberId();
    let profile = loginStore.getProfile();
    const date = new Date();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
       content: () => componentRef.current,
       documentTitle: 'Monthly Meeting Report',
       
      });
   return (
    <ProtectedRoute>
    <Container className="main-content-container px-3 pb-3">
    
    <Row noGutters className="page-header py-4">
      <PageTitle
        sm="4"
        title="Meeting Name Report"
        className="text-sm-left"
      />
      </Row>
      <div className="d-flex gap-3 flex-row-reverse bd-highlight"> 
                <Button size="sm" theme="info" className="mb-2 mr-1" onClick={handlePrint}>Print</Button>
        </div>
        <div
                ref={componentRef}
                className="print-component"
              >
      <Row noGutters className="px-3">
        <Col>
        
        <Container></Container>
            <Row className="mb-3">
            
            <Card small >
            
            <CardBody>
            <div className="text-center fs-5">ROUND TABLE FLOOR COVERING CREDIT GROUP</div>
            <div className="text-center fs-6">{date.toLocaleString('default', { month: 'long' })} Meeting Clearing List</div>
            <div className="text-center fs-6">{date.toLocaleString('default', { month: 'long' }) + ' ' + date.getDate() + ', ' + date.getFullYear()}</div>
            </CardBody>
            </Card>
            </Row>
            <Row className="py-3">
            <ClearingReport refresh={true} memberId={memberId} profile={profile} view="Report"/>
            </Row>
            
      </Col>
      </Row>
      </div>
    </Container>
    </ProtectedRoute>
  )
}

