import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import DefaultLayout from "./layouts/DefaultLayout";
import LogInLayout from "./layouts/LogInLayout";
import LoginApp from "./layouts/LoginApp";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import Home from "./views/Home";
import Services from "./views/Services";
import AboutUs from "./views/AboutUs";
import ContactUs from "./views/ContactUs";
import Advertising from "./views/Advertising"; 
import LogIn from "./views/LogIn";
import LogOut from "./views/LogOut";
import Links from "./views/Links";
import Reports from "./views/Client Views/Reports";
import EnterPFC from "./views/Client Views/EnterPFC";
import About from "./views/About";
import ManageNSF from "./views/Client Views/ManageNSF";
import attorneys from "./views/attorneys";
import Banks from "./views/Banks";
import Collection from "./views/Collection";
import Insurance from "./views/Insurance";
import CreditReports from "./views/CreditReports";
import Finances from "./views/Finances";
import News from "./views/News";
import TestLayout from "./views/Client Views/Components/TestLayout";
import BaseLayout from "./views/BaseLayout";
import EnterNSF from "./views/EnterNSF";
import ViewTradeReport from "./views/Client Views/ViewTradeReport";
import ViewCustomerNSF from "./views/Client Views/ViewCustomerNSF";
import ViewCustomerPFC from "./views/Client Views/ViewCustomerPFC";
import ViewCustomerChangeRequest from "./views/Client Views/ViewCustomerChangeRequest";
import ViewMeetingComments from "./views/Client Views/ViewMeetingComments";
import ViewStandardReports from "./views/Client Views/ViewStandardReports";
import ViewChangeRequest from "./views/Client Views/ViewChangeRequest";
import MonthlyPFC from "./views/Client Views/Components/MonthlyPFC";
import MonthlyNSF from "./views/Client Views/Components/MonthlyNSF";
import ViewClearingNames from "./views/Client Views/ViewClearingNames";
import AccountsUnder75 from "./views/Client Views/Components/AccountsUnder75";
import LargestScoreChange from "./views/Client Views/Components/LargestScoreChange";
import ScoreDecileDistribution from "./views/Client Views/Components/ScoreDecileDistribution";
import ViewDashboard from "./views/Client Views/ViewDashboard";
import ViewAccountBalance from "./views/Client Views/ViewAccountBalance";
import Members from "./views/Admin Views/Components/Members";
import ViewAccountManagement from "./views/Admin Views/ViewAccountManagement";
import ViewAdminReports from "./views/Admin Views/ViewAdminReports";
import ViewFileProcessing from "./views/Admin Views/ViewFileProcessing";
import ViewReportRefresh from "./views/Admin Views/ViewReportRefresh";
import ViewCustomerUsage from "./views/Admin Views/ViewCustomerUsage";
import Users from "./views/Admin Views/Components/Users";
import MeetingNamesReport from "./views/Admin Views/Components/MeetingNamesReport";
import WebsiteChange from "./views/Client Views/Components/WebsiteChange";
import ManageCompanies from "./views/Admin Views/Components/ManageCompanies";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/home"/>
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/home",
    layout: DefaultLayout,
    component: Home
  },
  {
    path: "/services",
    layout: DefaultLayout,
    component: Services
  },
  {
    path: "/about-us",
    layout: DefaultLayout,
    component: AboutUs
  },
  {
    path: "/aboutthegroup",
    layout: DefaultLayout,
    component: About
  },
  {
    path: "/contact-us",
    layout: DefaultLayout,
    component: ContactUs
  },
  {
    path: "/advertising",
    layout: DefaultLayout,
    component: Advertising
  },
  {
    path: "/login",
    layout: DefaultLayout,
    component: LogIn,
    handler: LoginApp
  },
  {
    path: "/logout",
    layout: DefaultLayout,
    component: LogOut,
    handler: LoginApp
  },
  {
    path: "/attorneys",
    layout: DefaultLayout,
    component: attorneys,

  },
  {
    path: "/banks",
    layout: DefaultLayout,
    component: Banks,

  },
  {
    path: "/collection",
    layout: DefaultLayout,
    component: Collection,
  },
  {
    path: "/insurance",
    layout: DefaultLayout,
    component: Insurance,
  },
  {
    path: "/creditreports",
    layout: DefaultLayout,
    component: CreditReports,
  },
  {
    path: "/finances",
    layout: DefaultLayout,
    component: Finances,
  },
  {
    path: "/news",
    layout: DefaultLayout,
    component: News,
  },
  {
    path: "/testlayout",
    layout: DefaultLayout,
    component: TestLayout,
  },
  {
    path: "/ViewTradeReport",
    layout: LogInLayout,
    component: ViewTradeReport,
  },
  {
    path: "/ViewCustomerNSF",
    layout: LogInLayout,
    component: ViewCustomerNSF,
  },
  {
    path: "/ViewCustomerPFC",
    layout: LogInLayout,
    component: ViewCustomerPFC,
  },
  {
    path: "/ViewAccountBalance",
    layout: LogInLayout,
    component: ViewAccountBalance,
  },
  {
    path: "/AccountsUnder75",
    layout: LogInLayout,
    component: AccountsUnder75,
  },
  {
    path: "/ViewStandardReports",
    layout: LogInLayout,
    component: ViewStandardReports,
  },
  {
    path: "/ViewCustomerChangeRequest",
    layout: LogInLayout,
    component: ViewCustomerChangeRequest,
  },
  {
    path: "/WebsiteChangeRequest",
    layout: LogInLayout,
    component: WebsiteChange,
  },
  {
    path: "/ViewCustomerUsage",
    layout: LogInLayout,
    component: ViewCustomerUsage,
  },
  {
      path: "/MonthlyPFC",
      layout: LogInLayout,
      component: MonthlyPFC,
  },
  {
      path: "/MonthlyNSF",
      layout: LogInLayout,
      component: MonthlyNSF,
  },
  {
  path: "/ViewMeetingComments",
  layout: LogInLayout,
  component: ViewMeetingComments,
  },
  {
  path: "/ViewClearingNames",
  layout: LogInLayout,
  component: ViewClearingNames,
  },
  {
    path: "/MeetingNamesReport",
    layout: LogInLayout,
    component: MeetingNamesReport,
  },
  {
    path: "/ManageCompanies",
    layout: LogInLayout,
    component: ManageCompanies,
  },
  {
    path: "/ViewDashboard",
    layout: LogInLayout,
    component: ViewDashboard,
  },
  {
  path: "/ViewChangeRequest",
  layout: LogInLayout,
  component: ViewChangeRequest,
  },
  {
    path: "/baselayout",
    layout: DefaultLayout,
    component: BaseLayout,
  },
  {
    path: "/enternsf",
    layout: DefaultLayout,
    component: EnterNSF,
  },
  {
    path: "/links",
    layout: LogInLayout,
    component: Links
  },
  {
    path: "/reports",
    layout: LogInLayout,
    component: Reports
  },
  {
    path: "/ManageNSF",
    layout: LogInLayout,
    component: ManageNSF,
    handler: LoginApp
  },
  {
    path: "/EnterPFC",
    layout: LogInLayout,
    component: EnterPFC
  },
  {
    path: "/ScoreDecileDistribution",
    layout: LogInLayout,
    component: ScoreDecileDistribution,
  },
  {
    path: "/LargestScoreChange",
    layout: LogInLayout,
    component: LargestScoreChange,
  },
  {
    path: "/ViewAccountManagement",
    layout: LogInLayout,
    component: ViewAccountManagement,
  },
  {
    path: "/ViewAdminReports",
    layout: LogInLayout,
    component: ViewAdminReports,
  },
  {
    path: "/ViewReportRefresh",
    layout: LogInLayout,
    component: ViewReportRefresh,
  },
  {
    path: "/ViewFileProcessing",
    layout: LogInLayout,
    component: ViewFileProcessing,
  },
  {
    path: "/Members",
    layout: LogInLayout,
    component: Members,
  },
  {
    path: "/Users",
    layout: LogInLayout,
    component: Users
  }
];
