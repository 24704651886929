import React,{ useState, useEffect} from "react";
import { Container, CardHeader, Row } from "shards-react";
import ScoreService from "../../../flux/services/ScoreService";
import Chart from "../../../utils/chart";

 
export default function History (props)
{

  const [data, setData] = useState(null);
  const canvasRef = React.createRef();
  var colors = ['#007bff','#28a745','#333333','#c3e6cb','#dc3545','#6c757d'];
  var chartData = {
    labels: ["S", "M", "T", "W", "T", "F", "S"],
    datasets: [{
      data: [589, 445, 483, 503, 689, 692, 634],
      backgroundColor: 'transparent',
      borderColor: colors[0],
      borderWidth: 4,
      pointBackgroundColor: colors[0]
    }
  //   {
  //     data: [639, 465, 493, 478, 589, 632, 674],
  //     backgroundColor: colors[3],
  //     borderColor: colors[1],
  //     borderWidth: 4,
  //     pointBackgroundColor: colors[1]
  //   }
    ]
  };
  
  const chartOptions = {
    responsive: true,
    legend: {
      position: "top"
    },
    elements: {
      line: {
        // A higher value makes the line look skewed at this ratio.
        tension: 0.3
      },
      point: {
        radius: 0
      }
    },
    scales: {
      xAxes: [
        {
          gridLines: false,
          ticks: {
            callback(tick, index) {
              // Jump every 7 values on the X axis labels to avoid clutter.
              return index % 7 !== 0 ? "" : tick;
            }
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            suggestedMax: 45,
            callback(tick) {
              if (tick === 0) {
                return tick;
              }
              // Format the amounts using Ks for thousands.
              return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
            }
          }
        }
      ]
    },
    hover: {
      mode: "nearest",
      intersect: false
    },
    tooltips: {
      custom: false,
      mode: "nearest",
      intersect: false
    }
  }

 

  useEffect(() => {
    async function getScoreHistory(accountId)
    {
      try {
        // set loading to true before calling API
        const data = await ScoreService.getScoreHistory(accountId);
        setData(data);
 //      alert(JSON.stringify(data1));
      } catch (error) {
        // add error handling here
        console.log(error);
      }
    }
    getScoreHistory(props.accountId);

  }, []);

  const Rows = (props) => {
    const {date_id, account_id, score_type, amount} = props
    let asOfDate = date_id.toString().substring(4,6) + '/' + date_id.toString().substring(6,8) + '/' + date_id.toString().substring(2,4);


    return ( <tr>
      <td></td>
      <td>{asOfDate}</td>
      <td>{amount}</td>
      <td></td>
    </tr>
    )
  }

  const Table = (props) => {
    
    if (!data) return (
      <span>Data not available</span>
    );
    return (
        <Row className="px-3">
            <table className="table table-sm">
              <thead className="table-light">
                <tr>
                <th scope="col">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                <th scope="col">Report Date</th>
                <th scope="col">CCI Score</th>
                <th scope="col">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                </tr>
              </thead>
            <tbody> 
              
            {data.map((data) => (
                  <Rows key={data.scores_id} date_id={data.date_id} account_id={data.account_id} score_type={data.attribute.attribute_value} amount={data.amount}  />
                ))
            }

            </tbody>
            </table>
          </Row>)
  }

  const HistoryGraph = (props) => {
    //alert(JSON.stringify(chartData));
    /*const graph = new Chart(canvasRef.current, {
      type: "Line",
      data: chartData,
      options: {
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: false
            }
          }]
        },
        legend: {
          display: false
        },
        responsive: true
      }
    });*/

      return(
        <span></span>
        /*<canvas
            height="120"
            ref={this.canvasRef}
            style={{ maxWidth: "100% !important" }}
      />*/
      
    
      )

  }


  //alert(JSON.stringify(props));
  return (
    
    <Container>
      
      <CardHeader className="border-bottom">
        <h5>
          History
        </h5>
      </CardHeader>
      <Table data={props}/>
      <HistoryGraph />

    </Container>
  )
}

