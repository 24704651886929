import React, { useState, useEffect } from "react";
//import addons from "react-addons/index";
import { Form, FormInput, FormGroup, Button, ListGroup, ListGroupItem, Row, Col, Container } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import LoginApp from "../../layouts/LoginApp";
import { Redirect } from "react-router-dom";
import { CardHeader, Card, CardBody } from "shards-react";
import CustomerSearch from "./Components/CustomerSearch";
//var classSet = React.classSet;


class ManageNSF extends LoginApp {
    
    constructor(props) {
      super()

    }

    /*handleChange(event) {
       //alert('event triggered ' + event.target.id);
        if (event.target.id === "#username")
            this.setState({user: event.target.value});
        if (event.target.id === "#password")
            this.setState({password: event.target.value});
    }
  
    login(e) {
      e.preventDefault();
      Auth.login(this.state.user, this.state.password)
        .catch(function(err) {
          alert("There's an error logging in");
          console.log("Error logging in", err);
        });
    }*/

  
    render() {
      if (!this._getLoginState().userLoggedIn)
      {
        
        return (
          <Redirect to='/login' />
        )
      }
      return (
        
        <Container className="main-content-container px-5 pb-4">
          
        <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Manage NSF"
              className="text-sm-left"
            />
        </Row>
            <Row className="px-0">
             <Col lg="8">
                <Card small className="px-3 py-3">
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Search</h6>
                  </CardHeader>
                  <CardBody className="p-0">
                    <CustomerSearch></CustomerSearch>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          
       
      </Container>
      );
    }
  }

  export default ManageNSF;


//ReactMixin(Login.prototype, React.LinkedStateMixin);