'use strict';

import React from 'react';
import LoginStore from '../flux/stores/loginStore';
import searchStore from '../flux/stores/searchStore';
import AuthService from '../flux/services/AuthService';
import { Redirect } from 'react-router-dom';

export default class LoginApp extends React.Component  {
  constructor() {
    super()
    //LoginStore.isLoggedIn();
    this.state = this._getLoginState();
    
  }

  _getLoginState() {
    return {
      userLoggedIn: LoginStore.isLoggedIn()
    };
  }

  componentDidMount() {
    this.changeListener = this._onChange.bind(this);
    LoginStore.addChangeListener(this.changeListener);
    searchStore.addChangeListener(this.changeListener);
  }

  _onChange() {
    this.setState(this._getLoginState());
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.changeListener);
    searchStore.removeChangeListener(this.changeListener);
  }



  logout(e) {
    e.preventDefault();
    AuthService.logout();
  }

}
