let formatDollar = (str) => 
{
  
  let USDollar =  new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0, 
  minimumFractionDigits: 0,
});
  return USDollar.format(str);
}

export default formatDollar;