import AppDispatcher from "../dispatcher";
import actionTypes from "./actionTypes";
import { Constants } from "../../flux";
import RouterContainer from "../services/RouterContainer";
import { redirect } from "react-router";

import { useHistory } from "react-router";
import { Redirect } from "react-router";

/*
export function getLogin() {
    dispatcher.dispatch({
        actionTypes: actionTypes.SUBMIT,
        login: {Username: '', Password: ''},
    });
}

export function doLogin(login) {
    dispatcher.dispatch({
        actionTypes: actionTypes.SUBMIT,
        login: login,
    });
}
*/

export default {
    loginUser: (jwt, userInfo) => {
      var savedJwt = localStorage.getItem('jwt');
      AppDispatcher.dispatch({
        actionTypes: Constants.LOGIN_USER,
        jwt: jwt,
        memberId: userInfo.member_id,
        userName: userInfo.user_name,
        profile: userInfo.profile
      });
      
      if (savedJwt !== jwt) {
        
        /*let routerContainer = RouterContainer.get();
        alert("routerContainer: " + routerContainer);
        var nextPath = RouterContainer.get().getCurrentQuery().nextPath || '/';
        alert("nextPath: " + nextPath);
        RouterContainer.get().transitionTo(nextPath);*/
        // RouterContainer.get().history.push('/');//transitionTo('/');
        //RouterContainer.get().history.push('/home');//history.push('/');
        //alert("routerContainer" + RouterContainer.get().transitionTo('/'))
        localStorage.setItem('jwt', jwt);
        localStorage.setItem('memberId', userInfo.member_id);
        localStorage.setItem('userName', userInfo.user_name);
        localStorage.setItem('profile', userInfo.profile);
      }
    },
    logoutUser: () => {
      
      AppDispatcher.dispatch({
        actionTypes: Constants.LOGOUT_USER
      });
      //RouterContainer.get().transitionTo('/login');
      localStorage.removeItem('jwt');
      localStorage.removeItem('memberId');
      localStorage.removeItem('profile');
      localStorage.removeItem('userName');
      localStorage.clear();
      
    }
  }