import React, {useState, useEffect} from "react";
import PlacedForCollectionsService from "../../../flux/services/PlacedForCollectionsService";
import format from "../../../utils/format";
import loginStore from "../../../flux/stores/loginStore";
import { ListGroup, ListGroupItem, ListGroupItemText, Button, Row, ListGroupItemHeading, Card, CardHeader, CardBody } from "shards-react";
import SearchService from "../../../flux/services/SearchService";
import { Redirect } from "react-router-dom";


export default function MemberAlerts(props) {

    const memberId = loginStore.getMemberId()

  const [data,setData] = useState(null)
  const [view, setView] = useState(
    {
        location: "Alerts"
    }
  )

  useEffect(() => {
    async function getPlacedForCollectionsToday(memberId)
    {
        try {
        // set loading to true before calling API
        const data = await PlacedForCollectionsService.getTodayPFC(memberId);
        setData(data);
        } catch (error) {
        // add error handling here
        console.log(error);
        }
    }
    getPlacedForCollectionsToday(memberId);
  }, []);

  async function handleClick(pfc)
    {
        
        let _response = await SearchService.getCustomerRecordByInternalId(pfc.account_id);
        if (_response == false)
          alert("Error returning Customer Record for CCI ID: " + pfc.account_id)
        else
          setView({location: "Report"});
    }

if(view.location == "Report")
    return (
        <Redirect to="/ViewTradeReport" />
    )

  if(data == null)
    return (
    <div></div>
    )

    return (
    <Card style={{minHeight: 300}}>
        <div class="d-flex justify-content-center"><span className='fw-bold'>Placed For Collections Today</span></div>
        <CardBody>
    <ListGroup>

    {data.map((data) => (
    <div>
    
    <ListGroupItem className="d-flex justify-content-left m-0 p-0"><Button style={{textAlign: 'left', width: "100%"}} className="btn-outline-light mx-auto m-0 border-0" onClick={() => handleClick(data)}>
        
        <div>{'CCI Id: ' + data.cci_account_id}</div>
        <div>{'Name: ' + data.account_name}</div>
        <div>{'City/State: ' + data.city + ', ' + data.state}</div>
        <div>{'Amount: ' + data.amount}</div>
        </Button></ListGroupItem>
    </div>
    ))}
    </ListGroup>
    </CardBody>
    </Card>

)
}