import React,{ useState, useEffect} from "react";
import { Container, Button, Card,CardBody, Col, CardHeader, Row, Modal, ModalHeader, ModalBody, Form, FormGroup, FormInput, FormTextarea } from "shards-react";
import NSFCheckService from "../../../flux/services/NSFCheckService";
import ChangeService from "../../../flux/services/ChangeService";
import format from "../../../utils/format";

 
export default function CustomerChangeRequest (props)
{
  
  const [state, setState]= useState({
    open: false,
    name: null,
    address: null,
    phone: null,
    accountMergeId: props.internalAccountId,
    accountMergeIntoCCIId: null,
    contactName: null,
    contactEmail: null,
    otherRequest: null,
    memberId: props.memberId
  });
  const [data, setData] = useState(null);
  useEffect(() => {
    async function getCustomerChangeRequest(memberId){
      try {
        // set loading to true before calling API
        const data = await getChangeRequests(memberId);
        setData(data);
       // alert(JSON.stringify(data));
      } catch (error) {
        // add error handling here
        console.log(error);
      }

    }
    getCustomerChangeRequest(props.memberId);

  }, []);

  
  async function getChangeRequests(memberId)
  {
      return ChangeService.getCustomerChangeRequests(memberId);
  }
  
  function toggle(e) {
   
    setState( prevState =>
      ({
      ...prevState,
      open: !state.open
      }
      ) 
    );
    
  }

  async function addChangeRequest(e) {

    await ChangeService.addCustomerChangeRequest(state);
      try {
        // set loading to true before calling API
        const data = await getChangeRequests(state.memberId);
        setData(data);
       // alert(JSON.stringify(data));
      } catch (error) {
        // add error handling here
        console.log(error);
      }
    toggle();

  }

  function handleChange(event) {
    let newValue = event.target.value;
    switch(event.target.id)
    {
    case "#Name":
        setState( prevState =>
          (
            {
              ...prevState,
              name: newValue
            }
          )
        );
      break;
    case "#Address":
      setState( prevState =>
        (
          {
            ...prevState,
            address: newValue
          }
        )
      );
      break;
    case "#Phone":
      setState( prevState =>
        (
          {
            ...prevState,
            phone: newValue
          }
        )
      );
      break;
    case "#MergeIntoAccount":
      setState( prevState =>
        (
          {
            ...prevState,
            accountMergeIntoCCIId: newValue
          }
        )
      );
      break;
    case "#ContactName":
    setState( prevState =>
      (
        {
          ...prevState,
          contactName: newValue
        }
      )
    );
    break;
    case "#ContactEmail":
      setState( prevState =>
        (
          {
            ...prevState,
            contactEmail: newValue
          }
        )
      );
      break;
      case "#OtherRequest":
      setState( prevState =>
        (
          {
            ...prevState,
            otherRequest: newValue
          }
        )
      );
      break;
    default:
      break;
    }
  }

  //Remove the NSF Checks
  async function DeleteCustomerChangeRequest(changeRequestId)
  {

    await ChangeService.deleteCustomerChangeRequest(changeRequestId);
    try {
      // set loading to true before calling API
      const data = await getChangeRequests(state.memberId);
      setData(data);

    } catch (error) {
      // add error handling here
      console.log(error);
    }

  }

  //add a delete button for the member to delete its own NSF Record
  const DeleteButton = (props) =>
  {
    
      return(
        <div>
        <Button size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => DeleteCustomerChangeRequest(props.changeRequestId)} >Delete</Button>
        </div>
      )
  }

  const Rows = (props) => {
    const {change_request_id, create_date, corrected_phone_number, corrected_address,
            corrected_name, merge_account_id, merge_into_cci_account_id,
            contact_name, contact_email, other_request,
            completed_date, change_status} = props
    
    let otherRequest = '';
    let infoCorrections = '';
    let mergeRequest = '';

    if (corrected_phone_number != '')
      infoCorrections = '| Phone: ' + format.formatPhoneNumber(corrected_phone_number);
    if (corrected_address != '')
      infoCorrections = infoCorrections +  ' | Address: ' + corrected_address;
    if (corrected_name != '')
      infoCorrections = infoCorrections +  ' | Name: ' + corrected_name;
  
    if (merge_into_cci_account_id != 0 && merge_into_cci_account_id != '')
      mergeRequest = merge_account_id + ' -> ' + merge_into_cci_account_id;

    if (other_request != '')
      otherRequest = other_request.substring(0,30) + ' ...';
    return ( <tr>
      <td>{change_request_id}</td>
      <td>{format.formatDate(create_date)}</td>
      <td>{change_status}</td>
      <td>{infoCorrections}</td>
      <td>{mergeRequest}</td>
      <td>{otherRequest}</td>
      <td><DeleteButton changeRequestId={change_request_id}></DeleteButton></td>
    </tr>
    )
  }

  const Table = (props) => {
    if (!data) return (
      <span>Data not available</span>
    );
    return (
       <Container>
        <Row small className="mb-4">
            <table className="table table-sm">
              <thead className="table-light">
                <tr>
                <th scope="col" >CR Id</th>
                <th scope="col" >Date Requested</th>
                <th scope="col">Status</th>
                <th scope="col">Info Changes</th>
                <th scope="col">Merge Requests</th>
                <th scope="col">Other Requests</th>
                <th scope="col"></th>
                </tr>
              </thead>
            <tbody> 
              
              {data.map((data) => (
                <Rows key={data.customer_change_request_id} change_request_id={data.customer_change_request_id} create_date={data.created_date} 
                corrected_phone_number={data.corrected_phone_number} corrected_address={data.corrected_address} corrected_name={data.corrected_legal_name} 
                merge_account_id={data.merge_account_id} merge_into_cci_account_id={data.merge_into_cci_account_id} contact_name={data.contact_name} 
                contact_email={data.contact_email} other_request={data.other_request} completed_date={data.completed_date} change_status={data.change_status}/>
              ))}

            </tbody>
            </table>
        </Row>
        </Container>
        )
  }


  //alert(JSON.stringify(props));
  return (
    

      <Container>
      <CardHeader className="border-bottom">
        <h5>
          Change Request
        </h5>
      </CardHeader>

      <Table data={props}/>

      <Row>
      <div className="d-flex justify-content-center bd-highlight">
        <Button size="sm" className="mb-2 mr-1" onClick={toggle.bind(this)}>Add Request</Button>
        <Modal open={state.open}  centered={true}>
          <ModalHeader>New Customer Change Request</ModalHeader>
          <ModalBody>
            <Card className="mb-4">                     
              <CardBody className="p-1">
                  <Row noGutters className="page-header px-0 py-4">
                      <Row>
                        <Col className="m-4">
                           
                              <h6>Customer Info</h6>
                                  <Form role="form">
                                      <FormGroup>
                                      <label htmlFor="#Name">Replace Name</label>
                                        <FormInput id="#Name" placeholder="New Name" required value={state.name} onChange={handleChange.bind(this)} />  
                                      <label htmlFor="#Address">Replace Address</label>
                                          <FormInput id="#Address" placeholder="New Address" required value={state.address} onChange={handleChange.bind(this)} />
                                      <label htmlFor="#Phone">Replace Phone</label>
                                        <FormInput id="#Phone" placeholder="New Phone Number" required value={state.phone} onChange={handleChange.bind(this)} />   
                                      </FormGroup>
                                      
                                  </Form>
                              <h6>Merge Customer</h6>
                                  <Form role="form">
                                      <FormGroup>
                                      <label htmlFor="#MergeAccount">Account to Merge</label>
                                        <FormInput id="#MergeAccount" required value={state.accountMergeId} onChange={handleChange.bind(this)} />  
                                      <label htmlFor="#MergeIntoAccount">Merge Into</label>
                                          <FormInput id="#MergeIntoAccount" placeholder="CCI Account to Merge Into" required value={state.accountMergeIntoCCIId} onChange={handleChange.bind(this)} />
                                      </FormGroup>
                                      
                                  </Form>
                              <h6>Other Request</h6>
                                  <Form role="form">
                                      <FormGroup>
                                      <label htmlFor="#ContactName">Contact Name</label>
                                        <FormInput id="#ContactName" placeholder="Contact Name" required value={state.contactName} onChange={handleChange.bind(this)} />  
                                      <label htmlFor="#ContactEmail">Contact Email</label>
                                          <FormInput id="#ContactEmail" placeholder="Contact Email" required value={state.contactEmail} onChange={handleChange.bind(this)} />
                                      <label htmlFor="#OtherRequest">Other Request</label>
                                          <FormTextarea id="#OtherRequest" placeholder="Other Request" required value={state.otherRequest} onChange={handleChange.bind(this)} />
                                      </FormGroup>
                                      
                                  </Form>
                                  <div class="d-flex justify-content-center">
                                        
                                          <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={addChangeRequest.bind(this)}>Save</Button>
                                          <label>&nbsp;&nbsp;</label>
                                          <Button size="sm" theme="secondary" className="mb-2 mr-1" onClick={toggle}>Cancel</Button>
                                        
                                  </div>

                        </Col>
                      </Row>
                  </Row>
                </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        </div>
      </Row>
      

      </Container>
    
  )
}

