import React from "react";
//import addons from "react-addons/index";
import { Row, Col, Container, Card, CardHeader, CardBody } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { ProtectedRoute } from "../../ProtectedRoute";
import getViewContext from "../Context/ViewContext";
import { Link } from "react-router-dom";

//var classSet = React.classSet;


export default function ViewStandardReports(props) {
    
    
      
      return (
      
      <ProtectedRoute>
      <Container>
        <Row noGutters  className="page-header py-4">
            <PageTitle
                sm="4"
                title="Standard Reports"
                className="text-sm-left"
            />
        </Row>
        <Row className="px-3 py-5" > 
            <Col lg="3" className="flex-column align-items-stretch">
                <Card className="h-50 mb-4">
                  <Link to="/MonthlyPFC">
                    <CardHeader className="border-bottom">
                    <h6 className="m-0">Monthly PFC Report</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="blog-comments__content">
                        <p className="m-2 my-1 mb-2 text-muted">
                            The PFC pulls all of the accounts Placed for Collections for the number of months specified.
                        </p>
                        </div>
                  </CardBody>
                  </Link>
                </Card> 
                <Card className="h-50 mb-4">
                  <Link to="/LargestScoreChange">
                    <CardHeader className="border-bottom">
                    <h6 className="m-0">Largest Score Change</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="blog-comments__content">
                        <p className="h-25 m-2 my-1 mb-2 text-muted">
                            The Largest Score Change pull the list of member accounts that had the largest score change in the past month.
                        </p>
                        </div>
                    </CardBody>
                    </Link>
                </Card>   
              </Col>
              <Col lg="3" className="flex-column align-items-stretch">
                <Card className="h-50 mb-4">
                  <Link to="/MonthlyNSF">
                    <CardHeader className="border-bottom">
                    <h6 className="m-0">Monthly NSF Report</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="blog-comments__content">
                        <p className=" m-2 my-1 mb-2 text-muted">
                            The NSF pulls all of the accounts who had a Non sufficient Funds posted on their account for the number of months specified.
                        </p>
                        </div>
                  
                    </CardBody>
                    </Link>
                </Card> 
                <Card className="h-50 mb-4">
                  <Link to="/ScoreDecileDistribution">
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Score Decile Distribution</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="blog-comments__content">
                        <p className="h-25 m-2 my-1 mb-2 text-muted">
                            The Score Decile Distribution shows the range of scores of all customers for a member.
                        </p>
                        </div>

                  </CardBody>
                    </Link>
                </Card> 
            </Col>
            <Col lg="3" className="flex-column align-items-stretch">
                <Card className="h-50 mb-4">
                  <Link to="/AccountsUnder75">
                    <CardHeader className="border-bottom">
                    <h6 className="m-0">List Accounts Below 75</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="blog-comments__content">
                        <p className="h-25 m-2 my-1 mb-2 text-muted">
                            The List Accounts Below 75 report provides all of the accounts that have fallen below 75 in the last month.
                        </p>
                        </div>

                    </CardBody>
                  </Link>
                </Card> 
            </Col>
        </Row>
      </Container>
      </ProtectedRoute>
    );
    
    }
/*
export function useViewContext()
{
  return viewContext;
}*/
