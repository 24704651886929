import React, {useContext,useState, useEffect, useRef} from "react";
import {Container, Row, Col, Card, CardHeader, CardBody, ListGroup, ListGroupItem, Form, FormGroup, FormInput, Button, FormSelect} from "shards-react";
import searchStore from "../../../flux/stores/searchStore";
import loginStore from "../../../flux/stores/loginStore";
//import { useViewContext } from "../ViewTradeReport";
import getToggleContext from "../../Context/ToggleContext";
import format from "../../../utils/format";
import SearchService from "../../../flux/services/SearchService";



export default function AccountContact (props) {
    let customerRecord = searchStore.getCustomerRecord();
    let user = loginStore.getUser();
    const contacttypeOptions = ["Owner", "President", "Vice President", "Accounts Receivable", "Accounts Payable", "Controller", "Manager", "Associate", "Other"];
  
    const [toggle, setToggle] = useContext(getToggleContext());
    //alert(JSON.stringify(customerRecord[0]));
    const [data, setData] = useState(null);
    const [state, setState]= useState({
      firstName: props.contact.firstName,
      lastName: props.contact.lastName,
      contactType: props.contact.contactType,
      phone: props.contact.phone,
      email: props.contact.email,
      accountContactId: props.contact.accountContactId,
      memberId: loginStore.getMemberId(),
      accountId: customerRecord[0].account_id,
      cciAccountId: customerRecord[0].cci_account_id,
      profileId: loginStore.getProfile()
    });
    
    
        
      //optionToSelect.selected = true;


    function handleChange(event) {
      let newValue = event.target.value;
      switch(event.target.id)
      {
      case "#FirstName":
          setState( prevState =>
            (
              {
                ...prevState,
                firstName: newValue
              }
            )
          );
        break;
      case "#LastName":
        setState( prevState =>
          (
            {
              ...prevState,
              lastName: newValue
            }
          )
        );
        break;
        case "#Phone":
          setState( prevState =>
            (
              {
                ...prevState,
                phone: newValue
              }
            )
          );
          break;
          case "#Email":
          setState( prevState =>
            (
              {
                ...prevState,
                email: newValue
              }
            )
          );
          break;
          case "#ContactType":
          setState( prevState =>
            (
              {
                ...prevState,
                contactType: newValue
              }
            )
          );
          break;
      default:
        break;
      }
    }

    function cancel(e) {
        setToggle({toggle: false})
    }
   
    async function addContact(e) {
      if (state.accountContactId != null)
        await SearchService.updateAccountContact(state);
      else
        await SearchService.addAccountContact(state);
        
        setToggle({ toggle: false })
        //window.location.reload()
        
    }
  


   
    const ContactTypeSelect = (props) =>
    {

        if (props.contactOption == props.contactType)
          return <option selected value={props.contactOption}>{props.contactOption }</option>

        return <option value={props.contactOption}>{props.contactOption }</option>
      
    }

    return (

        

          <Card small className="mb-4">                     
            <CardBody className="p-1">
                <Row noGutters className="page-header px-0 py-4">
                    <Row>
                        
                      <Col>
                          <ListGroup flush>
                          <ListGroupItem>
                          <Row>
                            <Col>
                                <Form role="form">
                                    <FormGroup>
                                    <label htmlFor="#FirstName">First Name</label>
                                      <FormInput id="#FirstName" placeholder="First Name" required value={state.firstName} onChange={handleChange.bind(this)} /> 
                                    <label htmlFor="#LastName">Last Name</label>
                                      <FormInput id="#LastName" placeholder="Last Name" required value={state.lastName} onChange={handleChange.bind(this)} />   
                                    <label htmlFor="#Phone">Phone Number</label>
                                        <FormInput id="#Phone" placeholder="Phone Number" value={state.phone} onChange={handleChange.bind(this)} />
                                    <label htmlFor="#Email">Email</label>
                                        <FormInput id="#Email" placeholder="Email" value={state.email} onChange={handleChange.bind(this)} />
                                    <label htmlFor="#ContactType">Contact Type</label> 
                                        <FormSelect id="#ContactType" onChange={handleChange.bind(this)}>
                                        <option>-- Choose One --</option>
                                        {contacttypeOptions.map((contact_type) => (
                                            <ContactTypeSelect contactOption={contact_type} contactType={state.contactType} />
                                          ))}
                                          
                                          
                                          </FormSelect>
                                    </FormGroup>
                                    
                                </Form>
                                <div class="d-flex justify-content-center">
                                      
                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={addContact.bind(this)}>Save</Button>
                                        <label>&nbsp;&nbsp;</label>
                                        <Button size="sm" theme="secondary" className="mb-2 mr-1" onClick={cancel.bind(this)}>Cancel</Button>
                                      
                                </div>
                            </Col>
                          </Row>
                          </ListGroupItem>
                          </ListGroup>
                      </Col>
                    </Row>
                </Row>
              </CardBody>
          </Card>
        

    )
  }
