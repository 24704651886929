import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";

const DefaultLayout = ({ children, noNavbar, noFooter }) => (
  <Container fluid>
    <Row>
   {/* <Col
        lg={{ size: 3, offset: 2}}
        md={{ size: 2, offset: 2 }}
        sm={{ size: 12, offset: 0}}
        tag="sidebar"
      >  */}
      <MainSidebar/>
      {/* </Col> */}
      <Col
        className="main-content p-1"
        lg={{ size: 10, offset: 2}}
        md={{ size: 9, offset: 3 }}
        sm={{ size: 9, offset: 0}}
        tag="main"
      >
        {!noNavbar && <MainNavbar />}
        {children}
        {!noFooter && <MainFooter />}
      </Col>
    </Row>
  </Container>
);

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default DefaultLayout;
