import React from "react";
import { Container, Button, Row, Col, Card, form, CardHeader} from "shards-react";
import PageTitle from "../components/common/PageTitle";
const Advertising = () => (
  <Container className="main-content-container px-5 pb-3">
      <Col>
        <Row noGutters className="page-header py-4"> 
          <PageTitle
            sm="4"
            title="Advertise With Us!"
            className="text-sm-left"
          />
        </Row>
        <Row className="px-0"> 
          <Col>
            <Card fluid className="main-content-container px-3 py-3">
            <CardHeader className="border-bottom">
              <h4>Advertising Rates</h4>
              <h6> Logo/banner links cost $1000 per year.  Featured Provider advertising costs an additional $5000 per year.

                Logo/banner links headlining a page cost $5000 per year.

                  Multiple logo/banner/link discounts are available. </h6>
              </CardHeader    >
              <CardHeader className="border-bottom">
              <h4>Terms & Conditions</h4>
              <h6>  You may use any suitable logo or link.  For an additional fee we will highlight your service in our "Featured Provider" area, a 2" X  2.5" block.  Click here to see a sample "Featured Provider" advertisement.

                Floorcredit.com has the right to reject any logo or ad copy deemed inappropriate.

                You may change your advertisement as often as you like.

                Advertising periods are 12 months.

                Advertising Fees must be paid in advance.

                You agree to pay all invoices for advertising  in accordance with the terms of the invoice.  Failure to make timely payment will removal of your advertisement.</h6>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        </Col>
  </Container>
);

export default Advertising;
