import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col } from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarSearch from "./SidebarSearch";
import SidebarNavItems from "./SidebarNavItems";
import loginStore from "../../../flux/stores/loginStore";

import { Store } from "../../../flux";

let profile = "";

class MainSidebar extends React.Component {
  
  constructor(props) {
    super(props);
    profile = loginStore.getProfile();
    
    this.state = {
      menuVisible: false,
      sidebarNavItems: this.getSidebarNavItems(profile),
      subSidebarNavItems: this.getSubSidebarNavItems(profile)
    };
    //alert (JSON.stringify(this.state.sidebarNavItems));

    this.onChange = this.onChange.bind(this);
  }

  getSidebarNavItems(profile)
  {
    
    if (profile == "member")
      return Store.getMemberSidebarItems();
    else if (profile == "alacarte")
      return Store.getAlacartSidebarNavItems();
    else if (profile == "admin")
      return Store.getAdminSidebarNavItems();

    return Store.getSidebarItems();
  }

  getSubSidebarNavItems(profile)
  {
    if (profile == "admin")
      return Store.getAdminSubSidebarNavItems();

    return null;
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: this.getSidebarNavItems(profile),
      subSidebarNavItems: this.getSubSidebarNavItems()
    });
  }

  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      "col-12",
      this.state.menuVisible && "open"
    );

    return (
      <Col
        tag="aside"
        className={classes}
        lg={{ size: 2 }}
        md={{ size: 3 }}  
        sm={{ size: 0 }}
      >
        <SidebarMainNavbar hideLogoText={this.props.hideLogoText} />
        <SidebarSearch />
        <SidebarNavItems profile={profile} />
      </Col>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

export default MainSidebar;
