import React, { useState, createContext } from "react";
//import addons from "react-addons/index";
import { Row, Col, Container, Card } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { ProtectedRoute } from "../../ProtectedRoute";
import loginStore from "../../flux/stores/loginStore";
import RefreshReports from "./Components/RefreshReports";

//var classSet = React.classSet;


export default function ViewReportRefresh() {
   
      let memberId = loginStore.getMemberId();
      let profile = loginStore.getProfile();
      return (
      
      <ProtectedRoute>
      <Container className="main-content-container px-4 pb-3">
        <Row noGutters className="page-header py-4">
      <PageTitle
        sm="4"
        title="Refresh Reports"
        className="text-sm-left"
      />
      </Row>
      <Row noGutters className="px-0">
            <Col>
        
            <RefreshReports profile={profile} memberId={memberId}/> 
          
          </Col>
      </Row>
      
        
      
      </Container>
      </ProtectedRoute>
    );
    
    }