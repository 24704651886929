export default function() {
    return [
      {
        title: "Account Management",
        htmlBefore: '<i class="material-icons">edit</i>',
        to: "/ViewAccountManagement",
      },
      {
        title: "Refresh Reports",
        htmlBefore: '<i class="material-icons">vertical_split</i>',
        to: "/ViewReportRefresh",
      },
      {
        title: "File Process",
        htmlBefore: '<i class="material-icons">vertical_split</i>',
        to: "/ViewFileProcessing",
      },
      {
        title: "Admin Reports",
        htmlBefore: '<i class="material-icons">view_module</i>',
        to: "/ViewAdminReports",
      }
      
      
    ];
  }
  