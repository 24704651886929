import React,{ useState, useEffect} from "react";
import { Container, CardHeader, Row } from "shards-react";
import SummaryService from "../../../flux/services/SummaryService";

 
export default function Trends (props)
{

  const [data, setData] = useState(null);
  useEffect(() => {
      async function getTrends(accountId)
      {
        try {
          // set loading to true before calling API
          const data = await SummaryService.getTrends(accountId); 
          setData(data);
        // alert(JSON.stringify(data));
        } catch (error) {
          // add error handling here
          console.log(error);
        }
      }
      getTrends(props.accountId);
    }, []);

  const Rows = (props) => {
    const {report_date_id,recent_high_credit,account_balance,current_balance,one_day_past_due_amt, thirty_day_past_due_amt, sixty_day_past_due_amt, ninety_day_past_due_amt, pct_current,pct_one_day_past_due, pct_thirty_day_past_due, pct_sixty_day_past_due, pct_ninety_day_past_due} = props
    let asOfDate = report_date_id.toString().substring(4,6) + '/' + report_date_id.toString().substring(6,8) + '/' + report_date_id.toString().substring(2,4);
    let USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0, 
      minimumFractionDigits: 0,
    });

    return ( <tr>
      <td></td>
      <td>{asOfDate}</td>
      <td align="right">{USDollar.format(recent_high_credit)}</td>
      <td align="right">{USDollar.format(account_balance)}</td>
      <td></td>
      <td>{pct_current=="0"?"":pct_current + "%"}</td>
      <td>{pct_one_day_past_due=="0"?"":pct_one_day_past_due + "%"}</td>
      <td>{pct_thirty_day_past_due=="0"?"":pct_thirty_day_past_due + "%"}</td>
      <td>{pct_sixty_day_past_due=="0"?"":pct_sixty_day_past_due + "%"}</td>
      <td>{pct_ninety_day_past_due=="0"?"":pct_ninety_day_past_due + "%"}</td>
    </tr>
    )
  }

  const Table = (props) => {
    
    if (!data) return (
      <span>Data not available</span>
    );
    //alert(JSON.stringify(data))]
    return (
            <Row className="px-3">
              <table className="table table-sm">
                  <thead className="table-light">
                    <tr>
                    <th scope="col" style={{width: '150px'}}></th>
                    <th scope="col">As Of Date</th>
                    <th scope="col"><div class="w-100 text-end">High Credit</div></th>
                    <th scope="col"><div class="w-100 text-end">Balance</div></th>
                    <th scope="col" style={{width: '50px'}}></th>
                    <th scope="col">Current</th>
                    <th scope="col">1-30</th>
                    <th scope="col">31-60</th>
                    <th scope="col">61-90</th>
                    <th scope="col">90+</th>
                    </tr>
                  </thead>
                <tbody> 
              
                  {data.map((data) => (
                      <Rows key={data.credit_summary_id} report_date_id={data.report_date_id} recent_high_credit={data.recent_high_credit} account_balance={data.account_balance} current_balance={data.current_balance} one_day_past_due_amt={data.one_day_past_due_amt} 
                            thirty_day_past_due_amt={data.thirty_day_past_due_amt} sixty_day_past_due_amt={data.sixty_day_past_due_amt}
                            ninety_day_past_due_amt={data.ninety_day_past_due_amt}  pct_current={data.pct_current}
                            pct_one_day_past_due={data.pct_one_day_past_due} pct_thirty_day_past_due={data.pct_thirty_day_past_due} pct_sixty_day_past_due={data.pct_sixty_day_past_due}
                            pct_ninety_day_past_due={data.pct_ninety_day_past_due}/>
                    ))
                  }
                </tbody>


                  

              </table>
            </Row>
    )
  }

  //alert(JSON.stringify(props));
  return (
    
    <Container>
      
      <CardHeader className="border-bottom">
        <h5>
          Trends
        </h5>
      </CardHeader>
      <Table data={props}/>
    </Container>
  )
}

