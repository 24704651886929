import supabase from "../stores/supabase";
import AppDispatcher from "../dispatcher";
import { Constants } from '../../flux';
import format from "../../utils/format";
import { json } from "react-router";


class SearchService {

   
  async search(phone, cciId, name, city, state ) {
  
    if(cciId !== '')
      return this.searchCCID(cciId);
    else if (phone !== '')
      return this.searchPhone(phone);
    else if (name !== '' && state !== '')
      return this.searchNameState(name,state);
    else if (name !== '')
      return this.searchName(name);
    else if (name !== '' && city !== '')
      return this.searchNameCity(name,city);
    else if (city !== '')
      return this.searchCity(city);


    return null;


  }

  async searchCCID(cci_id)
  {
    let { data: account, error } = await supabase
        .from('account')
        .select('account_id, cci_account_id,account_name,employer_identification_number, phone, address1, city, state, created_date, is_primary')
        .eq('cci_account_id', cci_id) 
        .eq('is_deleted', 0)
        .order('is_primary', { ascending: false})                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
        ;
    
    if (error != undefined)
    {
      alert(JSON.stringify(error));
      return null;
    }
    if (account == null)
      return null;

    return account;

  }

  async searchPhone(phone)
  {
    let { data: account, error } = await supabase
        .from('account')
        .select('account_id, cci_account_id,account_name,employer_identification_number, phone, address1, city, state, created_date')
        .eq('phone', phone)   
        .eq('is_deleted', 0) 
        .order('is_primary', { ascending: false})                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
        ;
    
    if (error != undefined)
    {
      alert(JSON.stringify(error));
      return null;
    }
    if (account == null)
      return null;

    return account;

  }

  async searchName(name)
  {
    let { data: account, error } = await supabase
        .from('account')
        .select('account_id, cci_account_id,account_name,employer_identification_number, phone, address1, city, state, created_date')
        .like('account_name', '%' + name.toUpperCase() + '%')  
        .eq('is_deleted', 0)
        .order('is_primary', { ascending: false})                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
        ;
    
    if (error != undefined)
    {
      alert(JSON.stringify(error));
      return null;
    }
    if (account == null)
      return null;

    return account;

  }

  async searchNameState(name, state)
  {
    //.textSearch('account_name', name)
    let { data: account, error } = await supabase
        .from('account')
        .select('account_id, cci_account_id,account_name,employer_identification_number, phone, address1, city, state, created_date')
        .like('account_name', '%' + name.toUpperCase() + '%') 
        .eq('is_deleted', 0)
        .eq('state', state)
        .order('is_primary', { ascending: false})                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
        ;
    
    if (error != undefined)
    {
      alert(JSON.stringify(error));
      return null;
    }
    if (account == null)
      return null;

    return account;

  }

  async searchCity(city)
  {
    let { data: account, error } = await supabase
        .from('account')
        .select('account_id, cci_account_id,account_name,employer_identification_number, phone, address1, city, state, created_date')
        .or('city.fts.' + city, 'address2.fts.' + city)
        .eq('is_deleted', 0)
        .order('is_primary', { ascending: false})                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
        ;

    if (error != undefined)
    {
      alert(JSON.stringify(error));
      return null;
    }
    if (account == null)
      return null;

    return account;

  }


  async searchNameCity(name, city)
  {
    let { data: account, error } = await supabase
        .from('account')
        .select('account_id, cci_account_id,account_name,employer_identification_number, phone, address1, city, state, created_date')
        .textSearch('account_name', name)
        .eq('is_deleted', 0)
        .or('city.fts.' + city, 'address2.fts.' + city) //.textSearch('city', city)
        .order('is_primary', { ascending: false})                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
        ;
    
    if (error != undefined)
    {
      alert(JSON.stringify(error));
      return null;
    }
    if (account == null)
      return null;

    return account;

  }

  async getCustomerRecord(cci_id)
  {
    let { data: account, error } = await supabase
        .from('account')
        .select('cci_account_id,account_name,employer_identification_number, phone, address1, address2, city, state, zip')
        .eq('cci_account_id', cci_id)   
        .eq('is_deleted', 0)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
        ;
    
    //alert(JSON.stringify(account));
    AppDispatcher.dispatch({
      actionTypes: Constants.CUSTOMER_RECORD,
      results: account
    });

    if (error != undefined)
      return false;

    return true;
  }

  async getCustomerRecordByInternalId(internalId)
  {
    let { data: account, error } = await supabase
        .from('account')
        .select('account_id, cci_account_id,account_name,employer_identification_number, phone, address1, address2, city, state, zip, created_date')
        .eq('account_id', internalId)    
        .eq('is_deleted', 0)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
        ;
    
    
    AppDispatcher.dispatch({
      actionTypes: Constants.CUSTOMER_RECORD,
      results: account
    });

    if (error != undefined)
      return false;

    return true;
  }

  async getCustomerRecordById(internalId)
  {
    let { data: account, error } = await supabase
        .from('account')
        .select('account_id, cci_account_id,account_name,employer_identification_number, phone, address1, address2, city, state, zip, created_date')
        .eq('account_id', internalId)    
        .eq('is_deleted', 0)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
        ;
    
    if (error != undefined)
      return null;

    return account;
  }

  async updateAccount(accountData)
  {

  
    const { error } = await supabase
        .from('account')
        .update({account_name: accountData.account_name, employer_identification_number: accountData.employer_identification_number, phone: accountData.phone, address1: accountData.address1, address2: accountData.address2, state: accountData.state, zip: accountData.zip})
        .eq('account_id', accountData.account_id)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
        ;
    
    if (error != undefined)
    {
      alert(JSON.stringify(error));
      return null;
    }

    return null;
  }

  async getAccountContact(cci_id)
  {
    let { data: accountContact, error } = await supabase
        .from('account_contact')
        .select('account_contact_id, account_id, first_name, last_name, phone, email, contact_type, created_date')
        .eq('cci_account_id', cci_id)  
        .order('account_contact_id')                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
        ;
    
    if (error != undefined)
    {
      alert(JSON.stringify(error));
      return null;
    }

    return accountContact;

  }

  async addAccountContact(contactData)
  {
    //alert(JSON.stringify(contactData))
    let { error } = await supabase
        .from('account_contact')
        .insert({account_id: contactData.accountId, cci_account_id: contactData.cciAccountId, member_id: contactData.memberId, first_name: contactData.firstName, last_name: contactData.lastName, phone: contactData.phone, email: contactData.email, contact_type: contactData.contactType})                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
        ;
    
    if (error != undefined)
    {
      alert(JSON.stringify(error));
      return null;
    }

    return null;

  }

  async updateAccountContact(contactData)
  {

    const { error } = await supabase
        .from('account_contact')
        .update({first_name: contactData.firstName, last_name: contactData.lastName, phone: contactData.phone, email: contactData.email, contact_type: contactData.contactType})
        .eq('account_contact_id', contactData.accountContactId)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
        ;
    
    if (error != undefined)
    {
      alert(JSON.stringify(error));
      return null;
    }

    return null;
  }

}


export default new SearchService()
