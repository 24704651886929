import React, { useContext } from "react";
import {Row, Col, Button} from "shards-react";
//import { useViewContext } from "../ViewTradeReport";
import searchStore from "../../../flux/stores/searchStore";
import getViewContext from "../../Context/ViewContext";

export default function ResetSearch() {

    const [view, setView] = useContext(getViewContext());

    function resetSearch()
    {
        searchStore.resetSearchResults();
        searchStore.resetResults();
        setView({view: "Search"});

    }

    function backToResults()
    {
        searchStore.resetSearchResults();
        //setView({view: "Search"});
        setView( prevState =>
            (
              {
                ...prevState,
                view: prevState.ReturnReport
              }
            ) 
          );
    }

    if(view.view != "Search" || searchStore.getCustomerRecord() != undefined)
    {
        return (
            <div class="d-flex justify-content-center gap-1">
    
                    <Button size="sm" theme="info" className="mb-2 mr-1" onClick={() => backToResults()}>Back to Results</Button>   
                    <Button size="sm" theme="info" className="mb-2 mr-1" onClick={() => resetSearch()}>Reset</Button>
              
            </div>
        );
    }

    return (
        <div className="d-flex justify-content-center gap-1">

                <Button size="sm" theme="info" className="mb-2 mr-1" onClick={() => resetSearch()}>Reset</Button>
          
        </div>
    );
}