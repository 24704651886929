import React from "react";
//import addons from "react-addons/index";
import { Row, Col, Container, Card, CardHeader, CardBody } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { ProtectedRoute } from "../../ProtectedRoute";
import { Link } from "react-router-dom";


//var classSet = React.classSet;


export default function ViewChangeRequest(props) {
    
    
      
      return (
      
      <ProtectedRoute>
      <Container className="main-content-container px-5 pb-3">
      <Row noGutters  className="page-header py-4">
            <PageTitle
                sm="4"
                title="Change Requests"
                className="text-sm-left"
            />
        </Row>
        <Row className="px-0 " > 
            <Col lg="3" className="mb-4 d-flex align-items-stretch">
                <Card small className="mb-4">
                  <Link to="/ViewCustomerChangeRequest">
                    <CardHeader className="border-bottom">
                    <h6 className="m-0">Customer Change Request</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="blog-comments_content">
                        <p className="m-2 my-1 mb-2 text-muted">
                            Request a change for a CCI Account including phone, address, and Legal Name. You can also request the account be merged or split from the account. If it is something else you can also request any other change to the account.
                        </p>
                        </div>
                  </CardBody>
                  </Link>
                </Card> 
                   
            </Col>
            <Col lg="3" className="mb-4 d-flex align-items-stretch">
                <Card small className="mb-4">
                  <Link to="/WebsiteChangeRequest">
                    <CardHeader className="border-bottom">
                    <h6 className="m-0">Website Enhancement/Change </h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="blog-comments__content">
                        <p className="h-25 m-2 my-1 mb-2 text-muted">
                            Request a change or enhancement to the website or reporting to be discussed at the next Round Table meeting.
                        </p>
                        </div>
                    </CardBody>
                    </Link>
                </Card>
            </Col>
            <Col lg="3" className="mb-4">
            </Col>
        </Row>
      </Container>
      </ProtectedRoute>
    );
    
    }
/*
export function useViewContext()
{
  return viewContext;
}*/
