import React,{ useState, useEffect} from "react";
import { Row,  Card, Col, CardBody, CardHeader} from "shards-react";
import ClearingNamesService from "../../../flux/services/ClearingNamesService";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../../../utils/tableCustomStyles";
 
export default function ClearingReport (props)
{

  
  const profile = props.profile;
  let [refresh, setRefresh] = useState({ refresh: props.refresh})
  const [state, setState]= useState({
    memberId: props.memberId,
    view: props.view,
    profile: props.profile
  });

  const [data, setData] = useState(null);
  const [specialNamesData, setSpecialNamesData] = useState(null)
  useEffect(() => {
    async function getClearingNames(memId, profile){

      try {

        let data = null;
        // set loading to true before calling API
        if (profile === 'admin')
          data = await ClearingNamesService.getClearingNamesAdmin();
        else
          data = await ClearingNamesService.getClearingNames(memId);

        setData(data);

      } catch (error) {
        console.log(error);
      }
      try {
        let data1 = null;
        // set loading to true before calling API
        if (profile === 'admin')
          data1 = await ClearingNamesService.getSpecialClearingNamesAdmin();
        else
          data1 = await ClearingNamesService.getSpecialClearingNames(memId);

        setSpecialNamesData(data1);

      } catch (error) {
        console.log(error);
      }
    }
    
    getClearingNames(state.memberId, profile);

  }, [refresh, props.refresh]);


  //Remove the Clearing Name
  async function DeleteClearingName(clearingNameId)
  {
    await ClearingNamesService.deleteClearingNames(clearingNameId);
   /* try {
      // set loading to true before calling API
      const data = await ClearingNamesService.getClearingNames(state.memberId);
      setData(data);

    } catch (error) {
      // add error handling here
      console.log(error);
    }
*/
    setRefresh( {refresh: !refresh.refresh});
  }

 //add a delete button for the member to delete its own meeting comment
 const DeleteButton = (props) =>
 {
   //alert(JSON.stringify(props))
   if(props.member_id == props.loggedin_Member_id || props.profile == 'admin')
     return(
       <div className="py-1">
        <button type="button" style={ { height: 28, width: 50, padding: 5} } class="btn btn-secondary btn-sm" onClick={() => DeleteClearingName(props.clearing_name_id)}>Delete</button>

       </div>
     )

   return(<div></div>)
 }

  const columns = [
    {
      name: 'Member',
      selector: data => profile=="alacarte"?"":data.member_id.toString().substring(2,5),
      sortable: true,
      width: '8%'
    },
    {
      name: (<div class="w-100 text-end">Date Entered</div>),
      selector: data => data.date_entered,
      cell: data => data.date_entered.toString().substring(5,7) + '/' + data.date_entered.toString().substring(8,10) + '/' + data.date_entered.toString().substring(0,4),
      sortable: true,
      width: '10%'
    },
    {
      name: 'CCI Number',
      sortable: true,
      selector: data => data.cci_account_id,
      width: '10%'
    },
    {
      name: 'Name',
      selector: data => data.account_name,
      sortable: true,
      width: '18%'
    },
    {
      name: 'Phone',
      selector: data => data.phone,
      cell: data => data.phone,
      sortable: true,
      width: '10%'
    },
    {
      
      name: 'Clearing Name',
      selector: data => data.clearing_name,
      cell: data => data.clearing_name ,
      sortable: true,
      width: '15%'
    },
    {
      name: 'Clearing Reason',
      selector: data => data.attribute_value + ' - ' + data.attribute_description,
      cell: data => data.attribute_value + ' - ' + data.attribute_description ,
      sortable: true,
      width: '20%'
    },
    {
      name: state.view=='Report'?'':'Delete',
      cell: data => state.view=='Report'?null:<DeleteButton member_id={data.member_id} profile={state.profile} loggedin_Member_id={state.memberId}  clearing_name_id={data.clearing_name_id}></DeleteButton>,
      width: '10%'
    }
  ]

  const NamesDataTable = (props) =>
  {
  
    let clearingData = null;
    if (props.view === "special")
      clearingData = specialNamesData;
    else
      clearingData = data;

    if (!clearingData) return (
      <span>Data not available</span>
    );

    return (
        <Row className="px-5">
            <DataTable customStyles={tableCustomStyles} columns={columns}
              data={clearingData}
              ></DataTable>
        </Row>)
  }



  return (
    
    <Card small className="mb-3">
        <CardHeader className="border-bottom">
          <h5>
            Clearing Names
          </h5>
        </CardHeader>
        <CardBody>
        
        <Col>
          <CardHeader className="border-bottom mb-2">
          <div class="text-center fs-6 fw-bold">Special Names</div>
          </CardHeader>
          <NamesDataTable view="special" data={props}></NamesDataTable>
          <CardHeader className="border-bottom mb-2">
          <div class="text-center fs-6 fw-bold">Clearing Names</div>
          </CardHeader>
          <NamesDataTable data={props}></NamesDataTable>
          </Col>
        </CardBody>
    </Card>
  )
}

