import React, { useState, createContext } from "react";
//import addons from "react-addons/index";
import { Row, Col, Container, Card, CardHeader, CardBody } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { ProtectedRoute } from "../../ProtectedRoute";
import getViewContext from "../Context/ViewContext";
import { Link } from "react-router-dom";

//var classSet = React.classSet;

const viewContext = getViewContext();

export default function ViewAccountManagement(props) {
    
    
      
      return (
      
      <ProtectedRoute>
      <Container className="main-content-container px-5 pb-3">
      <Row noGutters  className="page-header py-4">
            <PageTitle
                sm="4"
                title="Account Management"
                className="text-sm-left"
            />
        </Row>
        <Row className="px-0" > 
            <Col lg="3" className="mb-4">
                <Card small className="h-50 mb-4">
                  <Link to="/Members">
                    <CardHeader className="border-bottom">
                    <h6 className="m-0">Manage Members</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="blog-comments__content">
                        <p className="m-2 my-1 mb-2 text-muted">
                            View existing members and add new members to the group.
                        </p>
                        </div>
                  </CardBody>
                  </Link>
                </Card> 
                <Card small className="h-50 mb-4">
                  <Link to="/Users">
                    <CardHeader className="border-bottom">
                    <h6 className="m-0">Manage Users</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="blog-comments__content">
                        <p className="h-25 m-2 my-1 mb-2 text-muted">
                            View all existing users and add new ones to the system.
                        </p>
                        </div>
                    </CardBody>
                    </Link>
                </Card>   
            </Col>
            <Col lg="3" className="mb-4">
                <Card small className="h-50 mb-4">
                  <Link to="/ManageCompanies">
                    <CardHeader className="border-bottom">
                    <h6 className="m-0">Manage Companies</h6>
                    </CardHeader>
                    <CardBody className="p-0">
                        <div className="blog-comments__content">
                        <p className=" m-2 my-1 mb-2 text-muted">
                            Search for companies, assign as root companies, and merge/unmerge.
                        </p>
                        </div>
                  
                    </CardBody>
                    </Link>
                </Card> 
            </Col>
            <Col lg="3" className="mb-4">
            </Col>
        </Row>
      </Container>
      </ProtectedRoute>
    );
    
    }
/*
export function useViewContext()
{
  return viewContext;
}*/
