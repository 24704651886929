import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card, CardHeader, CardBody } from "shards-react";
import DashboardService from "../../../flux/services/DashboardService";
import Chart from "../../../utils/chart";

function DashboardPieGraph(props)
{
  let label = null
  let dataSet = null
  const [data, setData] = useState(null);
  //alert(props.memberId)

  useEffect(() => {
    async function getDashData(memberId) {
      try {
        // set loading to true before calling API
        const data = await DashboardService.getBalancePercent(memberId);
        setData(data);
        // alert(JSON.stringify(data));
      } catch (error) {
        // add error handling here
        console.log(error);
      }
    }
    getDashData(props.memberId);
  }, []);


  class Graph extends React.Component {
    constructor(props) {
      super(props);
      this.GraphValues = React.createRef();

    }
    componentDidMount() {
      let newLabel = [];
      let newDataSet = [0,0,0,0,0];
      function CustConversion(item) {

        if (item["member_id"] == props.memberId) {
          newDataSet[0] = item["pct_current"];
          newDataSet[1] = item["one_day_past_due_pct"];
          newDataSet[2] = item["thirty_day_past_due_pct"];
          newDataSet[3] = item["sixty_day_past_due_pct"];
          newDataSet[4] = item["ninety_day_past_due_pct"];
        }
      }

      data.forEach(CustConversion)
      label = ["percent current", "one day past due", "thirty days past due", "sixty days past due","ninety days past due"];
      dataSet = newDataSet;

      const Colors = ["rgba(84, 141, 255,1.0)", "red", "green", "yellow","orange"];
      const xValues = label;
      var yValues;

      yValues =
        ([{
          data: dataSet,
          backgroundColor: Colors,
        }]);


      const chartConfig = {
        type: "pie",
        data: {
          labels: xValues,
          datasets: yValues
        },
        options: {
          responsive: true,
          legend: { display: true },
          title: {
            display: true,
            text: "Customer Account Activity"
          }
        }


      };
      new Chart(this.GraphValues.current, chartConfig);
    }
    render() {
      return (
        <canvas height="350" ref={this.GraphValues} />
      );
    }
  }

  if (data == null)
  {
    return (
      <span>Awaiting Vaild Data</span>
    )
  }
  else if (data.length == 0) {
    return (
      <span>Awaiting Vaild Data</span>
    )
  }

  return(
    <Card>
        <Graph></Graph>
    </Card>
  ); 
}
export default (DashboardPieGraph)
