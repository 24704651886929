import React,{ useState, useEffect} from "react";
import { Container, CardHeader, Row, Button, Modal, ModalBody, ModalHeader, Card, CardBody, Col, ListGroup, ListGroupItem, Form, FormGroup, FormCheckbox,FormSelect} from "shards-react";
import ClearingNamesService from "../../../flux/services/ClearingNamesService";
import ClearingReport from "./ClearingReport";
import SearchService from "../../../flux/services/SearchService";
 
export default function ClearingNames (props)
{
  let acctId = props.accountId;
  const [state, setState]= useState({
    insertComplete: false,
    //open: false,
    clearingReason: null,
    clearingName: null,
    specialName: null,
    memberId: props.memberId,
    accountId: acctId,
    cciAccountId: props.cciAccountId,
    specialNameChecked: false,
    clearing_reason_id: null,
    profile: props.profile
  });
  let [refresh, setRefresh] = useState ({refresh: true})
  let [toggle, setToggle] = useState ({open: false})
  const [data, setData] = useState(null);
  const [clearingReasons, setClearingReasons] = useState(null);
  const [contactNames, setContactNames] = useState(null);

  useEffect(() => {
    /*async function getClearingNames(memId){
      try {
        // set loading to true before calling API
        let data = await ClearingNamesService.getClearingNames(memId);
        setData(data);

      } catch (error) {
        console.log(error);
      }
    }*/

    async function getClearingReasons()
    {
      try {
        // set loading to true before calling API
        const data = await ClearingNamesService.getClearingReasonCodes();
        setClearingReasons(data);

      } catch (error) {
        console.log(error);
      }
    }

    async function getAccountContact(acctId){
      try {
        // set loading to true before calling API
        const data = await SearchService.getAccountContact(acctId);
        setContactNames(data);
       // alert(JSON.stringify(data));
      } catch (error) {
        // add error handling here
        console.log(error);
      }

    }

    //getClearingNames(state.memberId);
    getClearingReasons();
    getAccountContact(state.cciAccountId);

  }, []);


  async function addMessage(e) {
      //alert(JSON.stringify(state));
      if (state.clearingName === null)
      {
        alert ("Please select a contact for the clearing name or hit cancel and add a contact first.")
        return;
      }
      try {
        let specialName = state.specialNameChecked?1:0
        await ClearingNamesService.addClearingNames(  state.cciAccountId, state.memberId, state.clearingReason, state.clearingName, specialName);

      } catch (error) {
        // add error handling here
        console.log(error);
      }

      //reload the meeting data
      try {
        // set loading to true before calling API
        const data = await ClearingNamesService.getClearingReasonCodes();
        setClearingReasons(data);

      } catch (error) {
        console.log(error);
      }

      changeToggle();

  }



  function changeToggle(e) {
    //alert("toggle")
    setToggle( {
      open: !toggle.open
      }
      
    );

    setRefresh (
      { refresh: !refresh.refresh}
    );
    
  }

  function handleChange(event) {
    let newValue = event.target.value;

    // handle the form values and set the state
    switch(event.target.id)
    {
    case "#ClearingReason":
      setState( prevState =>
        (
          {
            ...prevState,
            clearingReason: newValue
          }
        )
      );
          
      break;
      case '#SpecialName':
        setState(prevState =>
          (
            {
              ...prevState,
              specialName: newValue,
              specialNameChecked: !state.specialNameChecked
            }
          )
  
        );
        break;
        case '#ContactName':
          setState(prevState =>
            (
              {
                ...prevState,
                clearingName: newValue
              }
            )
    
          );
          break;
    default:
      break;
    }
  }
  const ContactOption =  (props) => {
    const {name} = props;

        if (state.clearingName == name)
            return ( <option selected value={name}>{name}</option>)
          
        return ( <option value={name}>{name}</option>)
  }

  const ContactNamesOptions = (props) => {
    if (!contactNames)
      return (<span></span>)

      return (
            
        <FormSelect id="#ContactName" onChange={handleChange.bind(this)} >
         <option>--Select--</option>
      {contactNames.map((contactName) => (
         
         <ContactOption name={contactName.first_name + ' ' + contactName.last_name} />
           
       ))}
       </FormSelect> 
       )
  }
  

  const Option = (props) => {
    const {attribute_id, attribute_value, attribute_description} = props;
    if (attribute_id == state.clearingReason)
    {
      return ( <option selected value={attribute_id}>
        {attribute_value + ' - ' + attribute_description}
      </option>
      )
    }
    return ( <option  value={attribute_id}>
        {attribute_value + ' - ' + attribute_description}
    </option>
    )
  }

  const Options = (props) => {
    
    if (!clearingReasons) return (
      <span></span>
    );
    //alert(JSON.stringify(meetingCodes));
    return (
            
             <FormSelect id="#ClearingReason" onChange={handleChange.bind(this)} >
              <option>--Select--</option>
           {clearingReasons.map((clearingReasons) => (
                <Option attribute_id={clearingReasons.attribute_id}
                      attribute_value={clearingReasons.attribute_value} 
                      attribute_description={clearingReasons.attribute_description} />
            ))}
            </FormSelect> 
            )

  }

  return (
    
    <Container>
      
      <CardHeader className="border-bottom">
        <h5>
          Clearing Names
        </h5>
      </CardHeader>
      <ClearingReport profile={state.profile} refresh={refresh.refresh} memberId={state.memberId}/>
      <Row>
        <div className="d-flex justify-content-center bd-highlight">
          <Button size="sm" className="mb-2 mr-1" onClick={changeToggle.bind(this)}>Add Clearing Name</Button>
          <Modal open={toggle.open}  centered={true}>
          <ModalHeader>Enter Clearing Name</ModalHeader>
          <ModalBody>
            <Card small className="mb-4">                     
              <CardBody className="p-1">
                  <Row noGutters className="page-header px-0 py-4">
                      <Row>
                          
                        <Col>
                            <ListGroup flush>
                            <ListGroupItem>
                            <Row>
                              <Col>
                                  <Form role="form">
                                      <FormGroup>
                                      <label htmlFor="#ClearingReasons">Clearing Reason</label>
                                        <Options id="#ClearingReasons" />
                                      <label htmlFor="#ContactName">Contact Name</label>
                                        <ContactNamesOptions id="#ContactName" /> 
                                       {/* <FormCheckbox id="#SpecialName" checked={state.specialNameChecked} onChange={handleChange.bind(this)}>&nbsp;Is Special Name</FormCheckbox>*/}
                                        
                                      </FormGroup>
                                      
                                  
                                  <div class="d-flex justify-content-center">
                                        
                                          <Button type="button" size="sm" theme="primary" className="mb-2 mr-1" onClick={addMessage.bind(this)}  >Save</Button>
                                          <label>&nbsp;&nbsp;</label>
                                          <Button size="sm" theme="secondary" className="mb-2 mr-1" onClick={changeToggle}>Cancel</Button>
                                        
                                  </div>
                                  </Form>
                              </Col>
                            </Row>
                            </ListGroupItem>
                            </ListGroup>
                        </Col>
                      </Row>
                  </Row>
                </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        </div>
      </Row>
      
    </Container>
  )
}

