import React, { useState, useEffect} from "react";
import { Row, Col, Container, Card, CardHeader, CardBody} from "shards-react";
import ScoreService from "../../../flux/services/ScoreService";
import loginStore from "../../../flux/stores/loginStore";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../../../utils/tableCustomStyles";
import SearchService from "../../../flux/services/SearchService";
import { Redirect } from "react-router-dom";

// test data for the table
let TestData = 0; 

export default function AccountsUnder75()
{

    const memberId = loginStore.getMemberId();
    const [state, setState]= useState({
      });
    const [data, setData] = useState(null);
    useEffect(() => {
      async function getAccountsUnder75(memberId)
      {
        try {
          // set loading to true before calling API
          const data = await ScoreService.getAccountsBelow75(memberId);
          setData(data);
  
        } catch (error) {
          console.log(error);
        }
      }
      getAccountsUnder75(memberId);
    }, []);
    
    const columns = [
      {
        name: (<div class="w-100 text-end">CCI Id</div>),
        selector: data => data.account_id,
        cell: data => (<div class="w-100 text-end">{data.account_id}</div>),
        sortable: true,
        width: '10%'
      },
      {
        name: 'Company Name',
        sortable: true,
        selector: data => data.account_name,
        width: '35%'
      },
      {
        name: 'Current Score',
        selector: data => data.current_month_amount,
        sortable: true,
        width: '15%'
      },
      {
        name: 'Last Month Score',
        selector: data => data.last_month_amount,
        cell: data => data.last_month_amount,
        sortable: true,
        width: '15%'
      },
      {
        id: data => data.date_entered,
        name: 'City',
        selector: data => data.city,
        cell: data => data.city,
        sortable: true,
        width: '15%'
      },
      {
        name: 'State',
        selector: data => data.state,
        cell: data => data.state,
        sortable: true,
        width: '10%'
      }
    ]

    async function onRowClickHandler(event) {
      //alert(JSON.stringify(event))
       let _response = await SearchService.getCustomerRecord(event.account_id);
     
       //alert(_response)
       if (_response == false)
         alert("Error returning Customer Record for CCI ID: " + event.account_id)
       else
       {
         setState( prevState =>
           (
             {
               ...prevState,
               view: "TradeReport",
             }
           )
         );
         
       }
         
     }

    const AccountsDataTable = (props) =>
    {
      if (!data) return (
        null
      );

      return (
        <Row className="py-2 px-4">
            <div className="d-flex flex-row-reverse bd-highlight"> 
                  {//<Button size="sm" theme="info" className="mb-2 mr-1" onClick={ModalState}>Print</Button> 
                  }
          </div>
        <Card className="px-6">
            <CardHeader className="text-sm-center" id="AccountsUnder">
                <div class="text-center fs-5">Round Table Floor Covering Credit Group</div>
                <div class="text-center fs-6">Accounts Under 75</div>
                <div class="text-center fs-6">All Customers Whose Accounts Fell Under 75</div>
            </CardHeader>
            <CardBody>
        
        <DataTable customStyles={tableCustomStyles} columns={columns}
          onRowClicked={onRowClickHandler}
          highlightOnHover={true}
          data={data}
          ></DataTable>
          </CardBody>
          </Card>
          </Row>
      )
    }
    const Rows = (props) => {
      const {account_id,current_month_amount, last_month_amount,  account_name, city, state} = props
     // let dateEntered = date_entered.toString().substring(5,7) + '/' + date_entered.toString().substring(8,10) + '/' + date_entered.toString().substring(0,4)
      let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0, 
        minimumFractionDigits: 0,
      });
      return ( <tr>
        <td>{account_id}</td>
        <td>{account_name}</td>
        <td>{current_month_amount}</td>
        <td>{last_month_amount}</td>
        <td>{city}</td>
        <td>{state}</td>
      </tr>
      )
    }
  
    const Table = () => {
  
      if (!data) return (
        <span>No data for the largest score change</span>
      );
      return (
        <Row className="px-5">
          <table className="table table-sm">
            <thead className="table-light">
              <tr>
                  
              <th scope="col" >CCI ID</th>
              <th scope="col">Company Name</th>
              <th scope="col">Current Score</th>
              <th scope="col">Last Month Score</th>
              <th scope="col">City</th>
              <th scope="col">State</th>
                 
              </tr>
            </thead>
          <tbody> 
                
          {data.map((data) => (
              <Rows key={data.scores_id} account_id={data.account_id} current_month_amount={data.current_month_amount} last_month_amount={data.last_month_amount} 
                      account_name={data.account_name}
                    city={data.city} state={data.state}/>
          ))}
  
          </tbody>
          </table>
        </Row>)
    }

  if (state.view == "TradeReport")
  return (
    <div>
      <Redirect to="/ViewTradeReport" />
    </div>
  )
  return (
    <Container>
      <Row noGutters className="page-header py-2 px-5">

            <AccountsDataTable></AccountsDataTable>
      </Row>
    </Container>
  );
}
