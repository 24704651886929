import supabase from "../stores/supabase";

class SummaryService {
  
  async getSummary(accountId ) {
  
    let { data: credit_summary, error } = await supabase
        .from('vw_credit_summary')
        .select('credit_summary_id, report_date_id,recent_high_credit,account_balance, current_balance, one_day_past_due_amt, thirty_day_past_due_amt, sixty_day_past_due_amt, ninety_day_past_due_amt, is_current, pct_current, pct_one_day_past_due, pct_thirty_day_past_due, pct_sixty_day_past_due, pct_ninety_day_past_due')
        .eq('cci_account_id', accountId)
        .eq('is_current', true)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
        ;

    if (error !== null)
      return null;
    if (credit_summary === null)
      return null;

      return credit_summary;


  }

  async getTrends(accountId ) {
  
    let { data: credit_trend, error } = await supabase
        .from('vw_credit_summary')
        .select('credit_summary_id, report_date_id,recent_high_credit,account_balance, current_balance, \
        one_day_past_due_amt, thirty_day_past_due_amt, sixty_day_past_due_amt, ninety_day_past_due_amt, is_current \
        , pct_current, pct_one_day_past_due, pct_thirty_day_past_due, pct_sixty_day_past_due, pct_ninety_day_past_due')
        .eq('cci_account_id', accountId)
        .neq('is_current',true)
        .order('report_date_id', { ascending: false })
        .limit(3)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
        ;
 
    if (error !== null)
    {
     //alert(JSON.stringify(error))
      return null;
    }
    if (credit_trend === null)
      return null;

      return credit_trend;


  }
}

export default new SummaryService()
