import supabase from "../stores/supabase";

class ClearingNamesService {
  
  async getClearingNames(memberId ) {
  
    let { data: clearingNames, error } = await supabase
        .from('vw_clearing_names')
        .select('clearing_name_id,date_entered,cci_account_id, member_id, account_name, clearing_name, employer_identification_number, phone, clearing_reason_id, attribute_value, attribute_description,  special_name')
        .eq('member_id', memberId)
        .neq('attribute_value', 'S')
        .order('date_entered',{ ascending: false}); 
 
    if (error !== null)
    {
        alert("error Clearing: " + JSON.stringify(error))
      return null;
    }
    if (clearingNames === null)
      return null;

      return clearingNames;


  }

  async getClearingNamesAdmin() {
  
    let { data: clearingNames, error } = await supabase
        .from('vw_clearing_names')
        .select('clearing_name_id,date_entered,cci_account_id, member_id, account_name, clearing_name, employer_identification_number, phone, clearing_reason_id, attribute_value, attribute_description,  special_name')
        .neq('attribute_value', 'S')
        .order('date_entered',{ ascending: false})
 

    if (error !== null)
    {
        alert("error Clearing: " + JSON.stringify(error))
      return null;
    }
    if (clearingNames === null)
      return null;

      return clearingNames;


  }

  async getSpecialClearingNames(memberId ) {
  
    let { data: clearingNames, error } = await supabase
        .from('vw_clearing_names')
        .select('clearing_name_id,date_entered,cci_account_id, member_id, account_name, clearing_name, employer_identification_number, phone, clearing_reason_id, attribute_value, attribute_description,  special_name')
        .eq('member_id', memberId)
        .eq('attribute_value', 'S')
        .order('date_entered',{ ascending: false}); 
 
    if (error !== null)
    {
        alert("error Clearing: " + JSON.stringify(error))
      return null;
    }
    if (clearingNames === null)
      return null;

      return clearingNames;


  }

  async getSpecialClearingNamesAdmin() {
  
    let { data: clearingNames, error } = await supabase
        .from('vw_clearing_names')
        .select('clearing_name_id,date_entered,cci_account_id, member_id, account_name, clearing_name, employer_identification_number, phone, clearing_reason_id, attribute_value, attribute_description,  special_name')
        .eq('attribute_value', 'S')
        .order('date_entered',{ ascending: false})
 
    if (error !== null)
    {
        alert("error Clearing: " + JSON.stringify(error))
      return null;
    }
    if (clearingNames === null)
      return null;

      return clearingNames;


  }

  async getClearingReasonCodes() 
  {
    let { data: clearingReasonCodes, error } = await supabase
        .from('attribute')
        .select('attribute_id,attribute_value,attribute_description')
        .eq('attribute_type', 'Clearing Reason');
 
    if (error !== null)
      return null;
    if (clearingReasonCodes === null)
      return null;

      return clearingReasonCodes;
  }

  async addClearingNames(cciAccountId, memberId, clearingCodeId,  clearingName, specialName) {
    
    //alert("accountId: " + accountId + "memberId: " + memberId + " specialName: " + specialName + " clearingName: " + clearingName + " clearingCode: " + clearingCodeId)

    let { data, error } = await supabase
    .from('clearing_name')
    .insert([
        { cci_account_id: cciAccountId, clearing_reason_id: clearingCodeId, member_id: memberId, clearing_name: clearingName, special_name: specialName },
    ])
 
    if (error !== null)
    {
        alert("error Clearing: " + JSON.stringify(error));
      return null;
    }
    return data;

  }

  async deleteClearingNames(clearingNameId) {
    
    let { data, error } = await supabase
    .from('clearing_name')
    .delete()
    .eq('clearing_name_id', clearingNameId)
 
    if (error !== null)
    {
      alert("Error Clearing Name: " + JSON.stringify(error));
      return null;
    }
    return data;

  }




}

export default new ClearingNamesService()
