import React from "react";
import { Container, Button, Row, Col, Card, CardHeader } from "shards-react";
import PageTitle from "../components/common/PageTitle";
const Insurance = () => (
  <Container className="main-content-container px-5 pb-3">
    <Col>
      <Row>
        <PageTitle
          sm="4"
          title="Insurance"
          className="text-sm-left"
        />
      </Row>
      <Col>
        <Card small className="px-3 py-3">
          <CardHeader className="border-bottom">
            <h3>
              Credit Insurance
            </h3>
          </CardHeader>
          <h6>
            The firms listed here have represented floorcovering manufacturers in the recent past.
            Their listing here is neither a recommendation nor endorsement of such firms.
            You  are encouraged to visit the web sites of such firms to learn more about their services.
          </h6>
          <CardHeader className="border-bottom">
            <h3>
              About Classified Advertising 
            </h3>
          </CardHeader>
          <h6>
            This page is intended to aid employers and employees in finding jobs in the floorcovering industry.
            All information submitted is held in the strictest confidence.  Classified advertising is FREE.
          </h6>
          <CardHeader className="border-bottom">
            <h3>Credit Insurance Links</h3>
            </CardHeader>
          <div class="d-grid gap-2">
            <a href="http://www.aciins.com/">American Credit Indemnity</a>
            <a href="http://www.aig.com/">American Indemnity Group</a>
            <a href="http://www.cna.com/">CNA Credit Insurance</a>
            <a href="http://www.exportinsurance.com/">Capital Recovery Corp</a>
          </div>
        </Card>
      </Col>
    </Col>
  </Container>
)
export default Insurance
