import React from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "shards-react";
import PageTitle from "../components/common/PageTitle";
const Services = () => (
  <Container fluid className="main-content-container px-5 pb-3">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Services"
              className="text-sm-left"
            />
          </Row>
          <Row className="px-0">
            <Col>
            <Card small className="px-3 py-3">
            <CardBody className="p-0">
            <div className="blog-comments__content">
              <CardHeader className="border-bottom">
                <h5 className="m-0">Trade Credit Reports</h5>
              </CardHeader>
                <p className="m-2 my-1 mb-2 text-muted">Trade Reports contain the following information in a comprehensible and easy-to-read format:

                Current Payment Experience.  A trade line for each contributor shows the date reported, date of last sale, pay history, high credit, account balance and the account balance that is current, and/or past due 1-30, 31-60, 61-90 and 91+ days.

                Current Payment Experience Summary.  The payment experiences of all the contributors are summarized here for an overall picture of the account.

                Payment Trends.  Contains a three month pay history of the account and provides you with a short-term trend snapshot.

                Account Score.  CIS scores all accounts according to its payment history and its account aging.  The account is scored each month a running 12 month score trend is reported.

                In addition each trade report shows which suppliers sell the account on a COD or CBD basis and whether that account has been placed for collection (PFC) or has presented not-sufficient-funds (NSF) checks within the past six months.  If NSF or PFC activity is reported, the NSF or PFC detail is also present in your report.  Click here for sample trade credit report.
                </p>
              
              <CardHeader className="border-bottom">
                <h5 className="m-0">NSF and PFC Reports</h5>
              </CardHeader>
                <p className="m-2 my-1 mb-2 text-muted">Our contributors supply us on a daily basis with the names of their NSF and PFC accounts.  This information not only is cross-referenced on our Trade Reports, but is also presented in list format in our NSF and PFC reports.  These reports can be generated for an individual account or for a specific period:  the past month or the most recent 12 months.  The reports show the account in question and dollar amounts of the NSF check (and the number of times returned) and in the case of the PFC report, the dollar amount placed for collection. Our NSF and PFC information can provide you with a valuable "early warning" of an account in trouble.</p>
              
              <CardHeader className="border-bottom">
                <h5 className="m-0">Information Sources</h5>
                </CardHeader>
                <p className="m-2 my-1 mb-2 text-muted">CIS's credit reports contain information provided exclusively by most of the largest mills and factors in the floor covering business.  Each of these sources is a leader in the floor covering industry.  As a result you are assured of getting the most relevant information from the most accurate sources.  No longer do you have to guess about the source of information you depend upon.</p>
              
              <CardHeader className="border-bottom">
                <h5 className="m-0">Timeliness</h5>
              </CardHeader>
                <p className="m-2 my-1 mb-2 text-muted">CIS's database is updated weekly, insuring that you have the most accurate information you need as quickly as possible.  No longer will you have to depend on stale and unreliable information.</p>
              
              <CardHeader className="border-bottom">
                <h5 className="m-0">Accuracy</h5>
              </CardHeader>
                <p className="m-2 my-1 mb-2 text-muted">CIS continuously monitors and updates its database for accuracy.  Our constant interaction with our information suppliers also insures a high degree of accuracy in the data we supply you.  As accounts are sold, move, change names or ownership, we are there to keep your information current.</p>
              
              <CardHeader className="border-bottom">
                <h5 className="m-0">System Requirements</h5>
                </CardHeader>
                <p className="m-2 my-1 mb-2 text-muted">All you need is a personal computer capable of accessing the internet and a web browser such as Microsoft Internet Explorer or Netscape Navigator.  We will set up your own account with a unique password.  Once on our web site, our intuitive program allows you to pull reports by phone number or account name.  Reports are then printed directly at your desktop.</p>
              
              <CardHeader noGutters className="border-bottom">
              <h5 className="m-0">Benefits To You</h5>
              </CardHeader>
              <p className="m-2 my-1 mb-2 text-muted">CIS continuously monitors and updates its database for accuracy.  Our constant interaction with our information suppliers also insures a high degree of accuracy in the data we supply you.  As accounts are sold, move, change names or ownership, we are there to keep your information current.</p>
            </div>
            </CardBody>
          </Card>
        </Col>
        </Row>
      </Container>

);

export default Services;
