import React from "react";
import { Container, Button, Row, Col, Card, CardHeader} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import img from '../images/content-management/testimage.jpg'
// import testimage from './shards-dashboard-react-master/src/images/content-management/testimage.jpg'

const Home = () => (
  <Container fluid={true} className="main-content-container px-5 pb-3">
    
    <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Home"
          subtitle="Overview"
          className="text-sm-left"
        />
      </Row>
      <Row className="px-0">
        <Col>
          <Card small className="px-3 py-3">
              <CardHeader className="border-bottom">
                <h3>Welcome to Floor Credit!</h3>
                </CardHeader>
                <p> <img src={img} alt="Credit Report Thing" align="left" /> CCI Software/Systems was founded in 1992 to provide large and small business software services in the wake of the personal computer revolution. In 1993 CCI Credit Services, a division of CCI Software, began providing credit reports to the leading manufacturers, factors and distributors in the floor covering industry. Since our inception CCI Reports have become synonymous with excellence and accuracy in credit reporting. 
                  This website is designed to aid credit professionals in the floorcovering industry.We have designed it as a clearing house of pertinent information to aid you in the day-to-day operation of your credit department.Use the links below to take you to various service providers.We welcome your suggestions.
                  Credit reports for as little as $8.95 per report.Update credit files and review new credit applicatons quickly and inexpensively.Credit reports contain data from leading floorcovering manufacturers..</p>
               
                  <h5 className="py-2">About Us</h5>

                  <p>CCI Software/Systems was founded in 1992 to provide large and small business software services in the wake of the personal computer revolution. In 1993 CCI Credit Services, a division of CCI Software, began providing credit reports to the leading manufacturers, factors and distributors in the floor covering industry. Since our inception CCI Reports have become synonymous with excellence and accuracy in credit reporting.

                    The advent of the Internet has provided CCI and its customers new opportunities for information sharing and streamlined efficiency. In that vein, CCI has become a leader in internet-based credit reporting for the floor covering industries.

                    Floorcredit.com was inaugurated by CCI in January 2000 as The web resource for credit professionals and others interested in the Floor Covering industry.It is our sincere hope that you find the contents of this site interesting and helpful.We welcome your comments and suggestions for useful links.Please visit the sponsors to our site.They make it all happen. </p>
          </Card>
        </Col>
      </Row>
  </Container>
);

export default Home;
