import React from "react";
import { Container, Button, Row, Col, Card, CardHeader } from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import CustomerInfoCard from "./CustomerInfoCard";
import BaseLayout from "../../BaseLayout";
import { useState } from "react";

let layout = [
  {id:"01", name:"based floor company",},
  {id:"02", name:"cringe celing company",},
  {id:"04", name:"biased wall company",}];


const Rows = (props) => {
  //alert(JSON.stringify(props))
  const {id,name} = props
  return ( <tr>
    <td>{id}</td>
    <td>{name}</td>

  </tr>
  )
}

const Table = (props) => {
  
  const {data} = props
  //alert(JSON.stringify(props))
  return (<table class="table">
            <thead>
              <tr>
              <th scope="col">Id</th>
              <th scope="col">Name</th>
              </tr>
            </thead>
          <tbody> 
            
            {data.map((data) => (
              <Rows id={data.id} name={data.name} />
            ))}

          </tbody>
          </table>)
}
class TestLayout extends React.Component
{

  constructor(props){
    super()
    this.state = {
      rowItem: layout
    }
  }

render() {
  const {layout: items} = this.state;
  return (
  <Container className="main-content-container px-5 pb-3">
    <Col>
      <Row>
        <PageTitle
          sm="4"
          title="Test Layout"
          className="text-sm-left"
        />
      </Row>
      <Col>
        <Card small className="px-3 py-3">
          <CardHeader className="border-bottom">
            <h3>
              Credit Reports
            </h3>
          </CardHeader>
          <h6>
            This is to check the layouts class visually
          </h6>
          <CardHeader className="border-bottom">
            <h3>
              Table
            </h3>
          </CardHeader>
          <Table data={layout}/>
          <CardHeader className="border-bottom">
            <h3>
              Customer Info Card
            </h3>
          </CardHeader>
            <CustomerInfoCard />
        </Card>
      </Col>
    </Col>
  </Container>
  )
}
}


export default TestLayout
