import React,{ useState, useEffect} from "react";
import {  Button, FormGroup, FormInput, Modal, ModalBody, ModalHeader } from "shards-react";
import Col from "./Col";
import { ProtectedRoute } from "../../../ProtectedRoute";
import { data, statuses } from "./data/data";
import Item from "./Items";
import DropWrapper from "./DropWrapper";
import SearchService from "../../../flux/services/SearchService";
import AdminService from "../../../flux/services/AdminService";
import Account from "./Account.js";
import getToggleContext from "../../Context/ToggleContext.js";

const toggleContext = getToggleContext();
 
export default function ManageCompanies (props)
{
    const [items, setItems] = useState(data);
    const [columns, setColumns] = useState(statuses)
    let [currentStatus, setCurrentStatus] = useState(null);
    const [currentCompanyId, setCurrentCompanyId] = useState( {companyId: '', mergeCompanyId: '', selected:'', refresh:false});
    const [toggle, setToggle] = useState(
        {
          toggle: false
        }
      );

    useEffect(()=>
        {
            if(currentCompanyId.refresh == false)
                return
            else if (currentCompanyId.refresh == true)
                {
                getCompany()
                }

        },[currentCompanyId],[toggle]
    )
    /* when a record is moved from one bucket to another catch the change and make the database updates */
    useEffect(()=>{
        
        if(currentStatus == null)
            return
        
        async function MergeCompany ()
        {
            if (currentStatus.status != currentStatus.newStatus && currentStatus.newStatus == "Merge Company")
                {
                    let company = items.filter((i) => i.id == currentStatus.id)
                    let response = await AdminService.updateParentAccount(company[0].id,currentCompanyId.mergeCompanyId)
                    if (response == 1)
                        {
                            
                            setCurrentCompanyId(prevState => (
                                {
                                    ...prevState,
                                    refresh: true
    
                                    }
                                )
                            )
                            
                            
                        }
                }
            else if (currentStatus.status != currentStatus.newStatus && currentStatus.newStatus == "New Company")
                {
                    let company = items.filter((i) => i.id == currentStatus.id)
                    let newCompany = await AdminService.addCCIAccount(company[0].phone)
                    if  (newCompany != null)
                    {
                        
                       let response =  await AdminService.updateAccountNewCCIAccount(company[0].id, newCompany[0].cci_account_id)
                       
                       if (response == 1)
                        {
                            
                            setCurrentCompanyId(prevState => (
                                {
                                    ...prevState,
                                    mergeCompanyId: newCompany[0].cci_account_id,
                                    refresh: true
    
                                    }
                                )
                            )
                            
                            
                        }
                    }
                }
            else if (currentStatus.status != currentStatus.newStatus && currentStatus.newStatus == "Current Company")
                {
                    let company = items.filter((i) => i.id == currentStatus.id)
                    let response = await AdminService.updateParentAccount(company[0].id,currentCompanyId.companyId)
                    if (response == 1)
                        {
                            
                            setCurrentCompanyId(prevState => (
                                {
                                    ...prevState,
                                    refresh: true
    
                                    }
                                )
                            )
                            
                            
                        }
                }
        }
           
        MergeCompany()
            

    },[currentStatus]
)

    function handleCurrentCompanyChange(event)
        {
            
            let value = event.target.value
            if (event.target.id === "#currentCompany")
            {
                setCurrentCompanyId(prevState =>
                    (
                        {
                            ...prevState,
                            companyId: value,
                            selected: "CurrentCompany"
                        }
                    )
                )
            }
            else
            {
                setCurrentCompanyId(prevState =>
                    (
                        {
                            ...prevState,
                            mergeCompanyId: value,
                            selected: "MergeCompany"
                        }
                    )
                )
            }
            
        }
    async function getCompany(){
        let tmpColumns = columns
        let newItemData = [];
        
        if (currentCompanyId.companyId != '')
            {
                let data = await SearchService.searchCCID(currentCompanyId.companyId)
        
                
                for(let i in data){
                    let newItem = {id: data[i].account_id, 
                                    title: data[i].account_name, 
                                    cci_id: data[i].cci_account_id, 
                                    status: "Current Company", 
                                    content: data[i].address1, 
                                    phone: data[i].phone,
                                    city: data[i].city,
                                    state: data[i].state,
                                    is_primary: data[i].is_primary}
                    newItemData.push(newItem)
                }
                let tmpColumns = columns
                tmpColumns[0].title = "CCI ID: " + currentCompanyId.companyId
            }
        if (currentCompanyId.mergeCompanyId != '')
            {
                let data = await SearchService.searchCCID(currentCompanyId.mergeCompanyId)
                for(let i in data){
                    let newItem = {id: data[i].account_id, 
                                    title: data[i].account_name, 
                                    cci_id: data[i].cci_account_id, 
                                    account_id: data[i].account_id,
                                    status: "Merge Company", 
                                    content: data[i].address1, 
                                    phone: data[i].phone,
                                    city: data[i].city,
                                    state: data[i].state,
                                    is_primary: data[i].is_primary }
                    newItemData.push(newItem)
                }
                
                tmpColumns[1].title = "Merge CCI ID: " + currentCompanyId.mergeCompanyId
                tmpColumns[1].status = "Merge Company"
            } 
        setColumns(tmpColumns)
        setItems(newItemData)
        setCurrentCompanyId(prevState => (
            {
                ...prevState,
                refresh: false
            }
            )
        )



    }

    async function deleteAccount(account_id){
        let result = await AdminService.deleteAccount(account_id)
        getCompany();

    }

    async function editAccount(){
        //let result = await AdminService.deleteAccount(account_id)
        getCompany();
        //setToggle({ toggle: true})

    }

    async function getMergeCompany(){
       //alert("mergeCompany")
        let data = await SearchService.searchCCID(currentCompanyId.mergeCompanyId)
                let newItemData = items.map(obj => ({...obj}));
                for(let i in data){
                    let newItem = {id: data[i].account_id, 
                                    title: data[i].account_name, 
                                    cci_id: data[i].cci_account_id, 
                                    account_id: data[i].account_id,
                                    status: "Merge Company", 
                                    content: data[i].address1, 
                                    phone: data[i].phone,
                                    city: data[i].city,
                                    state: data[i].state,
                                    is_primary: data[i].is_primary }
                    newItemData.push(newItem)
                }
                let tmpColumns = columns
                tmpColumns[1].title = "Merge CCI ID: " + currentCompanyId.mergeCompanyId
                tmpColumns[1].status = "Merge Company"
                setColumns(tmpColumns)
                setItems(newItemData)
    }
    function getCurrentCompany(e) 
        {
           let press = e.target.id
           if (press === "#mergebtn" && currentCompanyId.mergeCompanyId == '')
            {
                alert("Please enter a CCI Number for the Merge Company")
                return
            }
           if (press === "#currentbtn" && currentCompanyId.companyId == '')
            {
                alert("Please enter a CCI Number for the Current Company")
                return
            }
            getCompany()
        }
    
    
    const onDrop = (item, monitor, status) => {
        
        
        setItems((prevState) => {
            
          const newItems = prevState
            .filter((i) => i.id !== item.id)
            .concat({ ...item, status });
          return [...newItems];
        });
        setCurrentStatus({prevStatus: item.status, newStatus: status, id: item.id})
      };
    
      const moveItem = (dragIndex, hoverIndex) => {
        const item = items[dragIndex];
        
        setItems((prevState) => {
          const newItems = prevState.filter((i, idx) => idx !== dragIndex);
          newItems.splice(hoverIndex, 0, item);
          return [...newItems];
        });
      };
    
    const CustomerInput = (props) =>
        {
            if (props.index == 1) {
                
                return (
                    <FormGroup>
                        <div className="d-flex gap-3 px-4">
                    
                    <FormInput id="#currentCompany" placeholder="CCI Number" value={currentCompanyId.companyId} onChange={handleCurrentCompanyChange} onKeyDown={(e) => (e.keyCode === 13 ? getCurrentCompany(e) : null)} autoFocus={currentCompanyId.selected == 'CurrentCompany'?true:false} />
                    <Button id="#currentbtn" type="button" className="btn btn-small btn-secondary" onClick={getCurrentCompany} >Submit</Button>
                    </div>
                    </FormGroup>  
                )
            }

            return (
                <FormGroup>
                <div className="d-flex gap-3 px-4">
                <FormInput id="#mergeCompany" placeholder="Merge Number" value={currentCompanyId.mergeCompanyId} onChange={handleCurrentCompanyChange} onKeyDown={(e) => (e.keyCode === 13 ? getCurrentCompany(e) : null)} autoFocus={currentCompanyId.selected == 'MergeCompany'?true:false}/>
                <Button id="#mergebtn" type="button" className="btn btn-small btn-secondary" onClick={getCurrentCompany}>Submit</Button>
                </div>
                </FormGroup>  
            )
        }

    return (
        <ProtectedRoute>
        <toggleContext.Provider value={[toggle, setToggle]}>
        <div className="main-content-container px-5 pb-3">
        <div className="row py-4">
            <div className="d-flex justify-content-center gap-3 mb-4">
                {columns.map(s => (
                    <div key={s.status} className="col-4" style={{backgroundColor: s.color}}>
                        <div className="row justify-content-center">
                            <h5 className="text-center">{s.status.toUpperCase()}</h5>
                            <CustomerInput index={s.index}/>
                                    
                                
                            <div  className="card mb-1" style={{minHeight: 500, width: "90%"}}>
                                <h6 className="card-header border-bottom text-center" >{s.title}</h6>
                                <DropWrapper onDrop={onDrop} status={s.status}>
                                    <div className="card-body">
                                    <Col>
                                    {items
                                        .filter((i) => i.status === s.status)
                                        .map((i, idx) => 
                                            <Item
                                            key={i.id}
                                            item={i}
                                            index={idx}
                                            moveItem={moveItem}
                                            status={s}
                                            deleteAccount={deleteAccount}
                                            editAccount={editAccount}
                                            />
                                        )}
                                    </Col>
                                    </div>
                                </DropWrapper>
                            </div>
                        </div>
                    </div>
                    ) )
                }
            </div>
        </div>
        </div>
        <Modal open={toggle.toggle}  centered={true}>
        <ModalHeader>Edit Company</ModalHeader>
            <ModalBody>
            <Account editAccount={editAccount}/>
            </ModalBody>
        </Modal>
        </toggleContext.Provider>
        </ProtectedRoute>
    );
}

