import React from "react";
import { Router, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { DndProvider } from "react-dnd";
import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "./App.css"
import { HTML5Backend } from "react-dnd-html5-backend";
import UserHandler from "./components/layout/MainNavbar/NavbarNav/UserInfo/UserHandler";
const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASENAME
}) 

// let UH = new UserHandler();
let router = (
<DndProvider backend={HTML5Backend}>
<Router history={history} basename={process.env.REACT_APP_BASENAME || ""}>
<div className="container-fluid">
  {routes.map((route, index) => {
    return (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        handler={route.handler}
        component={props => {
          return (
            <route.layout {...props}>
              <route.component {...props} />
            </route.layout>
          );
        }}
      />
      
    );
  })}
  <Redirect to="/home" />
</div>
</Router>
</DndProvider>
);

export default () => router;
