import React, { useState } from "react";
//import addons from "react-addons/index";
import { Row, Col, Container, Card } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import CustomerSearch from "./Components/CustomerSearch";
import { ProtectedRoute } from "../../ProtectedRoute";
import CustomerInfoCard from "./Components/CustomerInfoCard";
import searchStore from "../../flux/stores/searchStore";
import ResetSearch from "./Components/ResetSearch";
import getViewContext from "../Context/ViewContext";
import CustomerChangeRequest from "./Components/CustomerChangeRequest";
import loginStore from "../../flux/stores/loginStore";

//var classSet = React.classSet;

const viewContext = getViewContext();

export default function ViewCustomerChangeRequest() {
    
    const [view, setView] = useState({view: "Search"});
    

    function CustomerSearchVisible(){
      //  const { view } = useContext(viewState);


        if(view.view == "Report" || searchStore.getCustomerRecord() != undefined)
        {
          //alert(view.view);
          let customerRecord = searchStore.getCustomerRecord();
          const profile = loginStore.getProfile();
           return ( 
            <Container>
                
                <Row className="px-3">
                <div className="d-flex gap-3 flex-row-reverse bd-highlight"> 
                <ResetSearch></ResetSearch>
                </div>
                </Row>
                <Row>
                <CustomerInfoCard></CustomerInfoCard>
                </Row>
                <Row noGutters>
                    <Card small className="mb-4">
                        <CustomerChangeRequest internalAccountId={customerRecord[0].account_id} profile={profile} accountId={customerRecord[0].cci_account_id}  memberId={loginStore.getMemberId()}></CustomerChangeRequest>
                    </Card>

                </Row>

                
            </Container>
            )
          
           // searchStore.getCustomerRecord();
            //alert(JSON.stringify(searchStore.getCustomerRecord()));
        }
        if(view.view == "Search")
        {
            return ( 

              <Row>
                <Row className="px-3">
                <div className="d-flex gap-3 flex-row-reverse bd-highlight"> 
                <ResetSearch></ResetSearch>
                </div>
                </Row>
                <CustomerSearch></CustomerSearch>
              </Row>

            )
        }

        return (<Row></Row>);
      }

      
      return (
      
      <ProtectedRoute>
      <Container className="main-content-container px-4 pb-3">
        <Row noGutters className="page-header py-4">
      <PageTitle
        sm="4"
        title="Customer Change Request"
        className="text-sm-left"
      />
      </Row>
      <Row noGutters className="px-0">
            <Col>
        
            <viewContext.Provider value={[view, setView]}>
            <CustomerSearchVisible/> 
            </viewContext.Provider>
          
          </Col>
      </Row>
      
        
      
      </Container>
      </ProtectedRoute>
    );
    
    }
/*
export function useViewContext()
{
  return viewContext;
}*/
