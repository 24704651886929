import React from 'react';
import Flux from 'flux';

var Test = 
{
  ADD_TODO: "ADD_TODO",
  TOGGLE_TODO: "TOGGLE_TODO"
}


const routes = React.routes;
// import LogIn from "./LogIn";

let displayName = "Please Log In";
async function postData(url = './LogIn', data = {}) {
  const response = await fetch(url, { method: 'POST' })
    .then(response => response.json)
    .catch((error => { console.error('Error:', error) }));
}
// var MySql = require('mysql');
console.log(postData.response);




export default { displayName }
