import React from "react";
import { Form, FormInput, FormGroup, Button, ListGroup, ListGroupItem, Row, Col, Container, Card, CardHeader, FormSelect } from "shards-react";


const Reports = () =>
(
  <Container classname="px-8">
    <Row noGutters className="page-header py-2">
      <Col>
        <CardHeader className="px-3"> Trade Reports </CardHeader>
        <Card className="px-3">
          <Form>
            <FormGroup className="py-2">
              <strong> Customer Phone </strong>
              <FormInput id="Customer Phone " placeholder="Customer Phone" />
              <strong> CCI ID </strong>
              <FormInput id="CCI ID: " placeholder="CCI ID:" />
              <strong> Customer Name </strong>
              <FormInput id="Customer Name " placeholder="Customer Name" />
              <Col md="4" className="form-group">
                <label htmlFor="feInputState">State</label>
                <FormSelect id="feInputState">
                  <option>Choose...</option>
                  <option>...</option>
                </FormSelect>
              </Col>

              <Button type="submit">Submit</Button>
            </FormGroup>
          </Form>
          </Card>
        </Col>
    </Row>
  </Container>
)

export default Reports;
