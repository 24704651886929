import React,{ useContext} from "react";
import { Container, Button, Card, CardHeader, Row } from "shards-react";
import SearchService from "../../../flux/services/SearchService";
//import { useViewContext } from "../ViewTradeReport";
import getViewContext from "../../Context/ViewContext";
import format from "../../../utils/format";
import searchStore from "../../../flux/stores/searchStore";



 
export default function CustomerSearchResults (props)
{
  const [view, setView] = useContext(getViewContext());
//    alert(JSON.stringify(view));

   /*
  * call the search service to get the Customer Record
  */
    //let searchResults = searchStore.getResults();
    //alert(JSON.stringify(searchResults));
   async function selectCCIID (accountId){
    
    let _response = await SearchService.getCustomerRecordByInternalId(accountId);
  
    if (_response == false)
      alert("Error returning Customer Record for CCI ID: " + accountId)
    else
      setView(prevState =>
        (
          {
            ...prevState,
            view: prevState.ReturnReport
          }
        ) );
  
  }


  const Rows = (props) => {
    // accountId in this instance is the internal account number not CCI account number
    const {accountId, id,ein,name,phone,city,state} = props
    return ( <tr>
      <td>{id}</td>
      <td>{accountId}</td>
      <td>{ein}</td>
      <td>{name}</td>
      <td>{format.formatPhoneNumber(phone)}</td>
      <td>{city}</td>
      <td>{state}</td>
      <td><Button size="sm" theme="info" className="mb-2 mr-1" onClick={() => selectCCIID(accountId)} >View</Button></td>
    </tr>
    )
  }

  const Table = (props) => {
    
    const {data} = props
    //alert(JSON.stringify(data))
    return (<table class="table table-sm">
              <thead>
                <tr>
                
                <th scope="col">CCI Id</th>
                <th scope="col">Record Id</th>
                <th scope="col">EIN</th>
                <th scope="col">Name</th>
                <th scope="col">Phone</th>
                <th scope="col">City</th>
                <th scope="col">State</th>
                <th scope="col"></th>
                </tr>
              </thead>
            <tbody> 
              
              {data.map((data) => (
                <Rows accountId={data.account_id} id={data.cci_account_id} ein={data.employer_identification_number} name={data.account_name}
                      phone={data.phone} city={data.city} state={data.state} />
              ))}

            </tbody>
            </table>)
  }

  //alert(JSON.stringify(props));
  return (
    
    <Card small className="px-3 mb-4">
      
      <CardHeader className="border-bottom">
        <h5>
          Results
        </h5>
      </CardHeader>
      <Table data={props.results}/>
    </Card>

  )
}

