import React from "react";
import { Container, Button, Row, Col, Card, form, Form, FormGroup, FormInput, CardBody, ListGroup, ListGroupItem, CardHeader, FormTextarea } from "shards-react";
import PageTitle from "../components/common/PageTitle";

const AboutUs = () => (
  <Container fluid className="main-content-container px-5 pb-3">
      <Col>
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Contact Us"
              className="text-sm-left"
            />
          </Row>
          <Row className="px-0">
            <Col>
              <Card small className="px-4 py-4">
                <CardHeader className="border-bottom">
                <h4>We are here to help!</h4>
                </CardHeader>
                <CardBody>
                <h6>Customer satisfaction is our top priority please contact us with any questions you may have. We try to provide transparancy to our customers and excellent customer service.
                We are always looking for suggestions to improve our processes and to deliver credit reports fast and accurately. Please provide any feedback.
                Please use the form below to ask any question that you may have or any question regarding our products and services and we will get back to you promptly.</h6>
                </CardBody>
                <CardHeader className="border-bottom">
                <h5>
                  Contact Information
                </h5>
                </CardHeader> 
                <CardBody>
                  <Col lg="5">
                <Form role="form">
                    <FormGroup>
                    <label htmlFor="#Name">Enter Your Name</label>
                      <FormInput id="#Name" placeholder="Name"   /> 
                      <label htmlFor="#Email">Email</label>
                      <FormInput id="#Email" placeholder="Email"   /> 
                      <label htmlFor="#Message">Message</label>
                      <FormTextarea id="#Message" placeholder="Message"   />
                    </FormGroup>
                </Form>
                <div class="d-flex justify-content-center">
                      
                      <Button size="sm" theme="primary" className="mb-2 mr-1">Send</Button>
                      <label>&nbsp;&nbsp;</label>
                    
                     </div> 
                    </Col>
                    
                    
                
                 
                </CardBody>
              </Card>
            </Col>
        </Row>
        </Col>
      
  </Container>
);

export default AboutUs;
