export default function() {
    return [
      {
        title: "Dashboard",
        htmlBefore: '<i class="material-icons">view_module</i>',
        to: "/ViewDashboard",
      },
      {
        title: "Trade Reports",
        htmlBefore: '<i class="material-icons">vertical_split</i>',
        to: "/ViewTradeReport",
      },
      {
        title: "Monthly NSF Reports",
        htmlBefore: '<i class="material-icons">vertical_split</i>',
        to: "/MonthlyNSF",
      },
      {
        title: "Monthly PFC Reports",
        htmlBefore: '<i class="material-icons">vertical_split</i>',
        to: "/MonthlyPFC",
      },
      {
        title: "Account Balance and History",
        htmlBefore: '<i class="material-icons">vertical_split</i>',
        to: "/ViewAccountBalance",
      }
      
      
    ];
  }