import React, { useState } from "react";
//import addons from "react-addons/index";
import { Row, Col, Container, Card } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import CustomerSearch from "./Components/CustomerSearch";
import { ProtectedRoute } from "../../ProtectedRoute";
import CustomerInfoCard from "./Components/CustomerInfoCard";
import searchStore from "../../flux/stores/searchStore";
import ResetSearch from "./Components/ResetSearch";
import getViewContext from "../Context/ViewContext";
import MeetingComments from "./Components/MeetingComments";
import loginStore from "../../flux/stores/loginStore";
import MeetingCommentsReport from "./Components/MeetingCommentsReport";

//var classSet = React.classSet;

const viewContext = getViewContext();

export default function ViewMeetingComments() {
    
    const [view, setView] = useState({view: "Search"});
    let customerRecord = searchStore.getCustomerRecord();
   
    

    function CustomerSearchVisible(){
      //  const { view } = useContext(viewState);

        if(view.view == "Report" || searchStore.getCustomerRecord() != undefined)
        {
          //alert(view.view);
          
           return ( 
            <Container>
            
                <Row className="px-3">
                <div className="d-flex gap-3 flex-row-reverse bd-highlight"> 
                <ResetSearch></ResetSearch>
                </div>
                </Row>
                <Row>
                <CustomerInfoCard></CustomerInfoCard>
                </Row>
                <Row>
                    
                       <MeetingComments accountId={customerRecord[0].cci_account_id} profile={loginStore.getProfile()} memberId={loginStore.getMemberId()}></MeetingComments> 
                    

                </Row>
            </Container>
            )
        }
        if(view.view == "Search")
        {
            return ( 

              <Row className="px-3">
                <Row >
                <div className="d-flex gap-3 flex-row-reverse bd-highlight"> 
                <ResetSearch></ResetSearch>
                </div>
                </Row>
                <Row>
                <CustomerSearch></CustomerSearch>
                <MeetingCommentsReport profile={loginStore.getProfile()} memberId={loginStore.getMemberId()}></MeetingCommentsReport>
                </Row> 
              </Row>

            )
        }

        return (<Row></Row>);
    }

    return (
    
    <ProtectedRoute>
    <Container className="main-content-container px-4 pb-3">
    <Row noGutters className="page-header py-4">
    <PageTitle
    sm="4"
    title="Meeting Comments"
    className="text-sm-left"
    />
    </Row>
    <Row noGutters className="px-0">
        <Col>
    
        <viewContext.Provider value={[view, setView]}>
        <CustomerSearchVisible/> 
        </viewContext.Provider>
        
        </Col>
    </Row>
    
    
    
    </Container>
    </ProtectedRoute>
);

}

