import React,{ useEffect} from "react";
import Auth from "../flux/services/AuthService";
import { Redirect } from "react-router-dom";

export default function LogOut (props)
{
 

    useEffect(() => {
        async function logout()
        {
          try {
            // set loading to true before calling API
            const result = await Auth.logout();
            
    
          } catch (error) {
            console.log(error);
          }
        }
        logout();
    
      }, []);

  return (
    <Redirect to='/login' />
  )

}