import React,{ Fragment, useRef, useState, useContext } from "react";
import { useDrag, useDrop } from 'react-dnd';
import { Button } from "shards-react";
import Window from './Window';
import ITEM_TYPES from "./data/types";
import format from "../../../utils/format";
import AdminService from "../../../flux/services/AdminService";
import getToggleContext from "../../Context/ToggleContext";


const Item = ({key, item, index, moveItem, status, deleteAccount, editAccount }) => {
    //alert(JSON.stringify(item))
    const [toggle, setToggle] = useContext(getToggleContext());
    const ref = useRef(null)
    const [, drop ] = useDrop({
        accept: ITEM_TYPES,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            /*
            if (dragIndex === hoverIndex) {
                //alert("return")
                return;
            }*/
            const hovererdRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hovererdRect.bottom - hovererdRect.top)/2;
            const mousePostion = monitor.getClientOffset();
            const hoverClientY = mousePostion.y - hovererdRect.top;

           /*if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {

                return
            }*/
           /* if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }*/
            moveItem(dragIndex, hoverIndex);
            item.index = hoverIndex;
            
        }
        
    })
    const [{ isDragging }, drag] = useDrag({
        type: ITEM_TYPES,
        item: () => ({type: ITEM_TYPES, ...item, index}),
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    const [show, setShow] = useState(false);

    const onOpen = () => setShow(true);

    const onClose = () => setShow(false)

   drag(drop(ref))
//drag(ref)

    const DeleteButton = (props) =>
        {
            //alert ("account id: " + JSON.stringify(props))
        
            return(
            <div className="d-flex flex-row-reverse bd-highlight">
                <button type="button" class="btn btn-link mb-2 mr-1" onClick={() => setToggle({toggle: true, accountId: props.account_id})} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                </svg> </button>
            <button type="button" class="btn btn-link mb-2 mr-1" onClick={() => DeleteAccount(props.account_id, props.deleteAccount)} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"
                 />
                </svg> </button>
                
            </div>
            )
        } 


    async function DeleteAccount(account_id, deleteAccount){

        deleteAccount(account_id)
        //let result = await AdminService.DeleteMember(member_id)
       // refreshMembers(state.memberGroup)


    }
    return (
        <Fragment >
            <div
                ref={ref}
                style={{ opacity: isDragging ? 0 : 1}}
                className={'card mb-2'}
                onClick={onOpen}

            >
                <div className="card-header border-bottom" style={{backgroundColor: item.is_primary == 1?"#AFF3FF":"#FFFFFF"}}>{item.title + " (" + item.id + ")"}</div>
                <div className="row px-4 py-3 bd-highlight" >
                    <div>{item.content}</div>
                    <div>{item.city + ", " + item.state}</div>
                    <div>{format.formatPhoneNumber(item.phone)}</div>
                </div>
                <div ><DeleteButton deleteAccount={deleteAccount} editAccount={editAccount} account_id={item.id}/></div>
                
            </div>
            <Window 
               item = {item} 
               onClose = {onClose}
               show = {show}
            />
    
        </Fragment>
    )
}
export default Item