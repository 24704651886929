import React from "react";
import { Container, Button } from "shards-react";


const AboutUs = () => (
  <Container fluid className="main-content-container px-4 pb-4">

    <h5>About Us</h5>
    <h6>CCI Software/Systems was founded in 1992 to provide large and small business software services in the wake of the personal computer revolution. In 1993 CCI Credit Services, a division of CCI Software, began providing credit reports to the leading manufacturers, factors and distributors in the floor covering industry. Since our inception CCI Reports have become synonymous with excellence and accuracy in credit reporting.

      The advent of the Internet has provided CCI and its customers new opportunities for information sharing and streamlined efficiency. In that vein, CCI has become a leader in internet-based credit reporting for the floor covering industries.

      Floorcredit.com was inaugurated by CCI in January 2000 as The web resource for credit professionals and others interested in the Floor Covering industry.It is our sincere hope that you find the contents of this site interesting and helpful.We welcome your comments and suggestions for useful links.Please visit the sponsors to our site.They make it all happen. </h6>

  </Container>
);

export default AboutUs;
