import React, { useState, useRef } from "react";
//import addons from "react-addons/index";
import { Row, Col, Container, Card, Button, Modal, ModalBody, ModalHeader } from "shards-react";
import {useReactToPrint} from "react-to-print"
import PageTitle from "../../components/common/PageTitle";
import CustomerSearch from "./Components/CustomerSearch";
import { ProtectedRoute } from "../../ProtectedRoute";
import CustomerInfoCard from "./Components/CustomerInfoCard";
import searchStore from "../../flux/stores/searchStore";
import PaymentExperience from "./Components/PaymentExperience";
import ResetSearch from "./Components/ResetSearch";
import Summary from "./Components/Summary";
import Trends from "./Components/Trends";
import Scores from "./Components/Scores";
import History from "./Components/History";
import getViewContext from "../Context/ViewContext";
import TradeReportOriginal from "./Components/TradeReportOriginal";
import CustomerGraph from "./Components/CustomerGraph";
import AdminService from "../../flux/services/AdminService";
import loginStore from "../../flux/stores/loginStore";
import CustomerPFC from "./Components/CustomerPFC";
import CustomerNSF from "./Components/CustomerNSF";
import { Redirect } from "react-router-dom";

//var classSet = React.classSet;

const viewContext = getViewContext();

let TestLabel = ["0","10","20","30","40","50","60","70","80","90","100"];
let TestDataSet = [[58,37,55,33,22,11,55,12,89,12,44], [42,57,12,77,100,67,99,78,10,3,44] ];


/*function ObjToArray(Obj)
{
  return 0;
}


<CustomerGraph label={ObjToArray(ScoreService.getScores(customerRecord[0].cci_account_id)) }
  dataSet={ObjToArray(ScoreService.getScores(customerRecord[0].cci_account_id.scores_id)) } />
*/

export default function ViewTradeReport(props) {

    
    const [view, setView] = useState({
      view: "Search" ,
      ReturnReport: !props.location.returnValue?"":props.location.returnValue,
      NumMonths: !props.location.months?"":props.location.months
    });
    const [modal, setModal] = useState({
      modal: false
    });
    const componentRef = useRef();
    const originalTradeReportRef = useRef();
    //const getTargetElement = () => document.getElementById("container");
    const handlePrint = useReactToPrint({
       content: () => componentRef.current,
       documentTitle: 'Trade Report',
       
      });
    const handlePrintOriginal = useReactToPrint({
        content: () => originalTradeReportRef.current,
        documentTitle: 'Trade Report',
        
      });

    function ModalState() {
      setModal( prevState =>
        (
          {
            modal: !modal.modal
          }
        )
      );
    }
    

    function CustomerSearchVisible(){
      //  const { view } = useContext(viewState);
        if(view.view == "MonthlyPFC")
        {
          return(
            <div>
              <Redirect to={{
            pathname: '/MonthlyPFC',
            months: view.NumMonths 
            }} />
            </div>
          )
        }
        if (view.view == "MonthlyNSF")
          {
            return(
              <div>
                <Redirect to={{
              pathname: '/MonthlyNSF',
              months: view.NumMonths 
              }} />
              </div>
            )
          }
        if (view.view == "LargestScoreChange")
          {
            return(
              <div>
                <Redirect to={{
              pathname: '/LargestScoreChange'
              }} />
              </div>
            )
          }
        if(view.view == "Report" || searchStore.getCustomerRecord() != undefined)
        {
          let customerRecord = searchStore.getCustomerRecord();
          let memberId = loginStore.getMemberId();
          let profile = loginStore.getProfile();
          let userName = loginStore.getUserName();
          let result = AdminService.setReportAccess(memberId, customerRecord[0].cci_account_id, 63, userName);

           return ( 
            
             <Container>

              
                <Row className="px-3">
                <div className="d-flex gap-3 flex-row-reverse bd-highlight"> 
                <ResetSearch></ResetSearch>
                {/*<Button size="sm" theme="info" className="mb-2 mr-1" onClick={handlePrint}>Print</Button>*/}
                <Button size="sm" theme="info" className="mb-2 mr-1" onClick={ModalState}>Print</Button>
                </div>
                <Modal open={modal.modal} className="modal-lg" >
                    <ModalHeader>Customer Trade Report</ModalHeader>
                    <ModalBody>
                    <div className="d-flex gap-2 flex-row-reverse bd-highlight">
                    <Button size="sm" theme="info" className="mb-2 mr-1" onClick={ModalState}>Close</Button>
                    <Button size="sm" theme="info" className="mb-2 mr-1" onClick={handlePrintOriginal}>Print</Button>
                    </div>
                    <div
                        ref={originalTradeReportRef}
                        className="print-component"
                      >
                    <TradeReportOriginal accountId={customerRecord[0].cci_account_id}></TradeReportOriginal>
                      
                    </div>
                    </ModalBody>
                  </Modal>
                
                </Row>
             
              <div
                ref={componentRef}
                className="print-component"
              >
                <Container fluid >
                
              
               <Row>
                <CustomerInfoCard></CustomerInfoCard>
                </Row>

                

                <Row >
                  <Card small>
                  <PaymentExperience accountId={customerRecord[0].cci_account_id} profile={profile} ></PaymentExperience>
                  <Summary accountId={customerRecord[0].cci_account_id}></Summary>
                  <Trends accountId={customerRecord[0].cci_account_id}></Trends>
                  <Scores accountId={customerRecord[0].cci_account_id}></Scores>
                  <History accountId={customerRecord[0].cci_account_id}></History>
                  <CustomerGraph accountId={customerRecord[0].cci_account_id} />
                  <CustomerPFC accountId={customerRecord[0].cci_account_id} view="Trade Report"></CustomerPFC>
                  <CustomerNSF accountId={customerRecord[0].cci_account_id} view="Trade Report"></CustomerNSF>
                  </Card>
                </Row>
                            
              
              
              </Container>
              </div>
              
              
            </Container>
            
            
            )
          
           // searchStore.getCustomerRecord();
            //alert(JSON.stringify(searchStore.getCustomerRecord()));
        }
        if(view.view == "Search")
        {
            return ( 

              <Container fluid className="px-4 py-0">
                <Col lg="11" >
 
                  <Row className="px-3">
                    <div className="d-flex gap-3 flex-row-reverse bd-highlight"> 
                      <ResetSearch></ResetSearch>
                    </div>
                  </Row>
                  <Row>
                    <CustomerSearch></CustomerSearch>
                  </Row>
                </Col>
              </Container>

            )
        }

        return (<Row></Row>);
      }

      return (
      
      <ProtectedRoute>
      <Container className="main-content-container px-3 pb-3">
        <Row noGutters className="page-header py-4">
      <PageTitle
        sm="4"
        title="Trade Report"
        className="text-sm-left"
      />
      </Row>
      <Row noGutters className="px-0">
            <Col>
            <viewContext.Provider value={[view, setView]}>
            <CustomerSearchVisible/> 
            </viewContext.Provider>
          
          </Col>
      </Row>
      
        
      
      </Container>
      </ProtectedRoute>
    );
    
    }
/*
export function useViewContext()
{
  return viewContext;
}*/
