import supabase from "../stores/supabase";
import supabaseAdmin from "../stores/supabaseAdmin";
import { createClient } from '@supabase/supabase-js'

class AdminService {

  
  async setReportAccess(memberId, accountId, reportTypeId, userName ) {
  
    let { data: credit_info, error } = await supabase.rpc("fninsertreportaccess", { memberid: memberId, accountid: accountId, reporttypeid: reportTypeId, username: userName});
    //alert(JSON.stringify(error));
    if (error !== undefined)
      return null;
    if (credit_info == null)
      return null;

      return credit_info;


  }

  async getReportAccess(memberId, report, timeFrame) {

    let today = new Date();
    today.setMonth(today.getMonth()-timeFrame);
    var fromDate = (today.getFullYear() * 10000) + ((today.getMonth()) * 100) + 1;

    if (report == "All")
        return this.getReportAccessAll(memberId, fromDate);
    
    return this.getReportAccessFilter(memberId, report, fromDate);


  }


  async getReportAccessAll(memberId, fromDate) {


    let { data: reportAccess, error } = await supabase
        .from('vw_report_access')
        .select('report_access_id, member_id, user_name , account_id, access_date, is_billable , attribute_description , attribute_value , account_name' )
        .eq('member_id', memberId)
        .gte('access_date_id', fromDate)
        .order('access_date', { ascending: false});
 
    if (error !== null)
    {
        alert("error admin: " + JSON.stringify(error))
      return null;
    }
    if (reportAccess === null)
      return null;

      return reportAccess;

  }

  // return the report based upon a filtered report
  async getReportAccessFilter(memberId, report, fromDate) {

    let { data: reportAccess, error } = await supabase
        .from('vw_report_access')
        .select('report_access_id, member_id, user_name , account_id, access_date, is_billable , attribute_description , attribute_value , account_name' )
        .eq('member_id', memberId)
        .eq('attribute_description', report)
        .gte('access_date_id', fromDate)
        .order('access_date', { ascending: false});
 
    if (error !== null)
    {
        alert("error admin: " + JSON.stringify(error))
      return null;
    }
    if (reportAccess === null)
      return null;

      return reportAccess;
  }

/********************** BEGIN MEMBERS********************* */
  // return all of the members
  async getMembers(memberGroup) {

    if (memberGroup == "All")
      return this.getAllMembers();

    return this.getMemberByGroup(memberGroup);

  }

  async getAllMembers(){

    let { data: members, error } = await supabase
        .from('vw_members')
        .select('member_id, name, contact_first_name, contact_last_name, email, address, city, state, postal_code, phone_number, member_group_id, member_group, file_format_id, file_format' )
        .order('member_id');
 
    if (error !== null)
    {
        alert("error Admin: " + JSON.stringify(error))
      return null;
    }
    if (members === null)
      return null;

      return members;

  }

  async getMemberByGroup(memberGroup){

    let { data: members, error } = await supabase
        .from('vw_members')
        .select('member_id, name, contact_first_name, contact_last_name, email, address, city, state, postal_code, phone_number, member_group_id, member_group, file_format_id, file_format' )
        .eq('member_group', memberGroup)
        .order('member_id');

    if (error !== null)
    {
        alert("error Admin: " + JSON.stringify(error))
      return null;
    }
    if (members === null)
      return null;

      return members;

  }

  async addMember(memberData){
    //alert(JSON.stringify(memberData));

    let { data, error } = await supabase
    .from('member')
    .insert([
        { member_id: memberData.memberId, name: memberData.name, contact_first_name: memberData.contactFirstName, contact_last_name: memberData.contactLastName, email: memberData.email, address: memberData.address, city: memberData.city, state: memberData.state, postal_code: memberData.zip, phone_number: memberData.phoneNumber, member_group_id: memberData.memberGroupId, file_format_id: memberData.fileFormatId },
    ])
 
    if (error !== null)
    {
      alert(JSON.stringify(error));
      return null;
    }
    if (data === null)
      return null;

    return null;
  }

  async DeleteMember(member_id){
    //alert(JSON.stringify(memberData));

    let { data, error } = await supabase
    .from('member')
    .update([
        { is_deleted: 1 },
    ]).eq('member_id',member_id)
 
    if (error !== null)
    {
      alert(JSON.stringify(error));
      return null;
    }
    if (data === null)
      return null;

    return null;
  }

  /********************** END MEMBERS********************* */

  /*********************BEGIN USERS************************* */

  async getAllUsers(){

    let { data: users, error } = await supabase
        .from('user_info')
        .select('user_info_id, email, user_name, member_id, member(name), profile' );
 
    if (error !== null)
    {
        alert("error Admin: " + JSON.stringify(error))
      return null;
    }
    if (users === null)
      return null;

      return users;

  }

  async addUser(userInfo){

  
    let { data: authuser, error: authError} =  await supabaseAdmin.auth.admin.createUser({
      email: userInfo.email,
      password: userInfo.password,
      email_confirm: true
    })

    if (authError == null)
      {

        let { data: users, error } = await supabase
            .from('user_info')
            .insert([{user_name: userInfo.userName, email: userInfo.email, member_id: userInfo.memberId, profile: userInfo.profile}])
    
            alert(JSON.stringify(users))
        if (error !== null)
        {
            alert("error user: " + JSON.stringify(error))
          return null;
        }
      }


      return null;

  }



  /**********************END USERS **************************** */
  /*******************BEGIN MANAGE COMPANIES ******************** */

  async addCCIAccount(phoneNumber ) {
  
    let { data, error } = await supabase
    .from('cci_account')
    .insert([
        { phone: phoneNumber, account_type_id: 50 },
    ]).select()
 
    if (error !== null)
      return null;
    if (data === null)
      return null;

    return data

  }

  async updateAccountNewCCIAccount (accountId, cciAccountId)
  {

    let {data, error} = await supabase
    .from('account')
    .update({cci_account_id: cciAccountId, is_primary: 1})
    .eq('account_id', accountId)

    if (error !== null)
      {
        alert("Error: " + JSON.stringify(error))
        return null;
      }

    return 1
  }

  async updateParentAccount (accountId, cciAccountId)
  {

    let {data, error} = await supabase
    .from('account')
    .update({cci_account_id: cciAccountId, is_primary: 0})
    .eq('account_id', accountId)

    if (error !== null)
      {
        alert("Error: " + JSON.stringify(error))
        return null;
      }

    return 1
  }

  async deleteAccount( accountId ) {
  
    let { data: account_info, error } = await supabase.rpc("fndeleteaccount", { accountid: accountId});
    //alert(JSON.stringify(error));
    if (error !== undefined)
      return null;
    if (account_info == null)
      return null;

      return account_info;


  }

  /*******************END MANAGE COMPANIES ************************* */

}


export default new AdminService()