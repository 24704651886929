//var BASE_URL = 'http://localhost:3001/';
var BASE_URL = 'https://supabase.floorcredit-dev.com/';
export default {
  CHANGE: "CHANGE",
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  BASE_URL: BASE_URL,
  API_KEY: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE4NDc1MDE1OTgsImlhdCI6MTY4OTcxNzE5OCwiaXNzIjoic3VwYWJhc2UiLCJyb2xlIjoiYW5vbiJ9.D1--6IuyJcMAAGn8E0JsCHHAU301Y3LnAawgT8t_lH8",
  API_ADMIN_KEY: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE4NDc1MDE1OTgsImlhdCI6MTY4OTcxNzE5OCwiaXNzIjoic3VwYWJhc2UiLCJyb2xlIjoic2VydmljZV9yb2xlIn0.74mupENhdz-iB_2_oKRSJi-ZJn_wOWl3tKFDs_hn39Q",
  LOGIN_URL: BASE_URL + 'auth/v1/token?grant_type=password',
  SIGNUP_URL: BASE_URL + 'users',
  LOGIN_USER: 'LOGIN_USER',
  LOGOUT_USER: 'LOGOUT_USER',
  SEARCH_RESULTS: 'SEARCH_RESULTS',
  CUSTOMER_RECORD: 'CUSTOMER_RECORD'
};
