import { Constants } from '../../flux';
import jwt_decode from 'jwt-decode';
import BaseStore from "./baseStore";
import jwtDecode from 'jwt-decode';


class LoginStore extends BaseStore {
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this))
        this._user = null;
        this._userName = null;
        this._jwt = null;
        this._memberId = null;
        this._profile = null;
    }

    _registerToActions(action) {
        switch (action.actionTypes) {
            case Constants.LOGIN_USER:
                this._jwt = action.jwt;
                this._user = jwt_decode(this._jwt);
                this._userName = action.userName;
                this._memberId = action.memberId;
                this._profile = action.profile;
                //alert(" user: " + JSON.stringify(this._user));
                this.emitChange();
                break;
            case Constants.LOGOUT_USER:
                this._user = null;
                this._userName = null;
                this._memberId = null;
                this._profile = null;
                this._jwt = null;
                this.emitChange();
                break;
            default:
                break;
        }
    };

    getUser() {
        this.checkLoggedIn();
        this._user = jwt_decode(this._jwt).email;
        return this._user;
      }
    
    getMemberId() {
        if (this._memberId == null)
            this._memberId = localStorage.getItem('memberId')
        return this._memberId;
    }

    getProfile() {
        if (this._profile == null)
            this._profile = localStorage.getItem('profile')
        return this._profile;
    }

    getUserName() {
        if (this._userName == null)
            this._userName = localStorage.getItem('userName');
        return this._userName
    }

    get jwt() {
    return this._jwt;
    }

    checkLoggedIn() {
        let savedJwt = localStorage.getItem('jwt');
        if (!!savedJwt)
        {
            if(Math.floor(Date.now() / 1000) < jwtDecode(savedJwt).exp)
            {
                this._jwt = savedJwt;
            }
        }
        //alert("jwt: " + JSON.stringify(jwtDecode(savedJwt)));

    }

    isLoggedIn() {
        this.checkLoggedIn();
        //alert(this._jwt);
        return !!this._jwt;
    }

}



export default new LoginStore();
