import request from 'reqwest';
import when from 'when';
import { Constants } from "../../flux";
import LoginActions from '../login/loginActions'
import supabase from '../stores/supabase';


class AuthService {

  
  async login(username, password) {
    
  //var supabase = createClient(Constants.BASE_URL, Constants.API_KEY);
  
  let userInfo = await this.getUserInfo(username);

  const { data, error } = await supabase.auth.signInWithPassword({
    email: userInfo[0].email,
    password: password,
  });
  //alert("error: " + JSON.stringify(error) + " Data: " + JSON.stringify(data.session.access_token))
  if (error != null)
  {
    alert("Invalid username or password");
    return false;
    
  }
  if (data == null)
    return false;

  //alert("data: " + JSON.stringify(data));
  var jwt = data.session.access_token;
  //alert ("isLoggedin: " + loginStore.isLoggedIn());
  LoginActions.loginUser(jwt, userInfo[0]);

  return true;


  }

  async getUserInfo(userName)
  {
    let { data: userinfo, error } = await supabase
        .from('user_info')
        .select('email,user_name, member_id, profile')
        .eq('user_name', userName); 
  
    //alert(JSON.stringify(userinfo));
    if (error != null)
      alert(JSON.stringify(error));
    return userinfo;
  }

  async logout() {
    LoginActions.logoutUser();
    return true;
  }

  signup(username, password, extra) {
    return this.handleAuth(when(request({
      url: Constants.SIGNUP_URL,
      method: 'POST',
      crossOrigin: true,
      type: 'json',
      Header: {
        apiKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE4NDc1MDE1OTgsImlhdCI6MTY4OTcxNzE5OCwiaXNzIjoic3VwYWJhc2UiLCJyb2xlIjoiYW5vbiJ9.D1--6IuyJcMAAGn8E0JsCHHAU301Y3LnAawgT8t_lH8'
      }, 
      data: {
        username, password, extra
      }
    })));
  }


  handleAuth(loginPromise) {


    //alert("error: " + JSON.stringify(loginPromise.error) + " Data: " + JSON.stringify(loginPromise.data))
        var jwt = loginPromise.data.access_token;
        LoginActions.loginUser(jwt);

      return true;
  }
 /* handleAuth(loginPromise) {

    return loginPromise
      .then(function(response) {
        var jwt = response.access_token;
        LoginActions.loginUser(jwt);
        return true;
      });
  }*/
}

export default new AuthService()
