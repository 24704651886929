import React, { useState, useEffect} from "react";
import { Row, Col, Container, Card, CardHeader, CardBody} from "shards-react";
import ScoreService from "../../../flux/services/ScoreService";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../../../utils/tableCustomStyles";
import SearchService from "../../../flux/services/SearchService";
import { Redirect } from "react-router-dom";
export default function LargestScoreChange()
{

    const [data, setData] = useState(null);
    const [state, setState]= useState({
    });
    useEffect(() => {
      async function getLargestScoreChange()
      {
        try {
          // set loading to true before calling API
          const data = await ScoreService.getLargestScoreChange();
          setData(data);
  
        } catch (error) {
          console.log(error);
        }
      }
      getLargestScoreChange();
    }, []);
    
    const columns = [
      {
        name: (<div class="w-100 text-end">CCI Id</div>),
        selector: data => data.account_id,
        cell: data => (<div class="w-100 text-end text-nowrap">{data.account_id}</div>),
        sortable: true,
        width: '10%'
      },
      {
        name: 'Company Name',
        sortable: true,
        selector: data => data.account_name,
        width: '30%'
      },
      {
        name: 'Last Amount',
        selector: data => data.last_amount,
        sortable: true,
        width: '10%'
      },
      {
        name: 'Current Amount',
        selector: data => data.amount,
        sortable: true,
        width: '15%'
      },
      {
        name: 'Score Change',
        selector: data => data.change_amount,
        cell: data => data.change_amount,
        sortable: true,
        width: '10%'
      },
      {
        id: data => data.date_entered,
        name: 'City',
        selector: data => data.city,
        cell: data => data.city,
        sortable: true,
        width: '15%'
      },
      {
        name: 'State',
        selector: data => data.state,
        cell: data => data.state,
        sortable: true,
        width: '10%'
      }
    ]

    async function onRowClickHandler(event) {
      //alert(JSON.stringify(event))
       let _response = await SearchService.getCustomerRecord(event.account_id);
     
       //alert(_response)
       if (_response == false)
         alert("Error returning Customer Record for CCI ID: " + event.account_id)
       else
       {
         setState( prevState =>
           (
             {
               ...prevState,
               view: "TradeReport",
             }
           )
         );
         
       }
         
     }

     const LargestScoreDataTable = (props) =>
    {
      if (!data) return (
        <div></div>
      );

      return (
        <Row className="py-2 px-4">
            <div className="d-flex flex-row-reverse bd-highlight"> 
                  {//<Button size="sm" theme="info" className="mb-2 mr-1" onClick={ModalState}>Print</Button> 
                  }
          </div>
        <Card className="px-6">
            <CardHeader className="text-sm-center" id="AccountsUnder">
                <div class="text-center fs-5">Round Table Floor Covering Credit Group</div>
                <div class="text-center fs-6">Largest Score Change</div>
                <div class="text-center fs-6">All Customers with the Largest Score Change this Month</div>
            </CardHeader>
            <CardBody>
        
        <DataTable customStyles={tableCustomStyles} columns={columns}
          onRowClicked={onRowClickHandler}
          highlightOnHover={true}
          data={data}
          ></DataTable>
          </CardBody>
          </Card>
          </Row>
      )
    }
  

    
  if (state.view == "TradeReport")
  return (
    <div>
      <div>
      <Redirect to={{
            pathname: '/ViewTradeReport',
            returnValue: 'LargestScoreChange'
            }} />
      </div>
    </div>
  )
  return (
    <Container>
      <Row noGutters className="page-header py-2 px-3">

            <LargestScoreDataTable></LargestScoreDataTable>

      </Row>
    </Container>
  );
}
