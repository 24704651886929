import { Constants } from '../../flux';
import jwt_decode from 'jwt-decode';
import BaseStore from "./baseStore";
import jwtDecode from 'jwt-decode';


class SearchStore extends BaseStore {
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this))
        this._results = null;
        this._customer_record = null;
    }

    _registerToActions(action) {
        switch (action.actionTypes) {
            case Constants.SEARCH_RESULTS:
                
                this._results = action.results;
                alert(" results: " + JSON.stringify(this._results));
                localStorage.setItem('searchResults', JSON.stringify(this._results))
                this.emitChange();
                break;
            case Constants.CUSTOMER_RECORD:
                
                this._customer_record = action.results;
                //alert(JSON.stringify(this._customer_record));
                localStorage.setItem('customer_record', JSON.stringify(this._customer_record) )
                this.emitChange();
                break;
            default:
                break;
        }
    };

    getResults() {
        
        return JSON.parse(localStorage.getItem('searchResults'));
      }
    
    setResults(results) {
        this._results = results
        localStorage.setItem('searchResults', JSON.stringify(this._results))
    }

    getCustomerRecord() {      
        this._customer_record = JSON.parse(localStorage.getItem('customer_record'));
        return this._customer_record;
    }

    resetResults(){
        localStorage.removeItem('searchResults');
    }

    resetSearchResults() {
        localStorage.removeItem('customer_record');
    }
    
   

}



export default new SearchStore();
