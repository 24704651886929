import React from "react";
import PageTitle from "../../components/common/PageTitle";
import { Form, FormInput, FormGroup, Button, ListGroup, ListGroupItem, Row, Col, Container, Card, CardHeader, FormSelect } from "shards-react";
import FormThingy from "./Components/FormThingy";

const EnterPFC = () =>
(
  <Container classname="px-8">
    <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Home"
          subtitle="Overview"
          className="text-sm-left"
        />
        <Row noGutters className="page-header py-2">
         <Col>
            <CardHeader className="px-3"> EnterPFC </CardHeader>
            
          </Col>
        </Row>
      </Row>
   
  </Container>
);

export default EnterPFC;
