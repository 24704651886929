import React, {useContext,useState, useEffect, useRef} from "react";
import {Container, Row, Col, Card, CardHeader, CardBody, ListGroup, ListGroupItem, Form, FormGroup, FormInput, Button, FormSelect} from "shards-react";
import searchStore from "../../../flux/stores/searchStore";
import loginStore from "../../../flux/stores/loginStore";
//import { useViewContext } from "../ViewTradeReport";
import format from "../../../utils/format";
import SearchService from "../../../flux/services/SearchService";
import getToggleContext from "../../Context/ToggleContext";





export default function Account (props) {
    let customerRecord = searchStore.getCustomerRecord();
    let editAccount = props.editAccount;
    let user = loginStore.getUser();
    const contacttypeOptions = ["Owner", "President", "Vice President", "Accounts Receivable", "Accounts Payable", "Controller", "Manager", "Associate", "Other"];
    const [toggle, setToggle] = useContext(getToggleContext());
    
    /*let [data, setData] = useState({
      account_id: 0, 
      cci_account_id: 0,
      account_name: '',
      employer_identification_number: 0,
      phone: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      created_date: null
    });*/
    let [data, setData] = useState(null);
    const [state, setState]= useState({ismounted: false}) /*useState({
      firstName: '',
      lastName: '',
      contactType: '',
      phone: '',
      email: '',
      accountContactId: '',
      memberId: '',
      accountId: '',
      cciAccountId: '',
      profileId: loginStore.getProfile()
    });*/

    useEffect(() => {
      async function getAccount(acctId)
      {
        
        try {
  
          // set loading to true before calling API
         const result = await SearchService.getCustomerRecordById(acctId);
          setData( result[0])
          //setData(result);
          
  
  
        } catch (error) {
          console.log(error);
        }
      }
      getAccount(toggle.accountId);

      /*let isMounted = true; 
      SearchService.getCustomerRecordById(toggle.accountId).then( data => { if (isMounted) {setData(data)} })
        alert(JSON.stringify(data))
      return () => { isMounted = false };*/
      
    }, []);
    
    
        
      //optionToSelect.selected = true;


    function handleChange(event) {
      let newValue = event.target.value;
      switch(event.target.id)
      {
      case "#AccountName":
          setData( prevState =>
            (
              {
                ...prevState,
                account_name: newValue
              }
            )
          );
        break;
      case "#EIN":
        setData( prevState =>
          (
            {
              ...prevState,
              employer_identification_number: newValue
            }
          )
        );
        break;
        case "#Phone":
          setData( prevState =>
            (
              {
                ...prevState,
                phone: newValue
              }
            )
          );
          break;
          case "#Address1":
          setData( prevState =>
            (
              {
                ...prevState,
                address1: newValue
              }
            )
          );
          break;
          case "#Address2":
          setData( prevState =>
            (
              {
                ...prevState,
                address2: newValue
              }
            )
          );
          break;
          case "#State":
          setData( prevState =>
            (
              {
                ...prevState,
                state: newValue
              }
            )
          );
          break;
          case "#Zip":
          setData( prevState =>
            (
              {
                ...prevState,
                zip: newValue
              }
            )
          );
          break;
      default:
        break;
      }
    }

    function cancel(e) {
        setToggle({toggle: false})
    }
   
    async function modifyAccount(e) {
      //alert("ModifyAccount")
      await SearchService.updateAccount(data);
      setToggle({toggle: false})
      editAccount();
      /*if (state.accountContactId != null)
        await SearchService.updateAccountContact(state);
      else
        await SearchService.addAccountContact(state);*/
        
        //window.location.reload()
        
    }
  


    if (data == null)
      return (
        <div></div>
      )

    return (

        

          <Card small className="mb-4">                     
            <CardBody className="p-1">
                <Row noGutters className="page-header px-0 py-4">
                    <Row>
                        
                      <Col>
                          <ListGroup flush>
                          <ListGroupItem>
                          <Row>
                            <Col>
                                <Form role="form">
                                    <FormGroup>
                                    <label htmlFor="#AccountName">Account Name</label>
                                      <FormInput id="#AccountName" placeholder="Account Name"  required value={data.account_name} onChange={handleChange.bind(this)} /> 
                                    <label htmlFor="#EIN">EIN</label>
                                      <FormInput id="#EIN" placeholder="EIN" required value={data.employer_identification_number==null?0:data.employer_identification_number} onChange={handleChange.bind(this)} />   
                                    <label htmlFor="#Phone">Phone Number</label>
                                        <FormInput id="#Phone" placeholder="Phone Number" value={data.phone} onChange={handleChange.bind(this)} />
                                    <label htmlFor="#Address1">Address 1</label>
                                        <FormInput id="#Address1" placeholder="Address Line 1" value={data.address1} onChange={handleChange.bind(this)} />
                                    <label htmlFor="#Address1">Address 2</label>
                                        <FormInput id="#Address2" placeholder="Address Line 2" value={data.address2} onChange={handleChange.bind(this)} />
                                    <label htmlFor="#State">State</label> 
                                        <FormInput id="#State" placeholder="State" value={data.state} onChange={handleChange.bind(this)} />
                                    <label htmlFor="#Zip">Zip</label> 
                                        <FormInput id="#Zip" placeholder="Zip" value={data.zip} onChange={handleChange.bind(this)} />
                                    </FormGroup>
                                    
                                </Form>
                                <div class="d-flex justify-content-center">
                                      
                                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={modifyAccount.bind(this)}>Save</Button>
                                        <label>&nbsp;&nbsp;</label>
                                        <Button size="sm" theme="secondary" className="mb-2 mr-1" onClick={cancel.bind(this)}>Cancel</Button>
                                      
                                </div>
                            </Col>
                          </Row>
                          </ListGroupItem>
                          </ListGroup>
                      </Col>
                    </Row>
                </Row>
              </CardBody>
          </Card>
        

    )
  }
