import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card, CardHeader, CardBody } from "shards-react";
import ScoreService from "../../../flux/services/ScoreService";

import Chart from "../../../utils/chart";

function CustomerGraph(props)
{
  let label = null
  let dataSet = null
  // props
  const [data, setData] = useState(null);
  useEffect(() => {
      async function getScoreTrends(accountId)
      {
        try {
          // set loading to true before calling API
          const data = await ScoreService.getScoreTrends(accountId); 
          setData(data);
          //alert(JSON.stringify(data));
        } catch (error) {
          // add error handling here
          console.log(error);
        }
      }
      getScoreTrends(props.accountId);
    }, []);

  class Graph extends React.Component {
    constructor(props) {
      super(props);
      this.GraphValues = React.createRef();
      
    }
    componentDidMount() {


      let newLabel = [];
      let newDataSet = [[], [], []];
      function CustConversion(item)
      {
        if(item.attribute.attribute_value === "Payment Score") {
          newDataSet[0].unshift(item.amount);
        }
        else if(item.attribute.attribute_value === "Aging Score")
        {
          newDataSet[1].unshift(item.amount);
        }
        else if (item.attribute.attribute_value === "CCI Score")
        {
          newDataSet[2].unshift(item.amount);
        }
        if (newLabel.indexOf(item.date_id.toString().substring(4,6) + '/' + item.date_id.toString().substring(2,4)) === -1)
        {
          newLabel.unshift(item.date_id.toString().substring(4,6) + '/' + item.date_id.toString().substring(2,4));
        }

      }
      

      data.forEach(CustConversion)
      label = newLabel;
      dataSet = newDataSet;

      const Colors = ["#87BBFF", "#FFAF72", "#7DCC70"];
      const Labels = ["Payment Score", "Aging Score", "CCI Score"]
      const xValues = label;
      var yValues;
      // to get 2d arrays for yValues
      if (dataSet[0].constructor === Array)
      {
        var arr = [];
        for(let i in dataSet)
        {
          arr.push(
            ({
              data: dataSet[i],
              borderColor: Colors[i],
              borderWidth: 1,
              fill: false,
              label: Labels[i]
            }));
        }
        yValues = arr;
      }
      else
      {
        yValues =
          ([{
            data: dataSet,
            borderColor: Colors,
            borderWidth: 1,
            fill: false
          }]);
      }
       

      const chartConfig = {
        type: "line",
        data: {
          labels: xValues,
          datasets: yValues
        },
        options: {
          responsive: true,
          legend: { display: true 
                  },

          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 100,
                  stepSize: 10
                }
              }
            ]
          }


        }
        
      };
      new Chart(this.GraphValues.current, chartConfig);
    }
    render() {
      return (
        <Col>
        <Row className="mb-4">
        <canvas ref={this.GraphValues} />
        </Row>
        </Col>
      );
    }
  
    
  }
  if ( data === null )
  {
    return (
      <span>awaiting valid data</span>
    )
  }
  return (
    
    <Container>
      <Graph/>
    </Container>
    
  );
}


export default CustomerGraph;
