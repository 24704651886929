import supabase from "../stores/supabase";

class DashboardService {
  
  async getBalancePercent(memberId ) {

    let { data: balancePercent, error } = await supabase
        .from('vw_credit_balance_percent')
        .select('member_id, pct_current, one_day_past_due_pct, thirty_day_past_due_pct, sixty_day_past_due_pct, ninety_day_past_due_pct')
        .eq('member_id', memberId)
        ;
 

    if (error !== null)
    {
        alert("error Dashboard1: " + JSON.stringify(error))
      return null;
    }
    if (balancePercent === null)
      return null;

    return balancePercent;


  }

  async getScoreAgainstIndustry(memberId) {

    let { data: score, error } = await supabase
        .from('vw_score_against_industry')
        .select('member_id, mmyyyy, member_avg_score, tier_avg_score')
        .eq('member_id', memberId)
        .order('year_actual')
        .order('month_actual')
        ;
 
    //alert (JSON.stringify(score))
    if (error !== null)
    {
        alert("error Dashboard2: " + JSON.stringify(error))
      return null;
    }
    if (score === null)
      return null;

    return score;


  }

  async getNumOfAccountsTrend(memberId) {
  
    //alert(memberId)
    if (memberId === null)
      return
    let { data: trend, error } = await supabase
        .from('vw_num_of_account_trend')
        .select('member_id, pct_change, mmyyyy_1, mmyyyy_2, mmyyyy_3, mmyyyy_4, mmyyyy_5, mmyyyy_6, mmyyyy_7, mmyyyy_8, mmyyyy_9, mmyyyy_10, mmyyyy_11, mmyyyy_12, member_count_1, member_count_2, member_count_3, member_count_4, member_count_5, member_count_6, member_count_7, member_count_8, member_count_9, member_count_10, member_count_11, member_count_12')
        .eq('member_id', memberId)
        ;
 
    //alert (JSON.stringify(score))
    if (error !== null)
    {
        alert("error Dashboard3: " + JSON.stringify(error))
      return null;
    }
    if (trend === null)
      return null;

    return trend;


  }

  async getPaymentHistory(memberId, paymentHistoryCode) {
  
    //alert("memberId: " + memberId + " paymentHistoryCode: " + paymentHistoryCode)
    if (memberId === null)
      return
    let { data: trend, error } = await supabase
        .from('vw_payment_history')
        .select('member_id, payment_history, pct_change, payment_history_1, payment_history_2, payment_history_3, payment_history_4, payment_history_5, payment_history_6, payment_history_7, payment_history_8, payment_history_9, payment_history_10, payment_history_11, payment_history_12')
        .eq('member_id', memberId)
        .eq('payment_history', paymentHistoryCode)
        ;
 
    //alert (JSON.stringify(trend))
    if (error !== null)
    {
        alert("error: " + JSON.stringify(error))
      return null;
    }
    if (trend === null)
      return null;

    return trend;


  }



}

export default new DashboardService()
