import supabase from "../stores/supabase";

class ScoreService {
  
  async getScores(accountId ) {
    let today = new Date();
    //today.setMonth(today.getMonth() + 1); // dates are zero indexed so it its starts with 0 not 1 for the month
    //let dateformat = (today.getFullYear() * 10000) + ((today.getMonth() + 1) * 100) + 1;
    
    //let dateformat = '20231201' //testing purposes
    let { data: scores, error } = await supabase
        .from('vw_current_score')
        .select('scores_id, date_id,account_id,attribute_value, amount')
        .eq('account_id', accountId)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
        ;
 
    if (error !== null)
    {
      alert(JSON.stringify(error))
      return null;
    }
    if (scores === null)
      return null;

      return scores;


  }

  async getLargestScoreChange(){
    let today = new Date();
    //today.setMonth(today.getMonth() + 1); // dates are zero indexed so it its starts with 0 not 1 for the month
    let dateformat = (today.getFullYear() * 10000) + ((today.getMonth()) * 100) + 1;
    let { data: scores, error } = await supabase
        .from('vw_largest_score_change')
        .select('scores_id, date_id,account_id, amount, last_amount, change_amount, account_name, city, state')
        .eq('date_id', dateformat)   
        .eq('member_id', 4804) // change to logged in member
        .order('change_amount', { ascending: true})                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
        ;
 
    //alert(JSON.stringify(error));
    if (error !== null)
      return null;
    if (scores === null)
      return null;

      return scores;
  }

  async getAccountsBelow75(memberId) {
    
    let { data: scores, error } = await supabase
        .from('vw_scores_below_75')
        .select('scores_id, date_id,account_id, current_month_amount, last_month_amount, account_name, city, state') 
        .eq('member_id', memberId) // change to logged in member
        .order('account_id', { ascending: true})                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
        ;
 
    //alert(JSON.stringify(memberId));
    if (error !== null)
      return null;
    if (scores === null)
      return null;

      return scores;
  }

  async getScoreDecileDistribution(){
    
    let { data: scores, error } = await supabase
        .from('vw_score_decile_distribution')
        .select('mmyyyy, between_0_10, between_11_20,between_21_30, between_31_40, between_41_50, between_51_60, between_61_70, between_71_80, between_81_90, between_91_100')                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
        .order('yyyymm', { ascending: false})
        ;
 
     //   alert(JSON.stringify(scores));
    if (error !== null)
      return null;
    if (scores === null)
      return null;

      return scores;
  }

  async getScoreHistory(accountId ) {
    let today = new Date();
    //today.setMonth(today.getMonth() + 1); // dates are zero indexed so it its starts with 0 not 1 for the month
    let dateformat = (today.getFullYear() * 10000) + (today.getMonth() * 100) + 1;

    let { data: scores, error } = await supabase
        .from('scores')
        .select('scores_id, date_id,account_id,attribute (attribute_value), amount')
        .eq('account_id', accountId)
        .eq('score_type_id', '9') //cci score
        .lte('date_id', dateformat)
        .order('date_id', { ascending: false})
        .limit(12)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
        ;
 
    if (error !== null)
      return null;
    if (scores === null)
      return null;

      return scores;


  }

  async getScoreTrends(accountId ) {
    let today = new Date();
    //today.setMonth(today.getMonth() + 1); // dates are zero indexed so it its starts with 0 not 1 for the month
    let dateformat = (today.getFullYear() * 10000) + ((today.getMonth() + 1) * 100) + 1;
    //let dateformat = '20231201';

    let { data: scores, error } = await supabase
        .from('scores')
        .select('scores_id, date_id,account_id,attribute (attribute_value), amount')
        .eq('account_id', accountId)
        .lte('date_id', dateformat)
        .order('date_id', { ascending: false})
        .limit(36)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
        ;
 
    if (error !== null)
      return null;
    if (scores === null)
      return null;

      return scores;


  }

}

export default new ScoreService()
