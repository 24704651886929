import React,{ useState, useEffect} from "react";
import { Container, Button, Card,CardBody, Col, CardHeader, Row, Modal, ModalHeader, ModalBody, Form, FormGroup, FormInput, ListGroup, ListGroupItem } from "shards-react";
import PlacedForCollectionsService from "../../../flux/services/PlacedForCollectionsService";
//import { useViewContext } from "../ViewTradeReport";

 
export default function CustomerPFC (props)
{
 
  let acctId = props.accountId
  const [state, setState]= useState({
    insertComplete: false,
    open: false,
    amount: null,
    accountId: acctId,
    memberId: props.memberId,
  });
  const [data, setData] = useState(null);
  useEffect(() => {
    /*async function getPFC(acctId)
    {
      try {

        // set loading to true before calling API
        const data = await PlacedForCollectionsService.getPFC(acctId);
        setData(data);
        


      } catch (error) {
        console.log(error);
      }
    }
    getPFC(acctId);*/
    let isMounted = true; 
    PlacedForCollectionsService.getPFC(acctId).then( data => { if (isMounted) {setData(data)} })
    return () => { isMounted = false };
    
  }, []);


  function toggle(e) {
   
    setState( prevState =>
      ({
      ...prevState,
      open: !state.open
      }
      ) 
    );
    
  }
  
  async function addPFC(e) {

    await PlacedForCollectionsService.addPFC(  state.accountId,state.amount, state.memberId);
      try {
        // set loading to true before calling API
        const data = await PlacedForCollectionsService.getPFC(state.accountId);
        setData(data);

      } catch (error) {
        // add error handling here
        console.log(error);
      }
    toggle();

  }

  //Remove the meeting Comment
  async function DeletePFC(pfcId)
  {
    await PlacedForCollectionsService.deletePFC(pfcId);
    try {
      // set loading to true before calling API
      const data = await PlacedForCollectionsService.getPFC(state.accountId);
      setData(data);

    } catch (error) {
      // add error handling here
      console.log(error);
    }

  }

  function handleChange(event) {
    let newValue = event.target.value;
    switch(event.target.id)
    {
    case "#Amount":
      setState( prevState =>
        (
          {
            ...prevState,
            amount: newValue
          }
        )
      );
      break;
    default:
      break;
    }
  }
  
  //add a delete button for the member to delete its own PFC Record
  const DeleteButton = (props) =>
  {
    //alert(JSON.stringify(props))
    if(props.member_id === props.loggedin_Member_id)
      return(
        <div>
        <Button size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => DeletePFC(props.pfcId)} >Delete</Button>
        </div>
      )

    return(<div></div>)
  }
  const Rows = (props) => {
    const {placed_for_collections_id, account_id,amount,member_id,date_entered} = props
    let dateEntered = date_entered.toString().substring(5,7) + '/' + date_entered.toString().substring(8,10) + '/' + date_entered.toString().substring(0,4)
    let USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0, 
      minimumFractionDigits: 0,
    });
    return ( <tr>
      <td>{member_id}</td>
      <td>{account_id}</td>
      <td>{dateEntered}</td>
      <td>{USDollar.format(amount)}</td>
      <td><DeleteButton member_id={member_id} loggedin_Member_id={state.memberId}  pfcId={placed_for_collections_id}></DeleteButton></td>
    </tr>
    )
  }

  const Table = (props) => {

    if (!data) return (
      <span>Data not available</span>
    );
    return (
        <Row className="px-3">
            <table className="table table-sm">
              <thead className="table-light">
                <tr>
                
                <th scope="col" >Member Id</th>
                <th scope="col">CCI ID</th>
                <th scope="col">Date Entered</th>
                <th scope="col">PFC Amount</th>
                <th scope="col"></th>
                </tr>
              </thead>
            <tbody> 
              
            {data.map((data) => (
                <Rows key={data.placed_for_collections_id} placed_for_collections_id={data.placed_for_collections_id} account_id={data.account_id} amount={data.amount}
                      member_id={data.member_id} date_entered={data.date_entered}/>
            ))}

            </tbody>
            </table>
        </Row>)
  }

  const AddPFCButton = (props) =>
  {
    if (props.view == 'Trade Report')
      return ( <div></div> )
      
    return (
      <Button size="sm" className="mb-2 mr-1" onClick={toggle.bind(this)}>Add PFC</Button>
    )
  }

   //alert(JSON.stringify(props));
  return (
    

      <Container>
      <CardHeader className="border-bottom">
        <h5>
          Customer PFC
        </h5>
      </CardHeader>
      <Table data={props}/>
      <Row>
      <div className="d-flex justify-content-center bd-highlight">
        <AddPFCButton view={props.view}></AddPFCButton>
        <Modal open={state.open}  centered={true}>
          <ModalHeader>Enter Placed For Collections</ModalHeader>
          <ModalBody>
            <Card small className="mb-4">                     
              <CardBody className="p-1">
                  <Row noGutters className="page-header px-0 py-4">
                      <Row>
                          
                        <Col>
                            <ListGroup flush>
                            <ListGroupItem>
                            <Row>
                              <Col>
                                  <Form role="form">
                                      <FormGroup>
                                      <label htmlFor="#Amount">PFC Amount</label>
                                        <FormInput id="#Amount" placeholder="Amount" required value={state.amount} onChange={handleChange.bind(this)} />  
                                          
                                      </FormGroup>
                                      
                                  </Form>
                                  <div class="d-flex justify-content-center">
                                        
                                          <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={addPFC.bind(this)}>Save</Button>
                                          <label>&nbsp;&nbsp;</label>
                                          <Button size="sm" theme="secondary" className="mb-2 mr-1" onClick={toggle}>Cancel</Button>
                                        
                                  </div>
                              </Col>
                            </Row>
                            </ListGroupItem>
                            </ListGroup>
                        </Col>
                      </Row>
                  </Row>
                </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        </div>
      </Row>
      </Container>
    
  )
}

