import React,{ useState, useEffect} from "react";
import { Container, CardHeader, Row, Button, Modal, ModalBody, ModalHeader, Card, CardBody, Col, ListGroup, ListGroupItem, Form, FormGroup, FormInput,FormSelect, FormTextarea} from "shards-react";
import MeetingCommentsService from "../../../flux/services/MeetingCommentsService";
 
export default function MeetingCommentsReport (props)
{
  const [state, setState]= useState({
    memberId: props.memberId,
    profile: props.profile,
    view: props.view
  });
  const [data, setData] = useState(null);
  useEffect(() => {
    async function getMeetingComments(memberId,profile){
      try {
        // set loading to true before calling API
        let data = null
        if (profile === 'admin')
          data = await MeetingCommentsService.getMeetingCommentsAll();
        else
          data = await MeetingCommentsService.getMeetingComments(memberId);
        setData(data);

      } catch (error) {
        console.log(error);
      }
    }
    getMeetingComments(state.memberId, state.profile);

  }, []);



  //Remove the meeting Comment
  async function DeleteComment(commentId)
  {
    await MeetingCommentsService.deleteMeetingComments(commentId);
    try {
      // set loading to true before calling API
        let data = null
        
        if (state.profile === 'admin')
          data = await MeetingCommentsService.getMeetingCommentsAll();
        else
          data = await MeetingCommentsService.getMeetingComments(state.memberId);
        
        setData(data);

    } catch (error) {
      // add error handling here
      console.log(error);
    }

  }


  //add a delete button for the member to delete its own meeting comment
  const DeleteButton = (props) =>
  {
    //alert(JSON.stringify(props))
    if((props.member_id == props.loggedin_Member_id || state.profile === 'admin') && state.view !== 'Report')
      return(
        <div>
        <Button size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => DeleteComment(props.meeting_comments_id)} >Delete</Button>
        </div>
      )

    return(<div></div>)
  }
  const Rows = (props) => {
    const {account_id,member_id,date_entered, attribute_value, attribute_description, other_comments, additional_comments, meeting_comments_id} = props
    let dateEntered = date_entered.toString().substring(5,7) + '/' + date_entered.toString().substring(8,10) + '/' + date_entered.toString().substring(0,4)
    let attrDescription = attribute_description;

    if (other_comments != "" && other_comments !== null && other_comments != "null")
      attrDescription = other_comments;


    return ( <tr>
      {state.profile=='admin'?<td>{member_id}</td>:null}
      <td>{dateEntered}</td>
      <td>{account_id}</td>
      <td>{attribute_value}</td>
      <td>{attrDescription}</td>
      <td>{additional_comments}</td>
      {
           state.memberId=='Report'?null: <td><DeleteButton member_id={member_id} loggedin_Member_id={state.memberId}  meeting_comments_id={meeting_comments_id}></DeleteButton></td>
          
      }
    
      
    </tr>
    )
  }

  //Populate the table with the meeting comments
  const Table = (props) => {

    if (!data) return (
      <span>Data not available</span>
    );
    return (
        <Row>
            <table className="table table-sm">
              <thead className="table-light">
                <tr>
                {state.profile=='admin'?<th scope="col">Member</th>:null}
                <th scope="col" >Date Entered</th>
                <th scope="col">CCI Number</th>
                <th scope="col">Meeting Code</th>
                <th scope="col">Meeting Comments</th>
                <th scope="col">Additional Comments</th>
                {state.view=='Report'?null:<th scope="col">Delete</th>}
                <th></th>                
                </tr>
              </thead>
            <tbody> 
              
            {data.map((data) => (
                <Rows key={data.meeting_comments_id} account_id={data.account_id} 
                      member_id={data.member_id} date_entered={data.date_entered} attribute_value={data.attribute.attribute_value}
                      attribute_description={data.attribute.attribute_description} other_comments={data.other_comments} 
                      additional_comments={data.additional_comments} meeting_comments_id={data.meeting_comments_id}/>
            ))}

            </tbody>
            </table>
        </Row>)
  }

   return (
    
    
      <Card>
        <CardHeader className="border-bottom">
          <h5>
            Meeting Comments
          </h5>
        </CardHeader>
        <CardBody>
          <Table data={props}/>
        </CardBody>
      </Card>
    
    
  )
}

