import React from "react";
import { Container, Button, Row, Col, Card, CardHeader } from "shards-react";
import PageTitle from "../components/common/PageTitle";
const CreditReports = () => (
  <Container className="main-content-container px-5 pb-3">
    <Col>
      <Row>
        <PageTitle
          sm="4"
          title="Credit Reports"
          className="text-sm-left"
        />
      </Row>
      <Col>
        <Card small className="px-3 py-3">
          <CardHeader className="border-bottom">
            <h3>
              Credit Reports
            </h3>
          </CardHeader>
          <h6>
            There are many providers of credit reports.
            The links to the left  are to the leaders in the industry.
            Credit Report providers vary in quality and cost.
            There listing here is neither an endorsement or recommendation for their services.
            You are encouraged to visit the web sites of such firms to learn more about their services.
          </h6>
          <CardHeader className="border-bottom">
            <h3>
              Why use a collection agency?
            </h3>
          </CardHeader>
          <div class="d-grid gap-2 px-2 py-2">
            <h6>Credit Reports Characteristics</h6>

            <h6>Your Credit Reports should give the following information:</h6>

            <h6>Timely accounts receivable aging information</h6>
            <h6>High Credit; Date Reported; Date of Last Sales</h6>
            <h6>Payment History</h6>
            <h6>An account score</h6>
            <h6>Whether vendors sell the account on a COD or CBD basis</h6>
            <h6>In addition, the report should cross-reference bad check and collection activity.</h6>
          </div>
          <CardHeader className="border-bottom">
            <h3>Collection Agencies</h3>
          </CardHeader>
          <div class="d-grid gap-2">
            <a href="http://floorcredit.com/cciinformationservices.htm">CCI Information Services</a>
            <a href="http://www.creditfyi.com/">Credit FYI</a>
            <a href="http://www.dnb.com/">Dun & Bradstreet</a>
            <a href="http://www.experian.com/">Experian</a>
            <a href="http://www.equifax.com/">Equifax</a>
            <a href="http://www.infousa.com/">InfoUSA</a>
          </div>
          <CardHeader className="border-bottom">
            <h3>Report Costs</h3>
          </CardHeader>
          <h6> CCI Reports cost as little as $8.95 per report. Ask us about quantity discounts.
            CCI provides your credit department with a real alternative to traditional, over-priced credit reporting services. </h6>
        </Card>
      </Col>
    </Col>
  </Container>
)
export default CreditReports
