import React,{ useState, useEffect} from "react";
import { Container, CardHeader, Row } from "shards-react";
import ScoreService from "../../../flux/services/ScoreService";
 
export default function Scores (props)
{

  const [data, setData] = useState(null);
  useEffect(() => {
    async function getScores(accountId)
    {
      try {
        // set loading to true before calling API
        const data = await ScoreService.getScores(accountId); 
        setData(data);
      // alert(JSON.stringify(data));
      } catch (error) {
        // add error handling here
        console.log(error);
      }
    }
    getScores(props.accountId);

  }, []);

  const Rows = (props) => {
    const {date_id, account_id, score_type, amount} = props
    return ( <tr>
      <td></td>
      <td>{score_type}</td>
      <td>{amount}</td>
      <td></td>
    </tr>
    )
  }

  const Table = (props) => {
    if (!data) return (
      <span>Data not available</span>
    );
    return (
          <Row className="px-3">
            <table className="table table-sm">
              <thead className="table-light">
                <tr>
                <th scope="col">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                <th scope="col">Scores</th>
                <th scope="col">Value</th>
                <th scope="col">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                </tr>
              </thead>
            <tbody> 
              
              {data.map((data) => (
                  <Rows key={data.scores_id} date_id={data.date_id} account_id={data.account_id} score_type={data.attribute_value} amount={data.amount}  />
                ))
              }

            </tbody>
            </table>
          </Row>)
  }


  //alert(JSON.stringify(props));
  return (
    
    <Container>
      
      <CardHeader className="border-bottom">
        <h5>
          Scores
        </h5>
      </CardHeader>
      <Table data={props}/>
    </Container>
  )
}

