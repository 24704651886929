import React, {useContext,useState, useEffect, useRef} from "react";
import {Container, Row, Col, Card, CardHeader, CardBody, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, Form, FormGroup, FormInput, Button, FormSelect} from "shards-react";
import searchStore from "../../../flux/stores/searchStore";
import loginStore from "../../../flux/stores/loginStore";
//import { useViewContext } from "../ViewTradeReport";
import format from "../../../utils/format";
import SearchService from "../../../flux/services/SearchService";
import AccountContact from "./AccountContact";
import getToggleContext from "../../Context/ToggleContext";

const toggleContext = getToggleContext();

export default function CustomerInfoCard () {
    let customerRecord = searchStore.getCustomerRecord();
    let user = loginStore.getUser();
  
    
    //alert(JSON.stringify(customerRecord[0].account_name));
    const [data, setData] = useState(null);
    const [toggle, setToggle] = useState(
      {
        toggle: false
      }
    );
    const [state, setState]= useState({
      open: false,
      firstName: null,
      lastName: null,
      contactType: null,
      phone: null,
      email: null,
      accountContactId: null,
      memberId: loginStore.getMemberId(),
      accountId: customerRecord[0].cci_account_id,
      profileId: loginStore.getProfile()
    });
    
    useEffect(() => {
      async function getAccountContact(acctId){
        try {
          // set loading to true before calling API
          const data = await SearchService.getAccountContact(acctId);
          setData(data);
         // alert(JSON.stringify(data));
        } catch (error) {
          // add error handling here
          console.log(error);
        }
  
      }
      getAccountContact(customerRecord[0].cci_account_id);
  
    }, [toggle]);

    function handleClick(contactData)
    {
      //alert(JSON.stringify(contactData));
      setState( prevState =>
        (
          {
            ...prevState,
            accountContactId: contactData.account_contact_id,
            firstName: contactData.first_name,
            lastName: contactData.last_name,
            email: contactData.email,
            phone: contactData.phone,
            contactType: contactData.contact_type
            
          }
        )
      );

    
      //optionToSelect.selected = true;
      
      setToggle({toggle: true})
    }

    function handleChange(event) {
      let newValue = event.target.value;
      switch(event.target.id)
      {
      case "#FirstName":
          setState( prevState =>
            (
              {
                ...prevState,
                firstName: newValue
              }
            )
          );
        break;
      case "#LastName":
        setState( prevState =>
          (
            {
              ...prevState,
              lastName: newValue
            }
          )
        );
        break;
        case "#Phone":
          setState( prevState =>
            (
              {
                ...prevState,
                phone: newValue
              }
            )
          );
          break;
          case "#Email":
          setState( prevState =>
            (
              {
                ...prevState,
                email: newValue
              }
            )
          );
          break;
          case "#ContactType":
          setState( prevState =>
            (
              {
                ...prevState,
                contactType: newValue
              }
            )
          );
          break;
      default:
        break;
      }
    }

    function addNewContact(e){

      setToggle({toggle: true});
    }




    function AccountCountacts(props)
    {
      if(data == null)
          return <div></div>

      return (

        <ListGroup>

        {data.map((data) => (
          <div >
          <ListGroupItem className="d-flex m-0 p-0"><Button style={{textAlign: 'left', width: "100%"}} className="btn-outline-light mx-auto m-0 border-0" onClick={() => handleClick(data)}>
              <span className='fw-bold'>{data.first_name + ' ' + data.last_name}</span>{' (' + data.contact_type + ')'}
              <div>{'Phone: ' + format.formatPhoneNumber(data.phone)}</div>
              <div>{'Email: ' + data.email}</div>
              </Button></ListGroupItem>
          </div>
        ))}
        </ListGroup>
        
            
        
      )
    }



    if(customerRecord == undefined)
      return (<div></div>)

    return (

      <toggleContext.Provider value={[toggle, setToggle]}>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h5>Customer Information</h5>
          </CardHeader>

          <CardBody>

          
                <Row>
                
                  <Col lg="4">
                    <Row className="px-0 py-1"><Col lg="4" md="3">EIN:</Col><Col className="text-muted">{customerRecord[0].employer_identification_number}</Col></Row>
                    <Row className="px-0 py-1"><Col lg="4" md="3">Name:</Col><Col className="text-muted">{(customerRecord[0].account_name).trim()}</Col></Row>
                    <Row className="px-0 py-1"><Col lg="4" md="3">Address:</Col><Col className="text-muted">{(customerRecord[0].address1)?customerRecord[0].address1.trim():''}<div className="px-0">{(customerRecord[0].address2)?customerRecord[0].address2.trim():''}</div><div className="px-0">{customerRecord[0].city.trim()}{", " + customerRecord[0].state}</div></Col></Row>
                    <Row className="px-0 py-1"><Col lg="4" md="3">Phone:</Col><Col className="text-muted">{format.formatPhoneNumber(customerRecord[0].phone.trim())}</Col></Row>
                  </Col>
                  
                  <Col lg="4">
                    <Row className="px-0 py-1"><Col lg="4" md="3">CCI Number:</Col><Col className="text-muted">{customerRecord[0].cci_account_id}</Col></Row>
                    <Row className="px-0 py-1"><Col lg="4" md="3">File Created:</Col><Col className="text-muted">{(customerRecord[0].created_date)?customerRecord[0].created_date.substring(0,10):""/*new Date().toISOString().split('T')[0]*/}</Col></Row>
                    <Row className="px-0 py-1"><Col lg="4" md="3">Requested By:</Col><Col className="text-muted">{user}</Col></Row>
                    <Row className="px-0 py-1"><Col lg="4" md="3">Contact:</Col><Col className="text-muted"></Col></Row>
                  </Col>
                  <Col lg="4">
                    <Row>Contacts</Row>
                    <Row className="py-1">
                      <AccountCountacts></AccountCountacts>
                  </Row>
                  <div class="container d-flex align-items-center justify-content-center">
                  <Button size="sm" className="mb-2 mr-1" onClick={addNewContact.bind(this)}>Add Contact</Button>
                  </div>
                  </Col>
              </Row>
          </CardBody>
       
        </Card>
        
        <Modal open={toggle.toggle}  centered={true}>
        <ModalHeader>Add/Change Company Contact</ModalHeader>
        <ModalBody>
         <AccountContact contact={state}/>
        </ModalBody>
      </Modal>
    </toggleContext.Provider>
        

    )
  }
