import React,{ useState, useEffect} from "react";
import { Container, Card, CardHeader, Row, Col, FormGroup, FormSelect } from "shards-react";
import AdminService from "../../../flux/services/AdminService";

 
export default function AccountBalance (props)
{
  //const [view, setView] = useContext(useViewContext());
  let [state, setState]= useState({
    memberId: props.memberId,
    reportMonths: "1",
    report: "All"
  });
  let [data, setData] = useState(null);
  let [pfcData, setPfcData] = useState(null);
  let [nsfData, setNsfData] = useState(null);
  useEffect(() => {
    async function getAccountBalance(memberId, reportMonths)
    {

      try {
        // set loading to true before calling API
        const data = await AdminService.getReportAccess(memberId, 'Trade Report', reportMonths);
        setData(data);
      } catch (error) {
        // add error handling here
        console.log(error);
      }

      try {
        // set loading to true before calling API
        const pfcData = await AdminService.getReportAccess(memberId, 'PFC Report', reportMonths);
        setPfcData(pfcData);
      } catch (error) {
        // add error handling here
        console.log(error);
      }

      try {
        // set loading to true before calling API
        const nsfdata = await AdminService.getReportAccess(memberId, 'NSF Report', reportMonths);
        setNsfData(nsfdata);
      } catch (error) {
        // add error handling here
        console.log(error);
      }


    }
        getAccountBalance(props.memberId, state.reportMonths);
  
    }, []);

async function refreshReport(memberId, reportMonths){
    //alert('refresh reports')
      try {
        // set loading to true before calling API
        const data = await AdminService.getReportAccess(memberId, 'Trade Report', reportMonths);
        setData(data);
      } catch (error) {
        // add error handling here
        console.log(error);
      }

      try {
        // set loading to true before calling API
        const pfcData = await AdminService.getReportAccess(memberId, 'PFC Report', reportMonths);
        setPfcData(pfcData);
      } catch (error) {
        // add error handling here
        console.log(error);
      }

      try {
        // set loading to true before calling API
        const nsfdata = await AdminService.getReportAccess(memberId, 'NSF Report', reportMonths);
        setNsfData(nsfdata);
      } catch (error) {
        // add error handling here
        console.log(error);
      }
}
async function handleChange(event) {

        let newValue = event.target.value;
        let memberId = state.memberId;
        let reportMonths = state.reportMonths;

        switch(event.target.id)
        {
            case "#reportMonths":
                reportMonths = newValue;
                setState( prevState =>
                (
                    {
                        ...prevState,
                        reportMonths: newValue
                        
                    
                    }
                )
                );
                break;
            default:
                break;
        }
        refreshReport(memberId, reportMonths);

    }


  const Rows = (props) => {
    let USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2, 
      minimumFractionDigits: 2,
    });

    const {member_id,username,report_type, access_date,cost,account_name} = props
    //let accessDate = access_date.toString().substring(4,6) + '/' + access_date.toString().substring(6,8) + '/' + access_date.toString().substring(2,4);

    return ( <tr>
      <td>{member_id}</td>
      <td>{access_date}</td>
      <td>{username}</td>
      <td>{report_type}</td>
      <td>{account_name}</td>
      <td>{USDollar.format(cost)}</td>
    </tr>
    )
  }
 


  const Table = (props) => {
    
    if (!data) return (
      <span>Trade Report Data Not Available</span>
    );

 
    return ( 
    
      <div class="overflow-auto">
      <div class="row px-5" style={{maxHeight: "500px", maxWidth: "100%"}}>
    <table className="table table-sm">
              <thead className="table-light">
                <tr>
                <th scope="col">Member Id</th>
                <th scope="col">Date Accessed</th>
                <th scope="col">User Name</th>
                <th scope="col">Report Type</th>
                <th scope="col">Company Name</th>
                <th scope="col">Cost</th>
                </tr>
              </thead>
            <tbody> 
              
              {data.map((data) => (
                <Rows key={data.report_access_id} member_id={data.member_id} username={data.user_name} report_type={data.attribute_description} access_date={data.access_date} cost={data.attribute_value} account_name={data.account_name} /> 
              ))}

            </tbody>
            </table>
            </div>
      </div>)
  }


  const PfcRows = (props) => {
    let USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2, 
      minimumFractionDigits: 2,
    });

    const {member_id,username,report_type, access_date,cost,account_name} = props
    //let accessDate = access_date.toString().substring(4,6) + '/' + access_date.toString().substring(6,8) + '/' + access_date.toString().substring(2,4);

    return ( <tr>
      <td>{member_id}</td>
      <td>{access_date}</td>
      <td>{username}</td>
      <td>{report_type}</td>
      <td>{USDollar.format(cost)}</td>
    </tr>
    )
  }
 


  const PfcTable = (props) => {
    if (!pfcData)
      return <div></div>
 
    return ( 
    
      <div class="overflow-auto">
      <div class="row px-5" style={{maxHeight: "500px", maxWidth: "100%"}}>
      <table className="table table-sm">
              <thead className="table-light">
                <tr>
                <th scope="col">Member Id</th>
                <th scope="col">Date Accessed</th>
                <th scope="col">User Name</th>
                <th scope="col">Report Type</th>
                <th scope="col">Cost</th>
                </tr>
              </thead>
            <tbody> 
              
              {pfcData.map((data) => (
                <PfcRows key={data.report_access_id} member_id={data.member_id} username={data.user_name} report_type={data.attribute_description} access_date={data.access_date} cost={data.attribute_value} account_name={data.account_name} /> 
              ))}

            </tbody>
            </table>
            </div>
        </div>    
        )
  }

  const NsfRows = (props) => {
    let USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2, 
      minimumFractionDigits: 2,
    });

    const {member_id,username,report_type, access_date,cost,account_name} = props
    //let accessDate = access_date.toString().substring(4,6) + '/' + access_date.toString().substring(6,8) + '/' + access_date.toString().substring(2,4);

    return ( <tr>
      <td>{member_id}</td>
      <td>{access_date}</td>
      <td>{username}</td>
      <td>{report_type}</td>
      <td>{USDollar.format(cost)}</td>
    </tr>
    )
  }
 


  const NsfTable = (props) => {
    if (!nsfData)
      return <div></div>
 
    return ( 
    
    
      <div class="overflow-auto">
        <div class="row px-5" style={{maxHeight: "500px", maxWidth: "100%"}}>
            <table className="table table-sm">
              <thead className="table-light">
                <tr>
                <th scope="col">Member Id</th>
                <th scope="col">Date Accessed</th>
                <th scope="col">User Name</th>
                <th scope="col">Report Type</th>
                <th scope="col">Cost</th>
                </tr>
              </thead>
            <tbody> 
              
              {nsfData.map((data) => (
                <NsfRows key={data.report_access_id} member_id={data.member_id} username={data.user_name} report_type={data.attribute_description} access_date={data.access_date} cost={data.attribute_value} account_name={data.account_name} /> 
              ))}

            </tbody>
            </table>
      </div>
    </div>
    )
  }

  return (
    

      <Container>
      
      <Card>
      <CardHeader className="border-bottom">
        <h5>
          Report Access
        </h5>
      </CardHeader>
        <Row className="px-4">
            <Col lg="8" className="mb-4"></Col>
            <Col>
            </Col>
            <Col>
            <label htmlFor="#reportMonths">Report Months</label>
            <FormSelect className="mb-2 mr-1" id="#reportMonths" onChange={handleChange.bind(this)} >
                <option value="1">1 Month</option>
                <option value="3">3 Months</option>
                <option value="6">6 Months</option>
                <option value="12">12 Months</option>
            </FormSelect> 
            </Col> 
        </Row> 
      <CardHeader className="border-bottom mb-2">
      <div class="fs-6">Trade Reports</div>
      </CardHeader>
      <Table data={props}/>
      <CardHeader className="border-bottom mb-2">
      <div class="fs-6">PFC Reports</div>
      </CardHeader>
      <PfcTable data={props}/>
      <CardHeader className="border-bottom mb-2">
      <div class="fs-6">NSF Reports</div>
      </CardHeader>
      <NsfTable data={props}/>
      </Card>
      </Container>

  )
}

