import React,{ useState, useEffect} from "react";
import { Container, Card, CardHeader, Row, Col, FormSelect, Modal, ModalBody, ModalHeader, Button, CardBody, FormGroup, FormInput } from "shards-react";
import { ProtectedRoute } from "../../../ProtectedRoute";
import AdminService from "../../../flux/services/AdminService";

 
export default function Members (props)
{
  //const [view, setView] = useContext(useViewContext());
  let [state, setState]= useState({
    open: false,
    memberGroup: "All",
    memberId: "",
    name: "",
    contactFirstName: "",
    contactLastName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
    memberGroupId: 1,
    fileFormatId: 5
  });
  let [data, setData] = useState(null);
  let [refresh, setRefresh] = useState({
    refresh: false
  });
  useEffect(() => {
    async function getMembers(memberGroup)
    {

      try {
        // set loading to true before calling API
        const data = await AdminService.getMembers(memberGroup);
        setData(data);
      } catch (error) {
        // add error handling here
        console.log(error);
      }
    }
        getMembers("All");
  
    }, []);

async function refreshMembers(memberGroup){
    try {
        // set loading to true before calling API
        const data = await AdminService.getMembers(memberGroup);
        setData(data);
      } catch (error) {
        // add error handling here
        console.log(error);
      }
}
async function handleChange(event) {

        let newValue = event.target.value;
        let memberGroup = state.memberGroup;

        switch(event.target.id)
        {
            case "#memberGroup":
                memberGroup = newValue;
                setState( prevState =>
                (
                    {
                        ...prevState,
                        memberGroup: newValue
                    }
                )
                );
                refreshMembers(memberGroup);
                break;
            case "#MemberId":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        memberId: newValue
                    }
                )
                );
              break;
            case "#Name":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        name: newValue
                    }
                )
                );
              break;
            case "#FirstName":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        contactFirstName: newValue
                    }
                )
                );
              break;
            case "#LastName":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        contactLastName: newValue
                    }
                )
                );
              break;
            case "#Email":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        email: newValue
                    }
                )
                );
              break;
            case "#Phone":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        phoneNumber: newValue
                    }
                )
                );
              break;
            case "#Address":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        address: newValue
                    }
                )
                );
              break;
            case "#City":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        city: newValue
                    }
                )
                );
              break;
            case "#State":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        state: newValue
                    }
                )
                );
              break;
            case "#Zip":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        zip: newValue
                    }
                )
                );
              break;
            case "#Group":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        memberGroupId: newValue
                    }
                )
                );
              break;
            case "#FileFormat":
              setState( prevState =>
                (
                    {
                        ...prevState,
                        fileFormatId: newValue
                    }
                )
                );
              break;
            default:
                break;
        }

        

    }

  /** Handle opening and closing the Modal window */
  function toggle(e) {
   
    setState( prevState =>
      ({
      ...prevState,
      open: !state.open
      }
      ) 
      );
      
  }

  async function addMember(e) {

    await AdminService.addMember(state);
      try {
        // set loading to true before calling API
        const data = await AdminService.getAllMembers();
        setData(data);
       // alert(JSON.stringify(data));
      } catch (error) {
        // add error handling here
        console.log(error);
      }
    toggle();

  }


  const DeleteButton = (props) =>
    {
      
        return(
          <div>
          <Button size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => DeleteMember(props.member_id)} >Delete</Button>
          </div>
        )
    } 


  async function DeleteMember(member_id){

    let result = await AdminService.DeleteMember(member_id)
    refreshMembers(state.memberGroup)

  }


  const Rows = (props) => {
    
    const {member_id,name,firstName, lastName,email,phone,member_group, file_format} = props
    //let accessDate = access_date.toString().substring(4,6) + '/' + access_date.toString().substring(6,8) + '/' + access_date.toString().substring(2,4);

    return ( <tr>
      <td>{member_id}</td>
      <td>{name}</td>
      <td>{firstName + ' ' + lastName}</td>
      <td>{email}</td>
      <td>{phone}</td>
      <td>{member_group}</td>
      <td>{file_format}</td>
      <td><DeleteButton member_id={member_id} >Delete</DeleteButton></td>
    </tr>
    )
  }
 


  const Table = (props) => {
    
 
    return ( <Row className="px-5"><table className="table table-sm">
              <thead className="table-light">
                <tr>
                <th scope="col">Member Id</th>
                <th scope="col">Name</th>
                <th scope="col">Contact Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Member Group</th>
                <th scope="col">File Format</th>
                <th scope="col">Delete</th>
                </tr>
              </thead>
            <tbody> 
              
              {data.map((data) => (
                <Rows key={data.member_id} member_id={data.member_id} name={data.name} firstName={data.contact_first_name} lastName={data.contact_last_name} email={data.email} phone={data.phone_number} member_group={data.member_group} file_format={data.file_format} /> 
              ))}

            </tbody>
            </table>
            </Row>)
  }


  if (!data) return (
    <ProtectedRoute>
        <Container>
      
            <Card>
            <CardHeader className="border-bottom">
            <h5>
                Member Managment
            </h5>
            </CardHeader>
            <span>Data not available</span>
            </Card>
        </Container>

    </ProtectedRoute>
    
  );

  return (
    
    <ProtectedRoute>
      <Container>
      
      <Card>
      <CardHeader className="border-bottom">
        <h5>
          Member Managment
        </h5>
      </CardHeader>
        <Row className="px-4">
            <Col lg="9" className="py-3 px-4">
            <div className="d-flex justify-content-left bd-highlight">
              <Button size="sm" className="mb-2 mr-1" onClick={toggle.bind(this)}>Add Member</Button>
            </div>
            </Col>
            <Col>
            <label htmlFor="#memberGroup">Member Group</label>
    
            <FormSelect className="mb-2 mr-1" id="#memberGroup" onChange={handleChange.bind(this)} >
                <option value="All">All</option>
                <option value="Crossovers">Crossovers</option>
                <option value="West Coast">West Coast</option>
                <option value="A La Carte">A La Carte</option>
            </FormSelect>  
            </Col>
            
        </Row> 
      <Table data={props}/>
      <Row>
      
        <Modal className="modal-lg" open={state.open}  centered={true}>
          <ModalHeader>Add Member</ModalHeader>
          <ModalBody>
            <Card className="mb-4">                     
              <CardBody className="p-1">
                  <Row noGutters className="page-header px-0 py-4">
                     <Col className="m-2">
                      <Row>

                        <Col>
                          <FormGroup>
                          <label htmlFor="#MemberId">Member Id</label>
                            <FormInput id="#MemberId" placeholder="Member Id" required value={state.memberId} onChange={handleChange.bind(this)} />  
                          <label htmlFor="#Name">Name</label>
                              <FormInput id="#Name" placeholder="Name" required value={state.name} onChange={handleChange.bind(this)} />
                          <label htmlFor="#FirstName">Contact First Name</label>
                          <FormInput id="#FirstName" placeholder="Contact First Name" required value={state.contactFirstName} onChange={handleChange.bind(this)} /> 
                          <label htmlFor="#LastName">Contact Last Name</label>
                              <FormInput id="#LastName" placeholder="Contact Last Name" required value={state.contactLastName} onChange={handleChange.bind(this)} />
                          <label htmlFor="#Group">Member Group</label>
                          <FormSelect className="mb-2 mr-1" id="#Group" onChange={handleChange.bind(this)} >
                              <option value="1">Crossovers</option>
                              <option value="2">East Coast</option>
                              <option value="3">West Coast</option>
                              <option value="4">A La Carte</option>
                          </FormSelect>  
                          <label htmlFor="#FileFormat">File Format</label>
                          <FormSelect className="mb-2 mr-1" id="#FileFormat" onChange={handleChange.bind(this)} >
                              <option value="5">Fixed Format</option>
                              <option value="6">CSV</option>
                          </FormSelect>   
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label htmlFor="#Address">Address</label>
                              <FormInput id="#Address" placeholder="Address" required value={state.address} onChange={handleChange.bind(this)} />  
                            <label htmlFor="#City">City</label>
                              <FormInput id="#City" placeholder="City" required value={state.city} onChange={handleChange.bind(this)} />
                              <label htmlFor="#State">Customer State</label>
                                      <FormSelect id="#State" onChange={handleChange.bind(this)} >
                                        <option value=''>--Select--</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                      </FormSelect>                             
                              <label htmlFor="#Zip">Email</label>
                            <FormInput id="#Zip" placeholder="Zip" required value={state.zip} onChange={handleChange.bind(this)} />
                            
                            <label htmlFor="#Phone">Phone</label>
                              <FormInput id="#Phone" placeholder="Phone" required value={state.phone} onChange={handleChange.bind(this)} />  
                            <label htmlFor="#Email">Email</label>
                              <FormInput id="#Email" placeholder="Email" required value={state.email} onChange={handleChange.bind(this)} />    
                          </FormGroup>
                        </Col>
                        <Row>
                          <div class="d-flex justify-content-center">
                                    
                                    <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={addMember.bind(this)}>Save</Button>
                                    <label>&nbsp;&nbsp;</label>
                                    <Button size="sm" theme="secondary" className="mb-2 mr-1" onClick={toggle}>Cancel</Button>
                                  
                            </div>
                        </Row>
                      </Row>
                      </Col>
                  </Row>
                </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        
      </Row>
      </Card>
      </Container>
      </ProtectedRoute>
  )
}

