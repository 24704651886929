export default function() {
  return [
    {
      title: "Home",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/home",
    },
    {
      title: "Services",
      htmlBefore: '<i class="material-icons">edit</i>',
      to: "/services",
    },
    {
      title: "Contact Us",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/contact-us",
    },
    {
      title: "About",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/aboutthegroup",
    },
    {
      title: "Advertising",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/advertising",
    }
    
    /*,
    {
      title: "Blog Dashboard",
      to: "/blog-overview",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    },
    {
      title: "Blog Posts",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/blog-posts",
    },
    {
      title: "Add New Post",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/add-new-post",
    },
    {
      title: "Forms & Components",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/components-overview",
    },
    {
      title: "Tables",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/tables",
    },
    {
      title: "User Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite",
    }*/
    
  ];
}
