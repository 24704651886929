import React from "react";
import { Container, Button, Row, Col, Card, CardHeader } from "shards-react";
import PageTitle from "../components/common/PageTitle";
const News = () => (
  <Container className="main-content-container px-5 pb-3">
    <Col>
      <Row>
        <PageTitle
          sm="4"
          title="News"
          className="text-sm-left"
        />
      </Row>
      <Col>
        <Card small className="px-3 py-3">
          <CardHeader className="border-bottom">
            <h3>
              News & Industry Publications
            </h3>
          </CardHeader>
          <h6>
            The attorneys or firms listed here have represented floorcovering manufacturers in the recent past.
            Their listing here is neither a recommendation nor endorsement of such firms.
            You  are encouraged to visit the web sites of such firms or attorney to learn more about their services.
          </h6>
          <CardHeader className="border-bottom">
            <h3>Industry News Magazines</h3>
          </CardHeader>
          <div class="d-grid gap-2">
            <a href="http://www.nacm.org/bcmag/bcm_index.html">Business Credit</a>
            <a href="http://www.businessfinancemag.com/">Business Finance</a>
            <a href="http://www.ccr.faulknergray.com/">Collections & Credit Risk</a>
            <a href="http://www.floorcoveringweekly.com/">Floor Covering Weekly</a>
            <a href="http://www.floorfocus.com/">Flooring Focus</a>
          </div>
        </Card>
      </Col>
    </Col>
  </Container>
)
export default News
