import { createClient } from '@supabase/supabase-js'
import { Constants } from '..';


const supabaseAdmin = createClient(Constants.BASE_URL, Constants.API_ADMIN_KEY, {
    auth: {
      autoRefreshToken: false,
      persistSession: false
    }
  })

  // Access auth admin api
 // const adminAuthClient = supabase.auth.admin

export default supabaseAdmin;