
import React, {useContext, useState} from "react";
import { Form, FormInput, FormGroup, Button, ListGroup, ListGroupItem, Row, Col, Card, Container, CardHeader, CardBody, FormSelect } from "shards-react";
import getViewContext from "../../Context/ViewContext";
import CustomerSearchResults from "./CustomerSearchResults";
import SearchService from "../../../flux/services/SearchService";
import searchStore from "../../../flux/stores/searchStore";


export default function CustomerSearch (props) {
    
    
   const [view, setView] = useContext(getViewContext());
      const [state, setState]= useState({
        phone: "",
        ccid: "",
        customerName: "",
        customerState: "",
        customerCity: "",
        showResults: false,
        results: {}
      });
    
    

    function handleChange(event) {
        let newValue = event.target.value;
        switch(event.target.id)
        {
        case "#phone":
            setState( prevState =>
              (
                {
                  ...prevState,
                  phone: newValue
                }
              )
            );
          break;
        case "#ccid":
          setState( prevState =>
            (
              {
                ...prevState,
                ccid: newValue
              }
            )
          );
          break;
        case "#customerName":
          setState( prevState =>
            (
              {
                ...prevState,
                customerName: newValue
              }
            )
          );
          break;
          case "#customerCity":
            setState( prevState =>
              (
                {
                  ...prevState,
                  customerCity: newValue
                }
              )
            );
            break;
          case "#customerState":
            setState( prevState =>
              (
                {
                  ...prevState,
                  customerState: newValue
                }
              )
            );
            break;
        }
    }
  
    function search(e) {
      
      getAccounts();  
      //setView({view: "Results"});


    }

    /*
    * get the results from the CCI Customer Accounts and render 
    * results
    */
    async function getAccounts()
    {

      let account = await SearchService.search(state.phone, state.ccid, state.customerName, state.customerCity, state.customerState);
      //alert(JSON.stringify(account));
      if(account !== null)
      {
        searchStore.setResults(account);
        setState( prevState =>
          (
            {
              ...prevState,
              showResults: true,
              results: {account}
            }
          )
        );
      }
      else
        alert("No Customers Returned. Please try using different criteria");
        //alert(JSON.stringify(account));

    }

    if (state.showResults == true || searchStore.getResults() != null)
    {

      let searchResults = {}
      if(searchStore.getResults() != null)
        searchResults = searchStore.getResults()
      else
        searchResults = state.results.account;

      return <CustomerSearchResults results={searchResults}></CustomerSearchResults>
    }
    return (
      
    
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h5 className="m-0">Search</h5>
        </CardHeader>
        
        <CardBody className="p-0">
                    
          
                        <Row flex>
                            
                                  <Col className="p-4">
                                    <FormGroup>
                                    <label htmlFor="#phone">Phone</label>
                                        <FormInput id="#phone" placeholder="Phone" value={state.phone} onChange={handleChange.bind(this)} onKeyDown={(e) => (e.keyCode === 13 ? search(e) : null)}/>
                                        <label htmlFor="#ccid">CCI ID</label>
                                      <FormInput id="#ccid" placeholder="CCI Id" value={state.ccid} onChange={handleChange.bind(this)} onKeyDown={(e) => (e.keyCode === 13 ? search(e) : null)} />
                                    </FormGroup>
                                    </Col>
                                    <Col className="p-4">
                                    <FormGroup>
                                    
                                      <label htmlFor="#customerName">Customer Name</label>
                                      <FormInput id="#customerName" placeholder="Customer Name" value={state.customerName} onChange={handleChange} onKeyDown={(e) => (e.keyCode === 13 ? search(e) : null)} />
                                      <label htmlFor="#customerCity">City</label>
                                      <FormInput id="#customerCity" placeholder="City" value={state.customerCity} onChange={handleChange} onKeyDown={(e) => (e.keyCode === 13 ? search(e) : null)} />
                                      <label htmlFor="#customerState">Customer State</label>
                                      <FormSelect id="#customerState" onChange={handleChange.bind(this)} onKeyDown={(e) => (e.keyCode === 13 ? search(e) : null)}>
                                        <option value=''>--Select--</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                        <option value="AB">Alberta</option>
                                        <option value="BC">British Columbia</option>
                                        <option value="MB">Manitoba</option>
                                        <option value="NB">New Brunswick</option>
                                        <option value="NL">NewFoundland/Labrador</option>
                                        <option value="NS">Nova Scotia</option>
                                        <option value="NT">Northwest Territories</option>
                                        <option value="NU">Nunavut</option>
                                        <option value="ON">Ontario</option>
                                        <option value="PE">Prince Edward Island</option>
                                        <option value="QC">Quebec</option>
                                        <option value="SK">Saskatchewan</option>
                                        <option value="YT">Yukon</option>
                                      </FormSelect>                            
                                    </FormGroup>
                                    
                                       

                                    </Col>
                            
                            
                        </Row>
                          <div class="d-flex mb-3 justify-content-md-center bd-highlight" >
                          <Button type="submit" onClick={search.bind(this)}>Search</Button>
                          </div>
                
                      
          </CardBody>
        </Card>
        /*<form onSubmit={this.handleSubmit}>
          <label>
            Name:
            <input type="text" value={this.state.value} onChange={this.handleChange} />
          </label>
          <input type="submit" value="Submit" />
        </form>*/
      );
}
