import React from "react";
import { Container, Button, Row, Col, Card, CardHeader } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import BaseLayout from "./BaseLayout";
import { useState } from "react";

function EnterNSF() {
  return (
    <Container className="main-content-container px-5 py-3 pb-3">
      <Row noGutters className="page-header py-2">
        <PageTitle
          sm="4"
          title="EnterNSF"
          className="text-sm-left"
        />
      </Row>
      <Col>
        <Row>
          <Card className="px-4">
            <h3 className="py-4">Customer Information </h3>
          <div tag="Table1">
            information here
          </div>
            <h3 className="py-4">Enter NSF Information </h3>
          <form class="d-grid gap-4">
            <label>Check Amount:
              <input type="text" fluid className="mx-2" />
            </label>
            <label>Times Returned:
              <input type="text" fluid className="mx-2" />
            </label>
            <Button>Submit</Button>
          </form>
            <h3 className="py-4">Current NSFs</h3>
            <div tag="Table2"/>
          </Card>
        </Row>
    </Col>
    </Container>
  )
}
export default EnterNSF;
