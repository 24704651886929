import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card, CardHeader, CardBody } from "shards-react";
import DashboardService from "../../../flux/services/DashboardService";
import Chart from "../../../utils/chart";

function ScoreAgainstIndustryGraph(props) {
  let label = null
  let dataSet = null
  const [data, setData] = useState(null);
  

  useEffect(() => {
    async function getDashData(memberId) {
      try {
        // set loading to true before calling API
        const data = await DashboardService.getScoreAgainstIndustry(memberId);
        setData(data);
        // alert(JSON.stringify(data));
      } catch (error) {
        // add error handling here
        console.log(error);
      }
    }
    getDashData(props.memberId);
  }, []);


  class Graph extends React.Component {
    constructor(props) {
      super(props);
      this.GraphValues = React.createRef();

    }
    componentDidMount() {
      let newDataSet = [[], []];
      let label = []
      function CustConversion(item) {

        if (item["member_id"] == props.memberId) {
          newDataSet[0].push(item["member_avg_score"]);
          newDataSet[1].push(item["tier_avg_score"]);
          label.push(item["mmyyyy"]);
        }
      }

      data.forEach(CustConversion)
      dataSet = newDataSet;

      const Colors = ["rgba(50, 80, 168,1.0)", "rgba(191, 23, 23, 1.0)"];
      const xValues = label;
      var yValues;

      yValues =
        ([{
          data: dataSet[0],
          label: "My Customers",
          borderColor: Colors[0],
          backgroundColor: "rgba(50, 80, 168,0.1)",
          borderWidth: 1,
          fill: true
        },
        {
          data: dataSet[1],
          label: "Industry Customers",
          backgroundColor: "rgba(191, 23, 23, 0.1)",
          borderColor: Colors[1],
          borderWidth: 1,
          fill: true
        }
        ]);


      const chartConfig = {
        type: "line",
        data: {
          labels: xValues,
          datasets: yValues
        },
        options: {
          scales: {
            y: {
                suggestedMin: 50,
                suggestedMax: 100
            }
          },
          responsive: true,
          legend: {
            display: true,
          },
          title: {
            display: true,
            text: "Score Against Industry"
          }
          
        }


      };
      new Chart(this.GraphValues.current, chartConfig);
    }
    render() {
      return (
        <canvas height="350" ref={this.GraphValues} />
      );
    }
  }

  if (data == null) {
    return (
      <span>Awaiting Valid Data</span>
    )
  }
  else if (data.length == 0) {
    return (
      <span>Awaiting Valid Data</span>
    )
  }

  return (
      
      <Card className="w-100">
        <Graph></Graph>
      </Card>
      
  );
}
export default (ScoreAgainstIndustryGraph)
