import React,{ useState, useEffect} from "react";
import { Container, Button, Card,CardBody, Col, CardHeader, Row, Modal, ModalHeader, ModalBody, Form, FormGroup, FormTextarea, FormInput, ListGroup, ListGroupItem } from "shards-react";
import NSFCheckService from "../../../flux/services/NSFCheckService";
//import { useViewContext } from "../ViewTradeReport";

 
export default function WebsiteChange (props)
{
  
  let acctId = props.accountId
  const [state, setState]= useState({
    open: false,
    timesReturned: null,
    amount: null,
    memberId: props.memberId,
    accountId: acctId,
    profileId: props.profile
  });
  const [data, setData] = useState(null);
  useEffect(() => {
    async function getChecks(acctId){
      try {
        // set loading to true before calling API
        const data = await getNSFChecks(acctId);
        setData(data);
       // alert(JSON.stringify(data));
      } catch (error) {
        // add error handling here
        console.log(error);
      }

    }
    getChecks(acctId);

  }, []);

  
  async function getNSFChecks(accountId)
  {
      return NSFCheckService.getNSFChecks(accountId);
  }
  
  function toggle(e) {
   
    setState( prevState =>
      ({
      ...prevState,
      open: !state.open
      }
      ) 
    );
    
  }

  async function addNSF(e) {

    await NSFCheckService.addNSFChecks(state.accountId, state.amount, state.timesReturned, 4801);
      try {
        // set loading to true before calling API
        const data = await NSFCheckService.getNSFChecks(state.accountId);
        setData(data);
       // alert(JSON.stringify(data));
      } catch (error) {
        // add error handling here
        console.log(error);
      }
    toggle();

  }

  function handleChange(event) {
    let newValue = event.target.value;
    switch(event.target.id)
    {
    case "#TimesReturned":
        setState( prevState =>
          (
            {
              ...prevState,
              timesReturned: newValue
            }
          )
        );
      break;
    case "#Amount":
      setState( prevState =>
        (
          {
            ...prevState,
            amount: newValue
          }
        )
      );
      break;
    default:
      break;
    }
  }

  //Remove the NSF Checks
  async function DeleteNSFChecks(nfsId)
  {
    await NSFCheckService.deleteNSFChecks(nfsId);
    try {
      // set loading to true before calling API
      const data = await NSFCheckService.getNSFChecks(state.accountId);
      setData(data);

    } catch (error) {
      // add error handling here
      console.log(error);
    }

  }

  //add a delete button for the member to delete its own NSF Record
  const DeleteButton = (props) =>
  {
    
    if(props.member_id === props.loggedin_Member_id || props.profile == "admin")
      return(
        <div>
        <Button size="sm" theme="secondary" className="mb-2 mr-1" onClick={() => DeleteNSFChecks(props.nsf_check_id)} >Delete</Button>
        </div>
      )

    return(<div></div>)
  }

  const Rows = (props) => {
    const {profile, nsf_check_id, account_id,amount,times_returned,member_id,date_entered} = props
    let dateEntered = date_entered.toString().substring(5,7) + '/' + date_entered.toString().substring(8,10) + '/' + date_entered.toString().substring(0,4)

    return ( <tr>
      <td>{member_id}</td>
      <td>{account_id}</td>
      <td>{dateEntered}</td>
      <td>{amount}</td>
      <td>{times_returned}</td>
      <td><DeleteButton profile={profile} member_id={member_id} loggedin_Member_id={state.memberId}  nsf_check_id={nsf_check_id}></DeleteButton></td>
    </tr>
    )
  }

  const Table = (props) => {
    if (!data) return (
      <span>Data not available</span>
    );
    return (
       <Container>
        <Row small className="mb-4">
            <table className="table table-sm">
              <thead className="table-light">
                <tr>
                
                <th scope="col" >Member Id</th>
                <th scope="col">CCI ID</th>
                <th scope="col">Date Entered</th>
                <th scope="col">Amt Returned</th>
                <th scope="col"># Times Returned</th>
                <th scope="col"></th>
                </tr>
              </thead>
            <tbody> 
              
              {data.map((data) => (
                <Rows key={data.nsf_check_id} profile={props.data.profile} nsf_check_id={data.nsf_check_id} account_id={data.account_id} amount={data.amount} times_returned={data.times_returned}
                      member_id={data.member_id} date_entered={data.date_entered}/>
              ))}

            </tbody>
            </table>
        </Row>
        </Container>
        )
  }


  //alert(JSON.stringify(props));
  return (
    

      <Container>
      <CardHeader className="border-bottom">
        <h5>
          Website Change Request
        </h5>
      </CardHeader>

      <Table data={props}/>

      <Row>
      <div className="d-flex justify-content-center bd-highlight">
        <Button size="sm" className="mb-2 mr-1" onClick={toggle.bind(this)}>Add Request</Button>
        <Modal open={state.open}  centered={true}>
          <ModalHeader>Add New Website Change Request</ModalHeader>
          <ModalBody>
            <Card small className="mb-4">                     
              <CardBody className="p-1">
                  <Row noGutters className="page-header px-0 py-4">
                      <Row>
                          
                        <Col>
                            <ListGroup flush>
                            <ListGroupItem>
                            <Row>
                              <Col>
                                  <Form role="form">
                                      <FormGroup>
                                      <label htmlFor="#RequestType">Change Request Type</label>
                                        <FormInput id="#RequestType" required value={state.amount} onChange={handleChange.bind(this)} />  
                                      <label htmlFor="#Description">Description</label>
                                          <FormTextarea id="#Description" placeholder="Describe change" required value={state.otherRequest} onChange={handleChange.bind(this)} />
                                      </FormGroup>
                                      
                                  </Form>
                                  <div class="d-flex justify-content-center">
                                        
                                          <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={addNSF.bind(this)}>Save</Button>
                                          <label>&nbsp;&nbsp;</label>
                                          <Button size="sm" theme="secondary" className="mb-2 mr-1" onClick={toggle}>Cancel</Button>
                                        
                                  </div>
                              </Col>
                            </Row>
                            </ListGroupItem>
                            </ListGroup>
                        </Col>
                      </Row>
                  </Row>
                </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        </div>
      </Row>
      

      </Container>
    
  )
}

