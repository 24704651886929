import React, { useEffect, useState } from "react";
import {Row, Col, Container, Card, CardHeader, CardBody} from "shards-react";
import ScoreService from "../../../flux/services/ScoreService";
import tableCustomStyles from "../../../utils/tableCustomStyles";
import Chart from "../../../utils/chart";
import DataTable from "react-data-table-component";

function DecileGraph()
{
  let [data, setData] = useState({index: 0});

  useEffect(() => {
    async function getScoreDecileDistribution()
    {
        
        try {
          // set loading to true before calling API
          
          let data = await ScoreService.getScoreDecileDistribution();
          setData(
            prevState =>
              (
                {
                  index: 0,
                  data: data
                }
              ) 

          )
  

        } catch (error) {
          console.log(error);
        }
        
     }
    getScoreDecileDistribution();
  }, []);
  class GraphValues extends React.Component {
    constructor(props) {
      super(props);
      this.DecileValues = React.createRef();
      //const [data] = useState(null);
    }

    
    // componentDidMount()
    componentDidMount()
    {
     
      //const data = this.getScoreDecileDistribution();

      const xValues = ["0 - 10", "11 - 20", "21 - 30", "31 - 40", "41 - 50", "51 - 60", "61 - 70", "71 - 80", "81 - 90", "91 - 100"];
      //const yValues = [data.between_0_10,data.between_11_20,data.between_21_30,10,50,75, 80, 60, 95, 30];
      let yValues = null;
      if (data.data != null)
        yValues = [data.data[data.index].between_0_10, data.data[data.index].between_11_20, data.data[data.index].between_21_30, data.data[data.index].between_31_40, data.data[data.index].between_41_50, data.data[data.index].between_51_60, data.data[data.index].between_61_70, data.data[data.index].between_71_80, data.data[data.index].between_81_90, data.data[data.index].between_91_100];
      const barColors = "rgba(37, 67, 96, 1)";
      const chartConfig = {
        type: "bar",
        data: {
          labels: xValues,
          datasets: [{
            backgroundColor: barColors,
            data: yValues
          }]
        },
        options: {
          legend: { display: false },
          title: {
            display: true,
            text: "Distribution % Last 13 months"
          }
        }
      };
      new Chart(this.DecileValues.current, chartConfig);
    }
      

    render() {
      return (
        <canvas height="180" ref={this.DecileValues} />
      )
    }
  }
 
  async function onRowClickHandler(event) {
    //alert(JSON.stringify(event))
    for (let i = 0; i < data.data.length; i++)
    {
      if (data.data[i].mmyyyy == event.mmyyyy)
      {
        setData(
          prevState =>
            (
              {
                ...prevState,
                index: i
              }
            ) 

        )
        break;
      }
    }
       
   }
  const columns = [
    {
      name: 'Mon/Year',
      selector: data => data.mmyyyy.toString().substring(0,2) + '/' + data.mmyyyy.toString().substring(2,6),
      width: '11%'
    },
    {
      name: '0-10',
      selector: data => data.between_0_10,
      width: '9%'
    },
    {
      name: '11-20',
      selector: data => data.between_11_20,
      width: '9%'
    },
    {
      name: '21-30',
      selector: data => data.between_21_30,
      width: '9%'
    },
    {
      name: '31-40',
      selector: data => data.between_31_40,
      width: '9%'
    },
    {
      name: '41-50',
      selector: data => data.between_41_50,
      width: '9%'
    },
    {
      name: '51-60',
      selector: data => data.between_51_60,
      width: '9%'
    },
    {
      name: '61-70',
      selector: data => data.between_61_70,
      width: '9%'
    },
    {
      name: '71-80',
      selector: data => data.between_71_80,
      width: '9%'
    },
    {
      name: '81-90',
      selector: data => data.between_81_90,
      width: '9%'
    },
    {
      name: '91-100',
      selector: data => data.between_91_100,
      width: '9%'
    },
  ]
  
  
  const ScoreDataTable = (props) =>
  {
    if (!data.data) return (
      null
    );
  
    return (
      <Row className="py-2 px-4">

      <div className="py-4"></div>  
      <DataTable customStyles={tableCustomStyles} columns={columns}
        onRowClicked={onRowClickHandler}
        highlightOnHover={true}
        data={data.data}
        ></DataTable>

        </Row>
    )
  }

  if (!data.data) return (
    <span>Waiting for Decile Distribution to Return</span>
  );
  return (
    <Container>
    <GraphValues />
    <div>
      <ScoreDataTable></ScoreDataTable>
    </div>
    {/**<table style={{ margin: 'auto' }}>
      <tr style={{ background: '#254360', color: 'beige' }} >
        <td style={{ border: 'grey 1px solid', }}> Month/Year </td>
        <td style={{ border: 'grey 1px solid', }}> 0 - 10 </td>
        <td style={{ border: 'grey 1px solid', }}> 11 - 20 </td>
        <td style={{ border: 'grey 1px solid', }}> 21 - 30 </td>
        <td style={{ border: 'grey 1px solid', }}> 31 - 40 </td>
        <td style={{ border: 'grey 1px solid', }}> 51 - 60 </td>
        <td style={{ border: 'grey 1px solid', }}> 61 - 70 </td>
        <td style={{ border: 'grey 1px solid', }}> 71 - 80 </td>
        <td style={{ border: 'grey 1px solid', }}> 81 - 90 </td>
        <td style={{ border: 'grey 1px solid', }}> 91 - 100 </td>
      </tr>
  </table> **/}
    </Container>
  );
}




export default function ScoreDecileDistribution() {
  return (
    <Container>
      <Row>
        <Col lg="10" md="12" sm="12" className="mb-4">
          <Card>
            <CardHeader>
              <h5 className="text-center">Score Decile Distribution</h5>
            </CardHeader>
            <CardBody>
                <DecileGraph />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
