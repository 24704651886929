import React, { useState, useEffect } from "react";
import {Col} from "shards-react";
import DashboardService from "../../../flux/services/DashboardService";
import SmallStats from "../../../components/common/SmallStats";
import { json } from "react-router";

export default function TotalAccountsGraph(props) {
 
  const [totalAccounts, setTotalAccounts] = useState(null  )


  useEffect(() =>
  {
    async function GetTotalAccountsInformation(memberId)
    {

      let data = null;
      try {
        data = await DashboardService.getNumOfAccountsTrend(memberId);
        //setData(data);
        //alert(JSON.stringify(data))
      }
      catch (error) {
        console.log(error);
      }
      
      // data did not go in correctly
      let failure = {
        label: "total Accounts",
          value: "Loading",
            percentage: "0.0%",
              increase: true,
                chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(0, 184, 216, 0.1)",
            borderColor: "rgb(0, 184, 216)",
            data: [1, 2, 1, 3, 5, 4, 7]
          }
        ]
      }

      if (data.length == 0) {
        return failure;
      }

      // If data properly comes in

      // process data
      var graphLabels = ["1","null","null",null,null,null,null,null,null,null,null,null];
      var memberCount = [];
      var pct;
      for (let i = 1; i < 13; i++)
      {
        memberCount.push(data[0]["member_count_" + i])
      }
      
      //alert(JSON.stringify(memberCount))

      const nfObject = new Intl.NumberFormat('en-US'); 

      setTotalAccounts( prevState =>
        ({
          ...prevState,
          percentage: data[0]["pct_change"],
          value: nfObject.format(data[0].member_count_1),
          labels: graphLabels,
          data: memberCount,
          increase: data[0]["pct_change"] >= 0?true:false
          

        })
      )


    }
    
    GetTotalAccountsInformation(props.memberId)
    
      

    },[])

    if (totalAccounts == null)
        return (
            <div>Waiting for data</div>
        )

  return (
    
              <SmallStats
                id={`small-stats-1`}
                variation="1"
                increase={totalAccounts.increase}
                chartData={[
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(0, 184, 216, 0.1)",
                      borderColor: "rgb(0, 184, 216)",
                      data: totalAccounts.data
                    }
                  ]}
                chartLabels={[null,null,null,null,null,null,null,null,null,null,null,null]}
                label="Total Accounts"
                value={totalAccounts.value}
                percentage={totalAccounts.percentage}

                
              />
  )

}