import React from "react";
import { Nav, Row } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import SubSidebarNavItem from "./SubSidebarNavItem";
import { Store } from "../../../flux";
import loginStore from "../../../flux/stores/loginStore";

let profile = "";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)
    profile = loginStore.getProfile();
    //alert("profile: " + props.profile);
    this.state = {
      navItems: this.getSidebarNavItems(profile),
      subNavItems: this.getSubSidebarNavItems(profile)
    };

    this.onChange = this.onChange.bind(this);
  }

  getSidebarNavItems(profile)
  {
    
    if (profile == "member")
      return Store.getMemberSidebarItems();
    else if (profile == "alacarte")
      return Store.getAlacartSidebarNavItems();
    else if (profile == "admin")
      return Store.getAdminSidebarNavItems();

    return Store.getSidebarItems();
  }

  getSubSidebarNavItems(profile)
  {

    if (profile == "admin")
    {
      return Store.getAdminSubSidebarNavItems();
    }

    return {item: null};
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: this.getSidebarNavItems(profile)
    });
  }

  links() {
    
    if (profile == 'member' || profile == 'alacarte' || profile == 'admin')
      return(<div></div>)

  return (

      <div fluid="true" className="px-4">
            <h6> <u>Links</u> </h6>
            <div className="d-grid gap-2">
              <a href="http://localhost:3000/attorneys">Attorneys</a>
              <a href="http://localhost:3000/banks">Banks/Factors</a>
              <a href="http://localhost:3000/collection">Collection Agancies</a>
              <a href="http://localhost:3000/insurance">Credit Insurance</a>
              <a href="http://localhost:3000/creditreports">Credit Reports</a>
              <a href="http://localhost:3000/finances">Financials</a>
              <a href="http://localhost:3000/news">News And Publications</a>
            </div>
            <h6 className="py-2"> <u>Partners</u> </h6>
            <div className="d-grid gap-2">
              <a href="http://localhost:3000/home">Floorcovering Weekly</a>
            </div>
          </div>

  )
  }

subNavItems(subItems) {
  //const { navItems: items, subNavItems: subItems } = this.state;
  //alert(JSON.stringify(subItems))
  if (profile == "admin")
  {
  return (<div>
    {subItems.map((item, idx) => (
            <SubSidebarNavItem key={idx} item={item} />
          ))}

  </div>);
  }

  return (<div></div>);

}


  render() {
    const { navItems: items, subNavItems: subItems } = this.state;
    if (profile == "admin")
    {
      return (
        <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} />
          ))}
          <Row className="pb-5"></Row>
          <div align="left" className="d-flex px-2 border-bottom-1">Admin Functions</div>
          
          
          {
            subItems.map((item, idx) => (
            <SubSidebarNavItem key={idx} item={item} />
          ))
          }
          <this.links></this.links>
        </Nav>
      </div>
      )
    }

    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} />
          ))}
          <Row className="pb-5"></Row>
          <this.links></this.links>
        </Nav>
      </div>
    )
  }
}

export default SidebarNavItems;
