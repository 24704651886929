import React from "react";
import ReactDOM from 'react-dom';
import TestLayout from "./Client Views/Components/TestLayout";


const BaseLayout = () => (

    <container>
      <table class='table'>
        <thead>
          <tr>
            <th scope="col">Member No.</th>
            <th scope="col">CCI-ID</th>
            <th scope="col">Company Name</th>
            <th scope="col">Company Name</th>
            <th scope="col">City</th>
            <th scope="col">State</th>
            <th scope="col">Date Entered</th>
            <th scope="col">PFC Amount</th>
          </tr>
        </thead>
        <tbody>
          <div id="newTable"></div>
          <TestLayout />
        </tbody>
      </table>
    </container>
  );

/*
function CreateDropdown(Items) {
  console.log("PING!!!");

  var FinalText = "<h3>stupid thing didn't work <h3>"

  for (let i = 0; i < Items.legnth; i++)
  {
    var Item = Items[i];
    FinalText = "<tr><th scope = \"row\">";
    for (let x = 0; x < Item.length; x++)
    {
      FinalText = FinalText + "<td>" + Item[i][x] + "</td>";
    }
    FinalText = FinalText + "</tr>";
  }
  return FinalText;
}*/
// what it should look like: A grid with dropdowns for rows!!!
/*
Member No.	CCI-ID	Company Name	City	State	Date Entered	PFC Amt
Drop down
card or item or container or something

*/ 
export default BaseLayout
